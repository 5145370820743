/*
 Iq-Sidebar
*/

.iq-backdrop {
  inset: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 30, 64, 0.6);
  z-index: $zindex-modal-backdrop;
}

.iq-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: calc(#{$zindex-modal} - 1);

  display: flex;
  flex-direction: column;

  width: 530px;
  height: calc(var(--vh, 1vh) * 100);
  padding: size(3x);
  background-color: #fff;

  @include maxW(530px) {
    width: 100vw;
  }

  .modal-header {
    position: relative;
    padding-bottom: size(3x);

    &__icon {
      margin-right: size(2x);

      svg {
        fill: $blue-main;
      }
    }

    &__title {
      font-size: size(3x);
      line-height: 1.5;
      color: $grey-main;
    }

    .modal-close {
      position: absolute;
      top: 6px;
      right: 0;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .modal-body {
    flex: 1;
    margin-right: -14px;
    padding-right: 14px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: $pale-grey;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $steel-light;
    }

    &__information {
      display: flex;
      align-items: center;
      margin: 10px 0;

      .icon {
        margin-right: 8px;
      }

      span {
        font-size: 14px;
        font-weight: bold;
        color: $slate;
      }
    }
  }

  .modal-footer {
    margin-top: auto;

    .assign-member__link {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      height: 56px;
      padding: size(1x);
      border-radius: 28px size(1x) size(1x) 28px;
      border: 1px solid $pale-sky-blue;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.57;
      color: $slate;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: size(5x);
        height: size(5x);
        margin-right: size(1x);
        border-radius: 50%;
        background-color: $pale-grey;

        svg {
          display: block;
          width: size(3x);
          height: size(3x);
          fill: $steel-light;
        }
      }
    }
  }
}

.iq-sidebar-fade-animation {
  transition: opacity 200ms ease-in-out;
  animation: modal-fade-in-right 0.3s;
}

.iq-sidebar-fade-out-animation {
  transition: opacity 200ms ease-in-out;
  animation: modal-fade-out-right 0.3s;
  animation-fill-mode: forwards;
}
