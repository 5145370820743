.tooltip {
  position: absolute;
  display: block;
  z-index: $tooltip-zindex;
  margin-bottom: 3px;

  &-inner {
    padding: 4px 8px 6px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    background-color: rgba($navy, 0.8);
    color: $pale-grey-two;
  }

  &--line-height-normal {
    .tooltip-inner {
      line-height: normal;
    }
  }

  .tooltip-inner {
    max-width: 480px !important;
    white-space: normal;
  }
}

.tooltip > div {
  animation-name: delayedFadeIn;
  animation-duration: 0.4s;
}

@keyframes delayedFadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.info-tooltip {
  max-width: 200px;
}

.iq-line-chart-tooltip {
  padding: 2px 4px;
  background: #3b82f6;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
  z-index: 1000;
  position: fixed;
}
