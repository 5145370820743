@mixin ghost-animation {
  background-image: $ghost-gradient;
  background-size: 200%;
  animation-name: ghost-gradient-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}
