@keyframes modal-fade-in-top {
  0% {
    opacity: 0;
    transform: translateY(-#{size(5x)});
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modal-fade-in-right {
  0% {
    transform: translateX(530px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes modal-fade-out-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(530px);
  }
}

@keyframes menu-open {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes profile-open {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
