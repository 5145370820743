@import 'src/assets/scss/_variables';
@import 'src/assets/scss/mixins/all';

.iq-dashboard {
  &__wrapper {
    padding: 24px 32px 32px;
    border-radius: 12px;
    background-color: #fff;

    @include maxW(576px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    @include maxW(576px) {
      gap: 16px;
    }
  }

  &__divider {
    background-color: $pale-grey;

    &--vertical {
      @extend .iq-dashboard__divider;
      min-width: 1px;
      min-height: 100%;
    }

    &--horizontal {
      @extend .iq-dashboard__divider;
      min-width: 100%;
      min-height: 1px;
    }
  }
}

// Nested styles
.iq-dashboard__wrapper {
  .value {
    padding-left: 16px;

    &__title {
      position: relative;
      font-size: 12px;
      line-height: 18px;
      color: $slate;

      &::after {
        content: '';
        position: absolute;
        display: block;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        top: 50%;
        left: -16px;
        transform: translateY(-50%);
      }

      &--blue {
        @extend .value__title;

        &::after {
          background-color: $light-blue;
        }
      }

      &--dark-blue {
        @extend .value__title;

        &::after {
          background-color: $dark-blue;
        }
      }
    }

    &__details {
      font-size: 12px;
      line-height: 18px;
    }

    &__count {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }

    &__average {
      display: inline-block;
      padding: 4px 8px;
      color: $slate;
      font-size: 11px;
      line-height: 13px;
      background: rgba(83, 104, 128, 0.08);
      border-radius: 4px;
    }
  }
}

.dashboard-listings {
  &__header {
    h2 {
      text-transform: capitalize;
    }
  }

  &__row {
    display: flex;
    gap: 24px;
  }

  &__column {
    flex: 1;
  }

  &__title {
    position: relative;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 18px;
    color: $slate;

    &::after {
      content: '';
      position: absolute;
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      top: 50%;
      left: -16px;
      transform: translateY(-50%);
    }

    &--blue {
      @extend .dashboard-listings__title;

      &::after {
        background-color: $light-blue;
      }
    }

    &--dark-blue {
      @extend .dashboard-listings__title;

      &::after {
        background-color: $dark-blue;
      }
    }

    &--green {
      @extend .dashboard-listings__title;

      &::after {
        background-color: #29A72B;
      }
    }

    &--grey {
      @extend .dashboard-listings__title;

      &::after {
        background-color: $pale-sky-blue;
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 30px;
    letter-spacing: -0.4px;

    &--bold {
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
    }

    &--semi-bold {
      font-size: 14px;
      line-height: 30px;
      font-weight: 600;
      letter-spacing: -0.4px;
    }
  }

  .red {
    color: $red;
  }
}
