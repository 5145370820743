@import "src/assets/scss/_variables";
@import "src/assets/scss/mixins/all";

.leads-table {
  &__name {
    width: 100%;

    span {
      max-width: 175px;
    }
  }

  &.table-wrap {
    .material {
      .datatable {
        &-header {
          &-cell {
            display: flex;
            padding: 16px 0;
            align-items: center;
            text-align: center;
            white-space: pre-line;

            &.address {
              position: static;
              padding-left: 50px;
            }

            &.datatable-header-cell {
              font-size: 10px;
              color: $grey-blue;

              &.sortable {
                .sort-btn {
                  min-width: 18px;
                }

                div {
                  span.datatable-header-cell-label {
                    display: inline-block;
                    text-align: center;
                    vertical-align: middle;
                  }
                }

                &.sort-active {
                  div {
                    span.datatable-header-cell-wrapper {
                      span {
                        font-family: Figtree;
                        font-weight: 600;
                        color: $grey-main;
                      }
                    }
                  }

                  &.sort-asc,
                  &.sort-desc {
                    div {
                      position: relative;

                      span.sort-btn {
                        &:before {
                          display: block;
                          font-size: 16px;
                          color: $blue-main;
                          transform: rotate(180deg);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &-body {
          &-row {

            &.active-shopper {
              background: rgba(#faff00, 0.06);
            }

            &.active {
              background-color: $onahau;
            }

            &:hover {
              background-color: $ice-blue-two !important;

              .datatable-row-group {
                background-color: unset !important;
              }

              .status {
                display: none;
              }

              .preview-link {
                visibility: visible;
                opacity: 1;
                height: auto;
                transition: 0.3s linear opacity;
              }

              .lead-action {
                @include minW(1201px) {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }

            &.is-new {
              .datatable-row-group {
                box-shadow: inset 2px 0 0px 0px $blue-main;
                background-color: $ice-blue-two;
              }
            }

            &.not-my-lead {
              background: #fff7f8;
              box-shadow: inset 0 -1px 0 0 #f2e6e8;
            }

            &.my-lead {
              background: #f5fff6;
              box-shadow: inset 0 -1px 0 0 #e6f2e7;
            }

            .datatable-body-cell {
              position: relative;
              padding: 10px;

              &-label {
                p {
                  width: 100%;
                  text-align: center;
                }
              }

              &.lead-cell-type {
                padding: 10px 0;
              }

              &.incorrect {
                span {
                  color: $steel-light;
                }
              }
            }
          }
        }
      }
    }

    .lead-cell {
      &.listed-red {
        color: $red !important;
      }

      .listed {
        display: inline-block;
        width: 82px;
        height: 22px;
        color: #fff;
        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        background-image: linear-gradient(to bottom, #ff5737, #f63048);
        border-radius: 5px;

        &.my-lead {
          background-image: linear-gradient(to bottom, #7ec939, #40b837);
        }
      }
    }

    .row {
      &__counters {
        position: relative;

        &-icon {
          position: absolute;
          top: 50%;
          right: -14px;
          transform: translateY(-50%);

          svg {
            width: 20px;
            height: 20px;
            fill: #9eb4cb;
          }
        }
      }

      &__menu {
        padding-left: 0 !important;
      }
    }

    .preview-link {
      visibility: hidden;
      opacity: 0;
      height: 0;

      &:hover {
        text-decoration: none;
      }
    }

    .type {
      display: inline-block;
      margin: 0 auto;
      height: 24px;
      width: 24px;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      border-radius: 5px;
      border: 1px solid transparent;
      cursor: default;

      &.selling {
        border-color: rgba(#0389ff, 0.4);
        color: #0389ff;
      }

      &.refinance {
        border-color: rgba(#6e00ff, 0.4);
        color: #6e00ff;
      }
    }

    .status {
      display: block;
      font-weight: normal;
      white-space: nowrap;
    }

    .lead-action {
      @include minW(1201px) {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s linear;
      }
    }

    &.multiselect--active {
      .material .datatable-body-row:hover {
        .home-info_report {
          .lead-address-preview {
            @include minW(1200px) {
              width: 100px;
            }
          }
        }
      }
    }
  }
}

.empty-data-table {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1170px;
  margin: 0 auto;
  margin-top: 70px;

  h2 {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    color: $grey-blue;
  }
}

.loading-collection {
  justify-content: center;
  margin-top: 120px;
  text-align: center;
}

.leads-table.table-wrap {
  &.multiselect {
    &.show-summary {
      .material .datatable-header-cell {
        &.collspan {
          width: 90% !important;
        }
        &:not(.shown) {
          display: none;
        }
      }
    }
  }

}
