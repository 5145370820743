$points: '480px', '560px', '768px', '1024px', '1200px';

.hidden {
  opacity: 0 !important;
  visibility: hidden !important;
}

.visible {
  opacity: 1 !important;
  visibility: visible !important;
}

@each $point in $points {
  .hidden-from-#{str-slice($point, 0, -3)} {
    @include minW($point) {
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }
}

@each $point in $points {
  .visible-from-#{str-slice($point, 0, -3)} {
    @include minW($point) {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
}

@each $point in $points {
  .hidden-to-#{str-slice($point, 0, -3)} {
    @include maxW($point) {
      display: none !important;
    }
  }
}

@each $point in $points {
  .visible-to-#{str-slice($point, 0, -3)} {
    @include maxW($point) {
      display: block !important;
    }
  }
}
