// Simple navigation
//
// ul.tab-nav
//   li.tab-nav__item
//     .tab-nav__link
//       span.tab-nav__label Leads
//       span.tab-nav__total 12
//       span.tab-nav__new +10
//   li.tab-nav__item
//     .tab-nav__link.active
//       span.tab-nav__label Leads
//       span.tab-nav__total 12
//       span.tab-nav__new +10
//
// Page Header
//
// ul.tab-nav
//   li.tab-nav__item.tab-nav__item--single
//     .tab-nav__link.active
//       span.tab-nav__label Accounts
//       span.tab-nav__total 26
//       span.tab-nav__new +26

@import "src/assets/scss/mixins/all";

.tab-nav {
  display: flex;
  max-width: 1170px; // todo:: remove on finish with all possible tabs and sub-tabs
  margin: 0 auto;   // todo:: remove on finish with all possible tabs and sub-tabs
  overflow: auto hidden;

  @include maxW(1200px) {
    margin: 0 15px;
  }

  &__total {
    margin-left: 6px;
    font-size: 20px;
    color: $blue-grey-four;
  }

  &__new {
    min-width: 23px;
    margin: 1px 0 auto 6px;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 1.14;
    color: #fff;
    background-color: $blue-main;
    border-radius: 12px;
  }

  &__item {
    align-self: center;
    margin-right: 40px;

    @include maxW(550px) {
      margin-right: 18px;
    }

    &--align-right {
      align-self: center;
      margin-left: auto;
    }

    &--button-wrapper {
      display: flex;
      margin-right: 0;

      & iq-button {
        padding-right: 4px;
      }
    }

    &--single {
      .tab-nav__link {
        &:after {
          display: none;
        }
      }
    }

    &--search {
      margin-right: 0;
    }
  }

  &__link {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 24px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
    white-space: nowrap;
    outline: none;
    cursor: pointer;

    @include maxW(767px) {
      font-size: 16px;
      padding-top: 15px;
      padding-bottom: 15px;
      line-height: 1.56;
      align-items: center;
    }

    &:hover {
      text-decoration: none;
      color: $grey-main;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      display: block;
      width: 0;
      height: 1px;
      background-color: $blue-main;
      overflow: hidden;
      transition: width 0.2s ease-in;
    }

    &.active {
      &:after {
        width: 100%;
        transition: width 0.2s ease-out;
      }

      .tab-nav__label {
        color: $grey-main;
      }

      .tab-nav__total {
        color: $steel;
      }
    }
  }

  &__label {
    color: $steel;
  }

  &__btn-back {
    position: absolute;
    top: 22px;
    left: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #f7f7f7;
  }

  &__btn-close {
    cursor: pointer;
  }
}

.tab-nav__menu {
  @extend .tab-nav;
  border: none;

  .control-btn {
    background-color: transparent;
  }
}
