@import "src/assets/scss/mixins/all";

.activity {
  &-list {
    position: relative;
    display: block;
    padding: 15px 15px 0;

    @include maxW(560px) {
      padding: 15px 0;
    }

    .last-item {
      .activity-item {
        &:after {
          display: none;
        }
      }
    }
  }

  &-item {
    flex-wrap: nowrap;
    position: relative;
    margin-bottom: 24px;
    padding: 8px;

    @include maxW(560px) {
      margin-bottom: 0;
      border-bottom: 1px solid #d1d9e2;

      &.b-c-azure {
        background-color: $azure-10;
      }

      &.b-c-ice-blue {
        background-color: $ice-blue
      }
    }

    @include minW(560px) {
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 40px;
        top: 64px;
        left: 36px;
        background: #d1d9e2;
      }
    }

    &.disabled {
      filter: grayscale(1);
      opacity: 0.7;
    }

    &__avatar {
      position: relative;
      padding: 8px;

      &--yellow-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #febe00;
        border-radius: 20px;
      }

      &--iq-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-image: linear-gradient(to left, #0daeff, #0080ff);
        border-radius: 20px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-basis: auto;
      flex-grow: 0;
      padding-top: 6px;
      padding-left: 2px;

      @include minW(561px) {
        &.b-c-azure {
          padding-right: 15px;
          padding-left: 12px;
          background-color: $azure-10;
          box-shadow: 0 2px 0 0 rgba(3, 137, 255, 0.2);
          border-radius: 8px;
        }

        &.b-c-ice-blue {
          padding-right: 25px;
          padding-left: 12px;
          background-color: $ice-blue;
          box-shadow: none;
          border-radius: 8px;
        }
      }

      a:hover {
        color: unset;
        text-decoration: underline;

        b {
          text-decoration: underline;
        }
      }

      &-header {
        padding-left: 2px;
        font-size: 16px;
        line-height: 24px;
        color: $grey-main;

        b {
          display: inline-block;
        }
      }

      &-description {
        display: inline-block;

        @include minW(560px) {
          position: relative;
        }

        &-image {
          display: inline;
          font-size: 14px;
          text-align: center;
          line-height: 24px;
        }

        &-date {
          display: inline-block;
          padding: 0 0 0 6px;
          font-size: 11px;
          line-height: 16px;
          color: $slate;
          opacity: 0.5;
        }

        &-details {
          display: inline;
          flex: auto;
          position: relative;
          padding: 0 0 0 16px;
          font-size: 11px;
          line-height: 14px;
          color: $slate;

          &:before {
            content: '';
            position: absolute;
            top: 6px;
            left: 7px;
            width: 2px;
            height: 2px;
            font-size: 11px;
            line-height: 14px;
            background-color: $slate;
            border-radius: 50%;
          }
        }

        &-icon {
          position: absolute;
          top: 9px;
          right: -23px;
          width: 16px;
          height: 16px;

          svg {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 12px;
            height: 12px;
            fill: #9eb4cb;
          }

          @include maxW(560px) {
            top: 55px;
            left: 26px;
            right: unset;
            width: 20px;
            height: 20px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    &.ghost {
      .activity-item__avatar {
        min-width: 40px;
        height: 40px;
        margin: 8px;
        border-radius: 20px;
        @include ghost-animation;
      }

      .activity-item__content {
        padding-left: 10px;
      }

      .activity-item__content-header {
        width: 318px;
        height: 11px;
        margin-top: 5px;
        margin-bottom: 10px;
        border-radius: 5px;
        @include ghost-animation;

        @include maxW(400px) {
          width: 240px;
        }
      }

      .activity-item__content-description {
        display: flex;
        align-items: center;
        width: 248px;

        @include maxW(400px) {
          width: 220px;
        }
      }

      .activity-item__content-description-image {
        width: 16px;
        height: 16px;
        border-radius: 5px;
        @include ghost-animation;
      }

      .activity-item__content-description-date {
        width: 55px;
        height: 6px;
        margin: 0 14px 0 10px;
        border-radius: 5px;
        @include ghost-animation;
      }

      .activity-item__content-description-details {
        height: 6px;
        border-radius: 5px;
        @include ghost-animation;

        &:before {
          display: none;
        }
      }
    }
  }
}
