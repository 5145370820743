:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

// colors
$azure: #0babff;
$azure-two: #0daeff;
$azure-three: #0089ff;
$blue: #26a5ff;
$blue-main: #0389ff;
$blue-main-two: #0389FF29;
$deep-sky-blue: #0080ff;
$leafy-green: #0AA765;
$leafy-green-two: #40B837CC;
$leafy-green-three: #0AA7651F;
$leafy-green-four: #0AA7650A;
$light-blue: #0DAAFF;
$dark-blue: #0C509E;
$grey-blue: #8194aa;
$azure-10: #e6f4ff;
$grey-main: #333940;
$blue-grey: #5f7389;
$blue-grey-two: #7f93a9;
$blue-grey-three: #98a5b3;
$blue-grey-four: #94a5b8;
$ice-blue: #f4f8f9;
$ice-blue-two: #e6f4ff;
$light-blue-grey: #d5dce6;
$cloudy-blue: #b8c2cc;
$cloudy-blue-two: #c4d4df;
$light-blue-grey-two: #d8e1e8;
$pale-grey: #ebeff2;
$pale-grey-two: #dadfe6;
$pale-grey-three: #f7fafc;
$pale-grey-five: #dfe5eb;
$natural-white: #ffffff;
$steel-light: #9cacbe;
$steel-two: #718296;
$steel-three: #9CACBE4D;
$steel-four: #9CACBE0A;
$navy: #001e40;
$steel: #78899c;
$slate: #536880;
$slate-two: #596c80;
$slate-three: #53688026;
$slate-four: #5368800D;
$pale-sky-blue: #dae1e6;
$charcoal-grey: #323c47;
$navy-two: #01264f;
$navy-three: #051f3e;
$dark-indigo: #0f2e4d;
$dark-indigo-three: #0a2540;
$dark-indigo-four: #061729;
$orange: #cf7c1d;
$orange-main: #ff6227;
$orange-one: #FFC266;
$red: #ff3d55;
$red-secondary: #FF3D5514;
$vermillion: #ff1010;
$amber: #FEBE003D;
$onahau: #cde7ff;
$supernova: #FFF9D9;
$mount-olympus: #D4FAFF;
$sizzling-red: rgba(255, 61, 85, 0.2);
$oxford-blue: #02244726;
$oxford-blue-level2: #0224471A;
$cadet-blue: #9CACBE26;
$azure-four: #0389FF1F;
$navy-light: #001E4014;
$rich-black: #030512;
$caribbean-green: #17CA8433;

// gradients
$blue-gradient: linear-gradient(to right, #0080ff 0%, #0daeff 51%, #0080ff 100%);
$blue-simple-gradient: linear-gradient(to left, $azure-two, $deep-sky-blue);
$red-gradient: linear-gradient(to right, #f63048 0%, #ff5737 51%, #f63048 100%);
$red-simple-gradient: linear-gradient(to left, #ff5737, #f63048);
$ghost-gradient: linear-gradient(135deg, #e4ebf2, #f4f7fb, #e4ebf2);

// shadows
$card-shadow: 0 0 3px 0 rgba(2, 36, 71, 0.1), 0 3px 10px 0 rgba(2, 36, 71, 0.1);

// z-index
$tooltip-zindex: 1070;

// UI
$warning-background-color: rgba(254, 190, 0, 0.2);
$danger-background-color: #FF3D5529;

// Adaptive
$desktop-menu-breakpoint: 1100px;
$tablet-breakpoint: 768px;
$phablet-breakpoint: 480px;

// sizes
$sizes: (1x: 8px, 2x: 16px, 3x: 24px, 4x: 32px, 5x: 40px, 6x: 48px, 7x: 56px, 8x: 64px, 9x: 72px, 10x: 80px, 11x: 88px, 12x: 96px);
@function size($size) {
  @return map-get($sizes, $size);
}
