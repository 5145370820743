$points: '480px', '560px', '768px', '1024px', '1200px';

.no-display {
  display: none;
}

@each $point in $points {
  .no-display-from-#{str-slice($point, 0, -3)} {
    @include minW($point) {
      display: none !important;
    }
  }
}

@each $point in $points {
  .no-display-to-#{str-slice($point, 0, -3)} {
    @include maxW($point) {
      display: none !important;
    }
  }
}

.block {
  display: block;
}

@each $point in $points {
  .block-from-#{str-slice($point, 0, -3)} {
    @include minW($point) {
      display: block;
    }
  }
}

@each $point in $points {
  .block-to-#{str-slice($point, 0, -3)} {
    @include maxW($point) {
      display: block;
    }
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;

  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.flex-1 {
  flex: 1;
}

@each $point in $points {
  .flex-from-#{str-slice($point, 0, -3)} {
    @include minW($point) {
      display: flex;
    }
  }
}

@each $point in $points {
  .flex-to-#{str-slice($point, 0, -3)} {
    @include maxW($point) {
      display: flex;
    }
  }
}

@for $i from 1 through 5 {
  .order-#{$i} {
    order: #{$i} !important;
  }
}

.flex-auto {
  flex: 1 1 auto;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-evenly {
  justify-content: space-evenly;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-self-end {
  justify-self: end;
}

$gaps: (2, 4, 5, 8, 10, 12, 14, 15, 16, 17, 18, 20, 25, 30, 32, 40);

@each $gap in $gaps {
  .gap-#{$gap} {
    gap: #{$gap}px
  }
}

.align-center {
  display: flex;
  align-self: center;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.position-relative {
  position: relative;
}
