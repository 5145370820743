// .send-modal / .send-modal.open
//     .send-modal__container
//       .send-modal__content
//         .send-modal__close.inline-svg
//         .send-modal__header
//         .send-modal__items
//          button1
//          button2
//          ...

@import "src/assets/scss/_variables";

.send-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: rgba(0, 30, 64, 0.8);
  overflow: auto;
  z-index: 9;

  &.open {
    display: flex;
    margin: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 0 15px 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(2, 36, 71, 0.2),
      0 3px 10px 0 rgba(2, 36, 71, 0.1);
  }

  &__content {
    position: relative;
  }

  &__header {
    position: static;
    width: 100%;
    margin-bottom: 10px;
    padding: 9px 0;
    border-bottom: 1px solid $pale-grey;
    text-align: center;
    line-height: 32px;
    font-weight: 600;
    color: $grey-main;
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item {
    margin: 0 auto 8px;
    width: 80%;

    .button {
      width: 100%;
    }

    .button-label {
      height: auto !important;
      white-space: normal !important;
    }
  }

  &__close {
    position: absolute;
    top: -30px;
    right: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
}
