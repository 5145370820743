$container-width: 1360px;
$container-width-md: 1202px;
$mobile-side-margin: 16px;

.hiq-container {
  max-width: $container-width;
  margin: 0 auto;

  &--md {
    max-width: $container-width-md;
  }

  @media (max-width: calc(#{$container-width} + #{$mobile-side-margin} * 2)) {
    padding: 0 $mobile-side-margin;
  }
}
