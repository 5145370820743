@import "src/assets/scss/variables";
@import "src/assets/scss/mixins/all";

.settings {
  &__form {
    position: relative;
    margin: 0 auto;
  }

  .settings-support {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__form-group-wrap {
    width: 100%;
    border-bottom: 1px solid $pale-sky-blue;
  }

  &__form-group {
    position: relative;
    margin: 0 auto;
    padding-top: 10px;

    .settings__col {
      max-width: 570px;
    }
  }
}
