@import "src/assets/scss/mixins/media";
@import "src/assets/scss/variables";

.settings-flex-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 80px;

  @include maxW(1350px) {
    flex-direction: column;
    gap: 64px;
    align-items: center;
  }
}

.settings-content-wrapper {
  width: 100%;

  @include maxW(1350px) {
    max-width: calc(100vw - #{size(4x)});
  }
}

.inner-container {
  max-width: 740px;

  @include maxW(1350px) {
    max-width: calc(100vw - 270px);
  }

  @include maxW(1100px) {
    max-width: calc(100vw - 60px);
  }
}

.settings-support {
  min-width: 270px;

  @include maxW(1024px) {
    min-width: initial;
  }
}
