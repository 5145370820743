@import "src/assets/scss/_variables";
@import "src/assets/scss/mixins/all";

$decor-bg: '/assets/components/sign-in/bg/sign-bg.png';
$decor-bg-homeiq: '/assets/components/sign-in/bg/sign-up.jpg';
$logo: '/assets/components/toolbar/logo.svg';

.auth {
  &__decor {
    flex: 0 1 auto;
    width: 453px;
    background-image: url($decor-bg);
    background-color: $navy;
    background-position: bottom center;
    background-repeat: no-repeat;

    @include maxW(1024px) {
      display: none;
    }
  }

  &__logo {
    display: block;
    width: 150px;
    height: 36px;
    margin: 88px auto 0;
    background: url($logo);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__title {
    margin: 0 0 16px;
    font-size: 32px;
    color: $blue-main;
  }

  &__subtitle {
    margin: 0 0 32px;
    font-size: 14px;
    color: $steel;
  }

  &__form {
    width: 450px;

    @include maxW(480px) {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
