.scroll-shadow {
  position: sticky;
  bottom: 0;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 48px;
    background-image: linear-gradient(to top, rgba(251, 251, 251, 1), rgba(251, 251, 251, 0));
    opacity: 0;
    transition: opacity 0.27s ease;
    z-index: 1;
  }

  &.show {
    &:before {
      opacity: 1;
    }
  }
}

.scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    transition: width 0.3s ease;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    width: 12px;
    transition: width 0.3s ease;
  }
}
