@import 'src/assets/scss/variables';

/* Mixins */
@mixin datatable-label {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: Figtree;
  font-size: 14px;
  line-height: 1.43;
}

@mixin hover-to-show-element {
  .hover-to-show {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  &:hover {
    .hover-to-show {
      visibility: visible;
      opacity: 1;
    }
  }
}

@mixin square-table-button {
  .control-btn {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.3s;

    &:hover {
      background-color: $pale-sky-blue;
    }
  }
}

.empty-data-table {
  padding: 60px;
  text-align: center;

  &__title {
    margin: 0;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.89;
    color: $slate;
  }

  &__refi-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: $grey-blue;
  }

  &__intell-title {
    margin-top: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $slate;
  }

  &__description {
    display: flex;
    text-align: left;

    &--image {
      max-width: 160px;
    }

    &--text {
      margin-left: 40px;
      max-width: 400px;
      font-size: 14px;
      line-height: 22px;
      color: $slate;

      &-title {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: $grey-main;
      }

      &-list {
        padding-left: 20px;

        li {
          list-style-type: disc;
        }
      }
    }
  }
}


.table-wrap {
  max-width: 1200px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding-top: 0;
  padding-right: 15px;
  padding-left: 15px;

  @include maxW(1270px) {
    max-width: unset;
    width: 1170px;
  }
}

.highlight-yellow {
  background: rgba(#faff00, 0.06);
}
