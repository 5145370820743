// Use with getPaymentStatusClass(param) method

.subscription-label {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 3px;
  background-color: $pale-grey;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.8px;
  color: $slate;

  &::first-letter {
    text-transform: uppercase;
  }

  &.pending {
    background-color: #ffeeb3;
    color: $orange;
  }

  &.paid {
    background: rgba(10, 167, 101, 0.16);
    color: $leafy-green;
  }

  &.unpaid {
    background-color: rgba($red, 0.2);
    color: $red;
  }

  &.canceled {
    background-color: $slate;
    color: #fff;
  }

  &.paused {
    background-image: linear-gradient(to left, #ffcb0c, #ffa217);
    color: #fff;
  }
}
