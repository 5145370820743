.control-btn {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s;
  background-color: transparent;
  cursor: pointer;

  &.dots {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.plus {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.filters {
    display: flex;
    align-items: center;
    justify-content: center;

    @include minW(768px) {
      display: none;
    }
  }

  &.bg-g {
    background-color: $pale-grey;
  }

  &:hover {
    background-color: $pale-sky-blue;
  }
}

.control-btn--square {
  @extend .control-btn;
  border-radius: 8px;
}
