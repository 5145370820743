.iq-modal {
  &-content {
    &.rare {
      background-color: #fff;

      .modal-header {
        &::before {
          display: none;
        }

        .modal-title {
          padding-top: 50px;
          padding-bottom: 20px;
          font-size: 14px;
          font-weight: bold;
          color: $slate;
        }
      }

      .modal-body {
        &__text {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.57;
          color: $slate;
        }
      }
    }
  }
}
