/*
.f-15-400 {
  font-size: 15px;
  font-weight: 400;
}

.l-h-24 {
  line-height: 24px;
}

.f-c-gray-main {
  color: $grey-main;
}
*/

$font_size: (10, 11, 12, 13, 14, 16, 18, 19, 20, 24, 26, 32, 34, 40, 64);
$font_weight: (normal, 400, 500, 600, 700, bold);
$font_color:
  'azure' $azure,
  'blue-main' $blue-main,
  'blue-grey' $blue-grey,
  'blue-grey-two' $blue-grey-two,
  'blue-grey-three' $blue-grey-three,
  'blue-grey-four' $blue-grey-four,
  'charcoal-grey' $charcoal-grey,
  'cloudy-blue' $cloudy-blue,
  'gray-main' $grey-main,
  'grey-blue' $grey-blue,
  'light-blue-grey' $light-blue-grey,
  'slate' $slate,
  'steel-light' $steel-light,
  'steel' $steel,
  'vermillion' $vermillion,
  'white' $white,
  'orange' $orange,
  'pale-sky-blue' $pale-sky-blue,
  'leafy-green' $leafy-green,
  'red' $red;

$line_heights: (14, 16, 18, 22, 24, 30, 32, 36, 40, 44, 48);

$breakpoints: (640, 768, 1024, 1280);

@each $size in $font_size {
  @each $weight in $font_weight {
    .f-#{$size}-#{$weight} {
      font-size: #{$size}px !important;
      font-weight: #{$weight} !important;
    }
  }
}

@each $line_height in $line_heights {
  .l-h-#{$line_height} {
    line-height: #{$line_height}px !important;
  }
}

@each $classname, $color in $font_color {
  .f-c-#{$classname} {
    color: $color !important;
  }
}

@each $breakpoint in $breakpoints {
  @media only screen and (min-width: #{$breakpoint}px) {
    @each $size in $font_size {
      @each $weight in $font_weight {
        .f-#{$size}-#{$weight}-min-#{$breakpoint} {
          font-size: #{$size}px !important;
          font-weight: #{$weight} !important;
        }
      }
    }
  }

  @media only screen and (max-width: #{$breakpoint}px) {
    @each $size in $font_size {
      @each $weight in $font_weight {
        .f-#{$size}-#{$weight}-max-#{$breakpoint} {
          font-size: #{$size}px !important;
          font-weight: #{$weight} !important;
        }
      }
    }
  }
}

.text-overflow {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.font-style-italic {
  font-style: italic !important;
}
