.iq-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: $zindex-modal;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  padding: 20px 0;
  background-color: rgba(0, 30, 64, 0.6);
  outline: 0;
  pointer-events: none;

  @include minW(900px) {
    margin-right: calc(-1 * (100vw - 100%));
  }

  @include maxW(560px) {
    padding: 20px size(2x);
    align-items: flex-start;
  }

  @media (max-height: 480px) and (orientation: landscape) {
    align-items: flex-start;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 570px;
    max-width: calc(100vw - #{size(4x)});
    pointer-events: auto;
    background-color: $pale-grey;
    background-clip: padding-box;
    border-radius: size(1x);
    outline: 0;

    &__wide {
      width: 920px;

      @include maxW(920px) {
        width: 100%;
      }
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 80px;
      padding: 0 size(8x);
      background-color: #fff;
      border-radius: 10px 10px 0 0;

      @include maxW(560px) {
        padding: 0 size(2x);
      }

      &::before {
        content: "";
        position: absolute;
        bottom: -11px;
        left: 0;
        right: 0;
        width: 30px;
        height: 30px;
        margin: auto;
        background-color: #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        border-radius: 6px;
        z-index: 2;
      }

      &.modal-hide-caret {
        &::before {
          display: none;
        }
      }

      .modal-title {
        font-size: size(3x);
        font-weight: 500;
        color: $grey-main;
      }
    }

    .modal-body {
      text-align: center;
      padding: size(4x) size(5x) size(4x);

      @include maxW(560px) {
        padding: size(5x) size(2x) size(4x);
      }

      &__icon {
        margin-bottom: size(3x);
      }

      &__title {
        margin-bottom: size(1x);
        font-weight: 600;
      }

      &__text {
        line-height: 1.5;
        color: $grey-main;
      }

      .image-full-width {
        margin: 0 -24px;

        img {
          width: 100%;
        }

        @include maxW(560px) {
          margin: 0;
        }
      }
    }

    .modal-footer {
      padding: 0 size(5x) size(3x);

      iq-button {
        margin-bottom: size(2x);
      }

      @include maxW(560px) {
        padding: 0 size(2x) size(3x);
      }
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: size(5x);
      height: size(5x);
      color: #fff;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &.fade-anim {
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    &.in {
      opacity: 1;

      .modal-content {
        animation: modal-fade-in-top 0.3s;
      }

      .modal-sidebar {
        animation: modal-fade-in-right 0.3s;
      }
    }

    .modal-sidebar {
      animation: modal-fade-out-right 0.3s;
    }
  }
}

.iq-modal-top > .iq-modal {
  align-items: flex-start;
}

.iq-modal-left > .iq-modal {
  justify-content: flex-start;
}

.iq-modal-right > .iq-modal {
  justify-content: flex-end;
}

.iq-modal-bottom > .iq-modal {
  align-items: flex-end;
}

.iq-backdrop-no-bg {
  inset: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: $zindex-modal-backdrop;
}
