@import "src/assets/scss/_variables";
@import "src/assets/scss/mixins/all";

$propertyImagePlaceholder: '/assets/components/custom-table/house.svg';

.ftl-viral {
  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    padding: 0 size(5x) size(5x);

    @include maxW(768px) {
      padding: 0 size(2x) size(2x);
    }
  }

  &__content {
    padding: size(11x) size(7x) 0;

    @include maxW(1024px) {
      padding-right: size(3x);
      padding-left: size(3x);
    }

    @include maxW(768px) {
      padding: size(4x) 0;
    }

    .button {
      .button-label {
        display: block !important;
      }
    }
  }

  &__title {
    margin-bottom: size(5x);
    font-size: 32px;
    line-height: 44px;
    font-weight: bold;
    color: $grey-main;

    @include maxW(768px) {
      margin-bottom: size(4x);
      line-height: 36px;
      font-size: 24px;
    }
  }

  &__subtitle {
    font-size: 20px;
    line-height: 30px;
    color: $grey-main;

    @include maxW(768px) {
      line-height: 26px;
      font-size: 16px;
    }

    b {
      font-weight: 700;
    }
  }

  &__tour-steps {
    display: flex;
    width: 100%;

    @include maxW(580px) {
      flex-direction: column;
    }
  }

  &__counter {
    margin-right: size(2x);
    font-size: 64px;
    font-weight: bold;

    @include maxW(768px) {
      font-size: 48px;
    }
  }

  &__skip-btn {
    &.btn {
      margin-top: auto;

      @include maxW(768px) {
        margin-top: 24px;
      }

      &.gray-btn {
        color: $slate;

        .inline-svg svg {
          fill: $slate;
        }
      }
    }
  }

  &__collection {
    max-width: 480px;

    .count {
      line-height: 60px;
      background: $blue-simple-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &.negative {
        background: $red-simple-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .items {
      .item {
        &.shadow {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
          }
        }

        .preview {
          width: 88px;
          height: 56px;
          border-radius: 4px;
          background-color: $pale-grey;
          overflow: hidden;
          background-image: url($propertyImagePlaceholder);
          background-size: 100% 80%;
          background-repeat: no-repeat;
          background-position: center bottom;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .info {
          position: relative;
          flex-direction: column;
          justify-content: center;
        }

        .name {
          line-height: 1.57;
        }

        .address {
          line-height: 1.4;
        }

        .date {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          font-size: 14px;
          line-height: 22px;
          font-weight: 600;

          @include maxW(768px) {
            position: static;
            transform: none;
            line-height: 18px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
