$sides: (12, 16, 20, 22, 24, 40, 48, 56, 64, 80, 120, 256);
$colors: ("blue-main": $blue-main, "white": #fff, "steel": $steel,);

.inline-svg {
  svg {
    width: 24px;
    height: 24px;
    fill: $steel-light;
  }

  &.red {
    svg {
      fill: $red;
    }
  }

  &.green {
    svg {
      fill: $leafy-green;
    }
  }

  &.landing-preview__logo {
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@each $side in $sides {
  .inline-svg--#{$side} {
    svg {
      width: #{$side}px;
      height: #{$side}px;
    }
  }
}

@each $key, $value in $colors {
  .inline-svg--#{$key} {
    svg {
      fill: $value;
      stroke: $value;
    }
  }
}
