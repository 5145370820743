*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

.control--size-48 .control {
  min-height: 48px;
  height: 48px;
}
.control--size-48 .control__label {
  padding: 4px;
}

.control {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 56px;
  border-radius: 6px;
  box-shadow: none;
  transition: box-shadow 0.2s ease;
}
.control__label {
  display: flex;
  align-items: flex-start;
  height: 100%;
  padding: 8px;
  background-color: #ebeff2;
}
.control__label-text {
  min-width: 0;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #78899c;
}
.control__input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  color: #333940;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  background-color: #fff;
  border: none;
  -webkit-appearance: none;
}
.control__input.placeholder {
  color: #9cacbe;
}
.control__input:-moz-placeholder {
  color: #9cacbe;
}
.control__input::-moz-placeholder {
  color: #9cacbe;
}
.control__input:-ms-input-placeholder {
  color: #9cacbe;
}
.control__input::-webkit-input-placeholder {
  color: #9cacbe;
}
.control__input:focus {
  z-index: 1;
}
.control__input:disabled {
  background-color: #ebeff2;
  color: #b8c2cc;
  cursor: not-allowed;
}
.control__prepend {
  position: relative;
  flex: 0 0 33.333%;
}
@media (max-width: 420px) {
  .control__prepend {
    max-width: 33.333%;
  }
}
.control__prepend > * {
  border-width: 1px;
  border-style: solid;
  border-color: #d5dce6;
  border-radius: 6px 0 0 6px;
  transition: border-color 0.2s;
}
.control__prepend:only-child {
  flex: 1;
}
.control__prepend:only-child > * {
  border-radius: 6px;
}
.control__content {
  position: relative;
  flex: 0 0 calc(66.666% + 1px);
  margin-left: -1px;
}
.control__content > * {
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #d5dce6;
  border-radius: 0 6px 6px 0;
  transition: border-color 0.2s;
}
.control__content:only-child {
  flex: 1;
  margin-left: 0;
}
.control__content:only-child > *,
.control__content:only-child .control__input {
  border-radius: 6px;
  height: auto;
}
.control__append {
  position: relative;
  flex: 1;
  margin-left: 8px;
}
.control--focus {
  box-shadow: 0 4px 8px 0 rgba(11, 171, 255, 0.2);
}
.control--focus .control__prepend > *,
.control--focus .control__content > * {
  border-color: #0389ff;
}
.control--readonly {
  box-shadow: none;
}
.control--readonly .control__prepend > *,
.control--readonly .control__content > * {
  background-color: #ebeff2;
}
.control--disable {
  box-shadow: none;
}
.control--disable .control__prepend > *,
.control--disable .control__content > * {
  background-color: #ebeff2;
}
@media (max-width: 768px) {
  .control.extra-control {
    width: calc(100% - 56px);
  }
}
.control.wide-prepend .control__prepend {
  flex: 0 0 66.666%;
}
@media (max-width: 768px) {
  .control.wide-prepend .control__prepend {
    flex: 0 0 50%;
  }
}
.control.wide-prepend .control__content {
  flex: 0 0 calc(33.333% + 1px);
}
@media (max-width: 768px) {
  .control.wide-prepend .control__content {
    flex: 0 0 50%;
  }
}
.control.broad-prepend .control__prepend {
  flex: 0 0 60%;
}
.control.broad-prepend .control__content {
  flex: 0 0 calc(40% + 1px);
}
.control.content-prepend .control__prepend {
  flex: 0 0 75%;
}
.control.content-prepend .control__content {
  flex: 0 0 25%;
}
.control textarea {
  font-size: 12px;
  resize: vertical;
}

.control-error-message {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  color: #ff1010;
}

@keyframes onautofillstart {}
@keyframes onautofillcancel {}
.alternative-label .control {
  display: block;
}
.alternative-label .control__content {
  margin-left: 0;
  border-radius: 6px;
  background-color: #fff;
}
.alternative-label .control__content > * {
  border-radius: 6px;
}
.alternative-label .control__prepend {
  max-width: 100%;
}
.alternative-label .control__input {
  border-radius: 6px !important;
}
.alternative-label .control__label {
  display: block;
  margin-bottom: 4px;
  padding: 2px 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.alternative-label .control__label-text {
  padding: 0;
  line-height: 20px;
  font-weight: 600;
  color: #536880;
}
.alternative-label .form-error__item {
  line-height: 18px;
}
.alternative-label .form-error__item:not([hidden]) {
  margin-top: 6px !important;
}
.alternative-label.ng-dirty.ng-valid .control--focus .control__content {
  box-shadow: 0 4px 8px 0 rgba(11, 171, 255, 0.2);
}
.alternative-label.ng-dirty.ng-invalid .control--focus .control__content {
  box-shadow: 0 4px 8px 0 rgba(255, 16, 16, 0.2);
}
.alternative-label .control--focus {
  box-shadow: none !important;
}
.alternative-label .control--focus .control__content {
  box-shadow: 0 4px 8px 0 rgba(11, 171, 255, 0.2);
}
.alternative-label__no-label .control__prepend {
  display: none;
}

.form-global-error {
  color: #ff3d55;
  font-size: 12px;
  line-height: 1.5;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 0 15px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  background: transparent;
  border: none;
}
.btn.link {
  height: auto;
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #0389ff;
  text-decoration: none;
  font-size: inherit;
}
.btn.link:hover {
  text-decoration: underline;
}
.btn.link-dark {
  color: #000;
  text-decoration: underline;
}
.btn.link-dark:hover {
  text-decoration: none;
}

.control-btn, .control-btn--square {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s;
  background-color: transparent;
  cursor: pointer;
}
.control-btn.dots, .dots.control-btn--square {
  display: flex;
  justify-content: center;
  align-items: center;
}
.control-btn.plus, .plus.control-btn--square {
  display: flex;
  justify-content: center;
  align-items: center;
}
.control-btn.filters, .filters.control-btn--square {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .control-btn.filters, .filters.control-btn--square {
    display: none;
  }
}
.control-btn.bg-g, .bg-g.control-btn--square {
  background-color: #ebeff2;
}
.control-btn:hover, .control-btn--square:hover {
  background-color: #dae1e6;
}

.control-btn--square {
  border-radius: 8px;
}

/*
  .checkbox
    input.checkbox__input(type="checkbox")
    label.checkbox__label Checkbox label
*/
body {
  content: url("/assets/common/forms/form-controls/checkbox/checked_default.svg") url("/assets/common/forms/form-controls/checkbox/checked_hover.svg") url("/assets/common/forms/form-controls/checkbox/checked_disabled.svg") url("/assets/common/forms/form-controls/checkbox/indeterminate_default.svg") url("/assets/common/forms/form-controls/checkbox/indeterminate_hover.svg") url("/assets/common/forms/form-controls/checkbox/indeterminate_disabled.svg") url("/assets/common/forms/form-controls/checkbox/unchecked_default.svg") url("/assets/common/forms/form-controls/checkbox/unchecked_hover.svg") url("/assets/common/forms/form-controls/checkbox/unchecked_disabled.svg");
}

.checkbox__input {
  position: absolute;
  display: none;
  z-index: -1;
}
.checkbox__input + .checkbox__label {
  position: relative;
  margin: 0;
  padding: 16px 23px 16px 28px;
  font-size: 14px;
  font-weight: 500;
  color: #5f7389;
  cursor: pointer;
}
.checkbox__input + .checkbox__label:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/assets/common/forms/form-controls/checkbox/unchecked_default.svg");
}
.checkbox__input + .checkbox__label:hover:before {
  background-image: url("/assets/common/forms/form-controls/checkbox/unchecked_hover.svg");
}
.checkbox__input:disabled + .checkbox__label {
  cursor: not-allowed;
}
.checkbox__input:disabled + .checkbox__label:before {
  background-image: url("/assets/common/forms/form-controls/checkbox/unchecked_disabled.svg");
}
.checkbox__input:checked + .checkbox__label:before {
  background-image: url("/assets/common/forms/form-controls/checkbox/checked_default.svg");
}
.checkbox__input:checked + .checkbox__label:hover:before {
  background-image: url("/assets/common/forms/form-controls/checkbox/checked_hover.svg");
}
.checkbox__input:checked:disabled + .checkbox__label:before {
  background-image: url("/assets/common/forms/form-controls/checkbox/checked_disabled.svg");
}
.checkbox__input:indeterminate + .checkbox__label:before {
  background-image: url("/assets/common/forms/form-controls/checkbox/indeterminate_default.svg");
}
.checkbox__input:indeterminate + .checkbox__label:hover:before {
  background-image: url("/assets/common/forms/form-controls/checkbox/indeterminate_hover.svg");
}
.checkbox__input:indeterminate:disabled + .checkbox__label:before {
  background-image: url("/assets/common/forms/form-controls/checkbox/indeterminate_disabled.svg");
}

/*
  .form-radio
    input.form-radio__input(type="radio", id="some_id", name="some_name")
    label.form-radio__label(for="some_id") Checkbox label
*/
.form-radio__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.form-radio__input:checked + label::before {
  background-image: url("/assets/common/forms/form-controls/radio/radio-icon-active.svg");
}
.form-radio__input:disabled + label::before {
  background-image: url("/assets/common/forms/form-controls/radio/radio-icon-default.svg");
  background-color: #f0f2f6;
}
.form-radio__label {
  position: relative;
  margin: 0;
  padding: 16px 23px 16px 28px;
  font-size: 14px;
  color: #5f7389;
  cursor: pointer;
}
.form-radio__label:hover::before {
  background-image: url("/assets/common/forms/form-controls/radio/radio-icon-default.svg");
}
.form-radio__label::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 22px;
  height: 22px;
  margin: auto;
  background-image: url("/assets/common/forms/form-controls/radio/radio-icon-default.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.form-switch {
  position: relative;
  flex: 0 0 35px;
  width: 35px;
  height: 20px;
  margin-bottom: 0;
  border-radius: 15px;
  background-color: #dae1e6;
  font-weight: 600;
  cursor: pointer;
}
.form-switch__slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.2s linear;
  z-index: 2;
}
.form-switch__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  border-radius: 15px;
  opacity: 1;
  background-color: transparent;
  transition: all 0.2s linear;
  z-index: 1;
}
.form-switch__control {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}
.form-switch__control:checked ~ .form-switch__slider {
  left: 100%;
  margin-left: -18px;
}
.form-switch__control:checked ~ .form-switch__bg {
  background-color: #0AA765;
}
.form-switch__control:disabled ~ .form-switch__slider {
  cursor: not-allowed;
}
.form-switch__control:disabled ~ .form-switch__bg {
  background: #fff;
  opacity: 0.4;
}
.form-switch__control:disabled:checked ~ .form-switch__bg {
  background-color: #0AA765;
}
.form-switch__group {
  position: relative;
  min-height: 40px;
  margin: 10px 0;
  padding: 6px 0 6px 45px;
}
.form-switch__group--disabled .form-switch {
  cursor: default;
}
.form-switch__group--disabled .form-switch__group-title {
  color: rgba(83, 104, 128, 0.3);
}
.form-switch__group-title {
  margin-bottom: 0;
  color: #5f7389;
  line-height: 28px;
  font-size: 14px;
  font-weight: 600;
}
.form-switch__group .form-switch {
  position: absolute;
  top: 10px;
  left: 0;
}

:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

bs-dropdown-container {
  z-index: 1051 !important;
}

.control-dropdown {
  position: relative;
}
.control-dropdown__toggle:after {
  content: none;
}
.control-dropdown__menu {
  min-width: 100px;
  margin: 0 5px;
  padding: 5px 0;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: 0 5px 15px 0 rgba(2, 36, 71, 0.2), 0 3px 10px 0 rgba(2, 36, 71, 0.1);
}
@media (max-width: 750px) {
  .control-dropdown__menu {
    right: -26px;
    position: absolute;
  }
}
.control-dropdown__menu--profile-actions {
  margin-top: -10px;
}
.control-dropdown__item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 24px 4px 16px;
  color: #333940;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  transition: background-color 0.2s;
  cursor: pointer;
  outline: none;
}
.control-dropdown__item:hover {
  background-color: #ebeff2;
}
.control-dropdown__item:disabled {
  color: #ebeff2;
  cursor: default;
}
.control-dropdown__item:disabled:hover {
  background-color: #fff;
}
.control-dropdown__item.active {
  background-color: #e4eaf2;
}
.control-dropdown__item .inline-svg {
  margin-right: 4px;
}
.control-dropdown__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.control-dropdown__icon ::ng-deep svg {
  vertical-align: unset;
}
.control-dropdown__label {
  flex: 1;
  text-decoration: none !important;
  color: #333940 !important;
}
.control-dropdown__divider {
  height: 1px;
  border: none;
  background: #ebeff2;
}
.control-dropdown.show .dropdown-toggle {
  background-color: transparent;
}

.form-control-radio {
  position: relative;
}
.form-control-radio__label {
  margin-bottom: 0;
  border: 1px solid rgba(3, 137, 255, 0.2);
}
.form-control-radio__label:first-child {
  border-radius: 5px 0 0 5px;
}
.form-control-radio__label:last-child {
  border-radius: 0 5px 5px 0;
}
.form-control-radio__control {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}
.form-control-radio__control:checked ~ .form-control-radio__caption {
  color: #536880;
  background-color: rgba(3, 137, 255, 0.2);
  cursor: default;
}
.form-control-radio__caption {
  width: 32px;
  height: 32px;
  padding: 8px 0;
  font-size: 12px;
  font-weight: 600;
  color: #c4d4df;
  text-align: center;
  cursor: pointer;
}

:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

/* Mixins */
.empty-data-table {
  padding: 60px;
  text-align: center;
}
.empty-data-table__title {
  margin: 0;
  padding-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.89;
  color: #536880;
}
.empty-data-table__refi-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #8194aa;
}
.empty-data-table__intell-title {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #536880;
}
.empty-data-table__description {
  display: flex;
  text-align: left;
}
.empty-data-table__description--image {
  max-width: 160px;
}
.empty-data-table__description--text {
  margin-left: 40px;
  max-width: 400px;
  font-size: 14px;
  line-height: 22px;
  color: #536880;
}
.empty-data-table__description--text-title {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #333940;
}
.empty-data-table__description--text-list {
  padding-left: 20px;
}
.empty-data-table__description--text-list li {
  list-style-type: disc;
}

.table-wrap {
  max-width: 1200px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding-top: 0;
  padding-right: 15px;
  padding-left: 15px;
}
@media (max-width: 1270px) {
  .table-wrap {
    max-width: unset;
    width: 1170px;
  }
}

.highlight-yellow {
  background: rgba(250, 255, 0, 0.06);
}

:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.leads-table__name {
  width: 100%;
}
.leads-table__name span {
  max-width: 175px;
}
.leads-table.table-wrap .material .datatable-header-cell {
  display: flex;
  padding: 16px 0;
  align-items: center;
  text-align: center;
  white-space: pre-line;
}
.leads-table.table-wrap .material .datatable-header-cell.address {
  position: static;
  padding-left: 50px;
}
.leads-table.table-wrap .material .datatable-header-cell.datatable-header-cell {
  font-size: 10px;
  color: #8194aa;
}
.leads-table.table-wrap .material .datatable-header-cell.datatable-header-cell.sortable .sort-btn {
  min-width: 18px;
}
.leads-table.table-wrap .material .datatable-header-cell.datatable-header-cell.sortable div span.datatable-header-cell-label {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.leads-table.table-wrap .material .datatable-header-cell.datatable-header-cell.sortable.sort-active div span.datatable-header-cell-wrapper span {
  font-family: Figtree;
  font-weight: 600;
  color: #333940;
}
.leads-table.table-wrap .material .datatable-header-cell.datatable-header-cell.sortable.sort-active.sort-asc div, .leads-table.table-wrap .material .datatable-header-cell.datatable-header-cell.sortable.sort-active.sort-desc div {
  position: relative;
}
.leads-table.table-wrap .material .datatable-header-cell.datatable-header-cell.sortable.sort-active.sort-asc div span.sort-btn:before, .leads-table.table-wrap .material .datatable-header-cell.datatable-header-cell.sortable.sort-active.sort-desc div span.sort-btn:before {
  display: block;
  font-size: 16px;
  color: #0389ff;
  transform: rotate(180deg);
}
.leads-table.table-wrap .material .datatable-body-row.active-shopper {
  background: rgba(250, 255, 0, 0.06);
}
.leads-table.table-wrap .material .datatable-body-row.active {
  background-color: #cde7ff;
}
.leads-table.table-wrap .material .datatable-body-row:hover {
  background-color: #e6f4ff !important;
}
.leads-table.table-wrap .material .datatable-body-row:hover .datatable-row-group {
  background-color: unset !important;
}
.leads-table.table-wrap .material .datatable-body-row:hover .status {
  display: none;
}
.leads-table.table-wrap .material .datatable-body-row:hover .preview-link {
  visibility: visible;
  opacity: 1;
  height: auto;
  transition: 0.3s linear opacity;
}
@media (min-width: 1201px) {
  .leads-table.table-wrap .material .datatable-body-row:hover .lead-action {
    visibility: visible;
    opacity: 1;
  }
}
.leads-table.table-wrap .material .datatable-body-row.is-new .datatable-row-group {
  box-shadow: inset 2px 0 0px 0px #0389ff;
  background-color: #e6f4ff;
}
.leads-table.table-wrap .material .datatable-body-row.not-my-lead {
  background: #fff7f8;
  box-shadow: inset 0 -1px 0 0 #f2e6e8;
}
.leads-table.table-wrap .material .datatable-body-row.my-lead {
  background: #f5fff6;
  box-shadow: inset 0 -1px 0 0 #e6f2e7;
}
.leads-table.table-wrap .material .datatable-body-row .datatable-body-cell {
  position: relative;
  padding: 10px;
}
.leads-table.table-wrap .material .datatable-body-row .datatable-body-cell-label p {
  width: 100%;
  text-align: center;
}
.leads-table.table-wrap .material .datatable-body-row .datatable-body-cell.lead-cell-type {
  padding: 10px 0;
}
.leads-table.table-wrap .material .datatable-body-row .datatable-body-cell.incorrect span {
  color: #9cacbe;
}
.leads-table.table-wrap .lead-cell.listed-red {
  color: #ff3d55 !important;
}
.leads-table.table-wrap .lead-cell .listed {
  display: inline-block;
  width: 82px;
  height: 22px;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(to bottom, #ff5737, #f63048);
  border-radius: 5px;
}
.leads-table.table-wrap .lead-cell .listed.my-lead {
  background-image: linear-gradient(to bottom, #7ec939, #40b837);
}
.leads-table.table-wrap .row__counters {
  position: relative;
}
.leads-table.table-wrap .row__counters-icon {
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translateY(-50%);
}
.leads-table.table-wrap .row__counters-icon svg {
  width: 20px;
  height: 20px;
  fill: #9eb4cb;
}
.leads-table.table-wrap .row__menu {
  padding-left: 0 !important;
}
.leads-table.table-wrap .preview-link {
  visibility: hidden;
  opacity: 0;
  height: 0;
}
.leads-table.table-wrap .preview-link:hover {
  text-decoration: none;
}
.leads-table.table-wrap .type {
  display: inline-block;
  margin: 0 auto;
  height: 24px;
  width: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: default;
}
.leads-table.table-wrap .type.selling {
  border-color: rgba(3, 137, 255, 0.4);
  color: #0389ff;
}
.leads-table.table-wrap .type.refinance {
  border-color: rgba(110, 0, 255, 0.4);
  color: #6e00ff;
}
.leads-table.table-wrap .status {
  display: block;
  font-weight: normal;
  white-space: nowrap;
}
@media (min-width: 1201px) {
  .leads-table.table-wrap .lead-action {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s linear;
  }
}
@media (min-width: 1200px) {
  .leads-table.table-wrap.multiselect--active .material .datatable-body-row:hover .home-info_report .lead-address-preview {
    width: 100px;
  }
}

.empty-data-table {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1170px;
  margin: 0 auto;
  margin-top: 70px;
}
.empty-data-table h2 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #8194aa;
}

.loading-collection {
  justify-content: center;
  margin-top: 120px;
  text-align: center;
}

.leads-table.table-wrap.multiselect.show-summary .material .datatable-header-cell.collspan {
  width: 90% !important;
}
.leads-table.table-wrap.multiselect.show-summary .material .datatable-header-cell:not(.shown) {
  display: none;
}

.inline-svg svg {
  width: 24px;
  height: 24px;
  fill: #9cacbe;
}
.inline-svg.red svg {
  fill: #ff3d55;
}
.inline-svg.green svg {
  fill: #0AA765;
}
.inline-svg.landing-preview__logo svg {
  width: 100%;
  height: 100%;
}

.inline-svg--12 svg {
  width: 12px;
  height: 12px;
}

.inline-svg--16 svg {
  width: 16px;
  height: 16px;
}

.inline-svg--20 svg {
  width: 20px;
  height: 20px;
}

.inline-svg--22 svg {
  width: 22px;
  height: 22px;
}

.inline-svg--24 svg {
  width: 24px;
  height: 24px;
}

.inline-svg--40 svg {
  width: 40px;
  height: 40px;
}

.inline-svg--48 svg {
  width: 48px;
  height: 48px;
}

.inline-svg--56 svg {
  width: 56px;
  height: 56px;
}

.inline-svg--64 svg {
  width: 64px;
  height: 64px;
}

.inline-svg--80 svg {
  width: 80px;
  height: 80px;
}

.inline-svg--120 svg {
  width: 120px;
  height: 120px;
}

.inline-svg--256 svg {
  width: 256px;
  height: 256px;
}

.inline-svg--blue-main svg {
  fill: #0389ff;
  stroke: #0389ff;
}

.inline-svg--white svg {
  fill: #fff;
  stroke: #fff;
}

.inline-svg--steel svg {
  fill: #78899c;
  stroke: #78899c;
}

.alert {
  position: relative;
}
.alert-dismissible .close {
  position: absolute;
  top: 50%;
  right: 5px;
  z-index: 999;
  width: 26px;
  height: 26px;
  color: inherit;
  font-size: 0;
  background-color: transparent;
  background-image: url("/assets/common/alerts/alert-close-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  transform: translateY(-13px);
}
@media (min-width: 768px) {
  .alert-dismissible .close {
    right: 25px;
  }
}
.alert-success {
  color: #333940;
  background-color: #a3d96c;
}
.alert-info {
  color: #333940;
  background-color: #cce7ff;
}
.alert-primary {
  color: #333940;
  background-color: #fff;
}
.alert-warning {
  color: #333940;
  background-color: #ffc266;
}
.alert-danger {
  color: #333940;
  background-color: #ff8080;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.activity-list {
  position: relative;
  display: block;
  padding: 15px 15px 0;
}
@media (max-width: 560px) {
  .activity-list {
    padding: 15px 0;
  }
}
.activity-list .last-item .activity-item:after {
  display: none;
}
.activity-item {
  flex-wrap: nowrap;
  position: relative;
  margin-bottom: 24px;
  padding: 8px;
}
@media (max-width: 560px) {
  .activity-item {
    margin-bottom: 0;
    border-bottom: 1px solid #d1d9e2;
  }
  .activity-item.b-c-azure {
    background-color: #e6f4ff;
  }
  .activity-item.b-c-ice-blue {
    background-color: #f4f8f9;
  }
}
@media (min-width: 560px) {
  .activity-item:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 40px;
    top: 64px;
    left: 36px;
    background: #d1d9e2;
  }
}
.activity-item.disabled {
  filter: grayscale(1);
  opacity: 0.7;
}
.activity-item__avatar {
  position: relative;
  padding: 8px;
}
.activity-item__avatar--yellow-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #febe00;
  border-radius: 20px;
}
.activity-item__avatar--iq-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-image: linear-gradient(to left, #0daeff, #0080ff);
  border-radius: 20px;
}
.activity-item__content {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-grow: 0;
  padding-top: 6px;
  padding-left: 2px;
}
@media (min-width: 561px) {
  .activity-item__content.b-c-azure {
    padding-right: 15px;
    padding-left: 12px;
    background-color: #e6f4ff;
    box-shadow: 0 2px 0 0 rgba(3, 137, 255, 0.2);
    border-radius: 8px;
  }
  .activity-item__content.b-c-ice-blue {
    padding-right: 25px;
    padding-left: 12px;
    background-color: #f4f8f9;
    box-shadow: none;
    border-radius: 8px;
  }
}
.activity-item__content a:hover {
  color: unset;
  text-decoration: underline;
}
.activity-item__content a:hover b {
  text-decoration: underline;
}
.activity-item__content-header {
  padding-left: 2px;
  font-size: 16px;
  line-height: 24px;
  color: #333940;
}
.activity-item__content-header b {
  display: inline-block;
}
.activity-item__content-description {
  display: inline-block;
}
@media (min-width: 560px) {
  .activity-item__content-description {
    position: relative;
  }
}
.activity-item__content-description-image {
  display: inline;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
}
.activity-item__content-description-date {
  display: inline-block;
  padding: 0 0 0 6px;
  font-size: 11px;
  line-height: 16px;
  color: #536880;
  opacity: 0.5;
}
.activity-item__content-description-details {
  display: inline;
  flex: auto;
  position: relative;
  padding: 0 0 0 16px;
  font-size: 11px;
  line-height: 14px;
  color: #536880;
}
.activity-item__content-description-details:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 7px;
  width: 2px;
  height: 2px;
  font-size: 11px;
  line-height: 14px;
  background-color: #536880;
  border-radius: 50%;
}
.activity-item__content-description-icon {
  position: absolute;
  top: 9px;
  right: -23px;
  width: 16px;
  height: 16px;
}
.activity-item__content-description-icon svg {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  fill: #9eb4cb;
}
@media (max-width: 560px) {
  .activity-item__content-description-icon {
    top: 55px;
    left: 26px;
    right: unset;
    width: 20px;
    height: 20px;
  }
  .activity-item__content-description-icon svg {
    width: 16px;
    height: 16px;
  }
}
.activity-item.ghost .activity-item__avatar {
  min-width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 20px;
  background-image: linear-gradient(135deg, #e4ebf2, #f4f7fb, #e4ebf2);
  background-size: 200%;
  animation-name: ghost-gradient-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.activity-item.ghost .activity-item__content {
  padding-left: 10px;
}
.activity-item.ghost .activity-item__content-header {
  width: 318px;
  height: 11px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #e4ebf2, #f4f7fb, #e4ebf2);
  background-size: 200%;
  animation-name: ghost-gradient-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@media (max-width: 400px) {
  .activity-item.ghost .activity-item__content-header {
    width: 240px;
  }
}
.activity-item.ghost .activity-item__content-description {
  display: flex;
  align-items: center;
  width: 248px;
}
@media (max-width: 400px) {
  .activity-item.ghost .activity-item__content-description {
    width: 220px;
  }
}
.activity-item.ghost .activity-item__content-description-image {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #e4ebf2, #f4f7fb, #e4ebf2);
  background-size: 200%;
  animation-name: ghost-gradient-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.activity-item.ghost .activity-item__content-description-date {
  width: 55px;
  height: 6px;
  margin: 0 14px 0 10px;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #e4ebf2, #f4f7fb, #e4ebf2);
  background-size: 200%;
  animation-name: ghost-gradient-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.activity-item.ghost .activity-item__content-description-details {
  height: 6px;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #e4ebf2, #f4f7fb, #e4ebf2);
  background-size: 200%;
  animation-name: ghost-gradient-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.activity-item.ghost .activity-item__content-description-details:before {
  display: none;
}

.viral-import__header,
.import-database__header,
.upload-database__header {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 24px;
  text-align: center;
}
.viral-import__header--btn,
.import-database__header--btn,
.upload-database__header--btn {
  position: absolute;
  top: 15px;
  left: 0;
}
.viral-import__title,
.import-database__title,
.upload-database__title {
  padding-top: 30px;
  font-size: 16px;
  line-height: 24px;
  color: #333940;
  font-weight: 700;
}
.viral-import__select-file-btn, .viral-import .btn,
.import-database__select-file-btn,
.import-database .btn,
.upload-database__select-file-btn,
.upload-database .btn {
  font-weight: 500;
}
.viral-import__content,
.import-database__content,
.upload-database__content {
  position: relative;
  max-width: 600px;
  margin: 24px auto;
  padding: 0 15px;
}
.viral-import__content-icon,
.import-database__content-icon,
.upload-database__content-icon {
  margin: 24px 0;
}
.viral-import__content-title,
.import-database__content-title,
.upload-database__content-title {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  color: #333940;
}
.viral-import__content-text,
.import-database__content-text,
.upload-database__content-text {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 21px;
  color: #333940;
}
.viral-import .btn:not(.link),
.import-database .btn:not(.link),
.upload-database .btn:not(.link) {
  display: block;
  min-width: 150px;
  margin-left: auto;
  margin-right: auto;
}
.viral-import .btn:not(.link).import-csv__import-btn,
.import-database .btn:not(.link).import-csv__import-btn,
.upload-database .btn:not(.link).import-csv__import-btn {
  font-size: 17px;
}
.viral-import hr.divider,
.import-database hr.divider,
.upload-database hr.divider {
  margin: 15px 0;
  background-color: #dae1e6;
}
.viral-import__loading,
.import-database__loading,
.upload-database__loading {
  padding-top: 134px;
}
.viral-import__loading .loading-dots,
.import-database__loading .loading-dots,
.upload-database__loading .loading-dots {
  display: block;
}
.viral-import__text,
.import-database__text,
.upload-database__text {
  font-size: 14px;
  line-height: 22px;
  color: #333940;
  font-weight: 500;
}
.viral-import__text a,
.import-database__text a,
.upload-database__text a {
  color: #0389ff !important;
}
.viral-import__select-file-btn input,
.import-database__select-file-btn input,
.upload-database__select-file-btn input {
  position: absolute;
  visibility: hidden;
  z-index: -1;
}
.viral-import__uploaded-icon,
.import-database__uploaded-icon,
.upload-database__uploaded-icon {
  width: 22px;
  height: 22px;
  margin-right: 9px;
  background-color: #fff;
  border-radius: 50%;
}
.viral-import__status-image,
.import-database__status-image,
.upload-database__status-image {
  width: 120px;
}
.viral-import__status-icon,
.import-database__status-icon,
.upload-database__status-icon {
  font-size: 64px;
  margin: 56px auto 0;
}
.viral-import__status-text,
.import-database__status-text,
.upload-database__status-text {
  margin-bottom: 24px;
  font-size: 14px;
}
.viral-import__select-another-file,
.import-database__select-another-file,
.upload-database__select-another-file {
  margin: 25px 0 30px;
  padding: 0;
  font-weight: 400;
  color: #0389ff;
}
.viral-import__progress,
.import-database__progress,
.upload-database__progress {
  position: relative;
  display: block;
  width: 346px;
  margin: 0 auto;
  padding: 5px;
  border-radius: 6px;
  background-color: #fff;
}
.viral-import__progress-bar,
.import-database__progress-bar,
.upload-database__progress-bar {
  height: 46px;
  border-radius: 6px;
  background-color: #0389ff;
  transition: width 0.5s ease;
}
.viral-import__progress-value,
.import-database__progress-value,
.upload-database__progress-value {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 16px;
  line-height: 24px;
  transform: translate(-50%, -50%);
}
.viral-import__leads,
.import-database__leads,
.upload-database__leads {
  max-width: 500px;
  margin: 0 auto 10px;
  text-align: left;
}

.import-database__header {
  border-bottom: 1px solid #ebeff2;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.tab-nav, .tab-nav__menu {
  display: flex;
  max-width: 1170px;
  margin: 0 auto;
  overflow: auto hidden;
}
@media (max-width: 1200px) {
  .tab-nav, .tab-nav__menu {
    margin: 0 15px;
  }
}
.tab-nav__total {
  margin-left: 6px;
  font-size: 20px;
  color: #94a5b8;
}
.tab-nav__new {
  min-width: 23px;
  margin: 1px 0 auto 6px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 1.14;
  color: #fff;
  background-color: #0389ff;
  border-radius: 12px;
}
.tab-nav__item {
  align-self: center;
  margin-right: 40px;
}
@media (max-width: 550px) {
  .tab-nav__item {
    margin-right: 18px;
  }
}
.tab-nav__item--align-right {
  align-self: center;
  margin-left: auto;
}
.tab-nav__item--button-wrapper {
  display: flex;
  margin-right: 0;
}
.tab-nav__item--button-wrapper iq-button {
  padding-right: 4px;
}
.tab-nav__item--single .tab-nav__link:after {
  display: none;
}
.tab-nav__item--search {
  margin-right: 0;
}
.tab-nav__link {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 24px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
@media (max-width: 767px) {
  .tab-nav__link {
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 1.56;
    align-items: center;
  }
}
.tab-nav__link:hover {
  text-decoration: none;
  color: #333940;
}
.tab-nav__link:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  display: block;
  width: 0;
  height: 1px;
  background-color: #0389ff;
  overflow: hidden;
  transition: width 0.2s ease-in;
}
.tab-nav__link.active:after {
  width: 100%;
  transition: width 0.2s ease-out;
}
.tab-nav__link.active .tab-nav__label {
  color: #333940;
}
.tab-nav__link.active .tab-nav__total {
  color: #78899c;
}
.tab-nav__label {
  color: #78899c;
}
.tab-nav__btn-back {
  position: absolute;
  top: 22px;
  left: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #f7f7f7;
}
.tab-nav__btn-close {
  cursor: pointer;
}

.tab-nav__menu {
  border: none;
}
.tab-nav__menu .control-btn, .tab-nav__menu .control-btn--square {
  background-color: transparent;
}

.tooltip {
  position: absolute;
  display: block;
  z-index: 1070;
  margin-bottom: 3px;
}
.tooltip-inner {
  padding: 4px 8px 6px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  background-color: rgba(0, 30, 64, 0.8);
  color: #dadfe6;
}
.tooltip--line-height-normal .tooltip-inner {
  line-height: normal;
}
.tooltip .tooltip-inner {
  max-width: 480px !important;
  white-space: normal;
}

.tooltip > div {
  animation-name: delayedFadeIn;
  animation-duration: 0.4s;
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.info-tooltip {
  max-width: 200px;
}

.iq-line-chart-tooltip {
  padding: 2px 4px;
  background: #3b82f6;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
  z-index: 1000;
  position: fixed;
}

.toast-container {
  display: flex;
  flex-direction: column;
}

.notify {
  display: block !important;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
  overflow: hidden;
  padding: 10px 30px;
  border-radius: 5px;
  box-shadow: 0 0 12px #999999;
  background-image: none;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #dae1e6;
}
.notify.toast-success {
  background-color: rgba(0, 30, 64, 0.8);
}
.notify.toast-warning {
  background-color: rgba(255, 16, 16, 0.8);
}

:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
/*
.filter
  .filter-container
    .filter-main-controls
      .filter-label Filter by:
      <-- filters -->
    .filter-additional-controls
      <-- controls -->
*/
/*
.filter.filter--no-label
  .filter-container
    .filter-main-controls
      .filter-label Filter by:
      <-- filters -->
    .filter-additional-controls
      <-- controls -->
*/
.filter {
  max-width: 1170px;
  margin: 16px auto 16px;
}
@media (max-width: 1270px) {
  .filter {
    max-width: unset;
    width: 100%;
    padding: 0 16px;
  }
}
.filter--hidden {
  visibility: hidden;
}
.filter-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 60px;
}
.filter-main-controls {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
}
.filter-label {
  position: absolute;
  left: 0;
  line-height: 28px;
  font-size: 14px;
  font-weight: 500;
  color: #536880;
}
.filter-item {
  margin: 4px 0 4px 10px;
}
.filter-item .dp-filter-btn {
  margin-right: 0;
}
.filter-item-btn {
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  color: #536880;
}
.filter-modal-close {
  display: none;
}
@media (min-width: 768px) {
  .filter--no-label .filter-container {
    padding: 0;
  }
  .filter--no-label .filter-label {
    display: none;
  }
}

.currency-filter {
  position: relative;
}
.currency-filter .btn--dropdown {
  background-color: #ebeff2;
  transition: background-color 0.3s;
  padding: 0 36px 0 12px;
}
.currency-filter .btn--dropdown:hover {
  background-color: #dae1e6;
}
.currency-filter__menu {
  max-height: 384px;
  border-color: transparent;
  box-shadow: 0 5px 15px 0 rgba(2, 36, 71, 0.2), 0 3px 10px 0 rgba(2, 36, 71, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
  width: 320px;
  min-width: 320px;
  padding-right: 12px;
  padding-left: 12px;
}
.currency-filter__menu .input-group {
  margin-bottom: 5px;
}

.filter-dropdown {
  display: block;
  position: relative;
}
.filter-dropdown.dropdown-checkbox {
  width: 100%;
}
.filter-dropdown.dropdown-checkbox .btn--dropdown {
  width: 100%;
  justify-content: flex-start;
}
.filter-dropdown.dropdown-checkbox .btn--dropdown:hover {
  background-color: unset;
}
.filter-dropdown__toggle {
  display: block;
  max-width: 100%;
  background-color: #ebeff2;
}
@media (min-width: 768px) {
  .filter-dropdown__toggle--label.selected {
    display: none;
  }
}
@media (max-width: 767px) {
  .filter-dropdown__toggle--label {
    color: #333940;
  }
}
.filter-dropdown__menu {
  position: absolute;
  min-width: 4rem;
  z-index: 2;
  padding: 0;
  margin-top: 2px;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(2, 36, 71, 0.2), 0 3px 10px 0 rgba(2, 36, 71, 0.1);
  background-color: #fff;
  border: none;
  overflow: hidden;
}
.filter-dropdown__menu--title {
  display: none;
}
.filter-dropdown__content {
  position: relative;
  max-height: 200px;
  padding: 5px 0;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .filter-dropdown__content {
    width: 100%;
    max-width: 500px;
    margin: auto;
    background: #ffffff;
    border-radius: 5px;
  }
}
.filter-dropdown__content .checkbox {
  display: block;
  width: 100%;
  padding: 0 14px;
}
.filter-dropdown__content .checkbox__label {
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 0;
  white-space: nowrap;
}
.filter-dropdown-spinner {
  padding: 15px 0;
}
.filter-dropdown__item {
  display: block;
  float: left;
  padding: 0 15px;
  width: 100%;
  font-size: 14px;
  text-align: left;
  line-height: 2.29;
  white-space: nowrap;
}
.filter-dropdown__item.active {
  color: #333940;
  background-color: #dce3e8;
}
.filter-dropdown__item:hover {
  background-color: #ebeff2;
}
@media (max-width: 767px) {
  .filter-dropdown__item {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}

@media (max-width: 1200px) {
  .filter-container {
    margin: 0 15px;
  }
}
.empty-message {
  padding: 10px 0;
  border-bottom: 1px solid #ebeff2;
  text-align: center;
  font-size: 18px;
  line-height: 1.89;
  color: #8194aa;
}

.subscription-label {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 3px;
  background-color: #ebeff2;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.8px;
  color: #536880;
}
.subscription-label::first-letter {
  text-transform: uppercase;
}
.subscription-label.pending {
  background-color: #ffeeb3;
  color: #cf7c1d;
}
.subscription-label.paid {
  background: rgba(10, 167, 101, 0.16);
  color: #0AA765;
}
.subscription-label.unpaid {
  background-color: rgba(255, 61, 85, 0.2);
  color: #ff3d55;
}
.subscription-label.canceled {
  background-color: #536880;
  color: #fff;
}
.subscription-label.paused {
  background-image: linear-gradient(to left, #ffcb0c, #ffa217);
  color: #fff;
}

:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.ftl-viral__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 0 40px 40px;
}
@media (max-width: 768px) {
  .ftl-viral__container {
    padding: 0 16px 16px;
  }
}
.ftl-viral__content {
  padding: 88px 56px 0;
}
@media (max-width: 1024px) {
  .ftl-viral__content {
    padding-right: 24px;
    padding-left: 24px;
  }
}
@media (max-width: 768px) {
  .ftl-viral__content {
    padding: 32px 0;
  }
}
.ftl-viral__content .button .button-label {
  display: block !important;
}
.ftl-viral__title {
  margin-bottom: 40px;
  font-size: 32px;
  line-height: 44px;
  font-weight: bold;
  color: #333940;
}
@media (max-width: 768px) {
  .ftl-viral__title {
    margin-bottom: 32px;
    line-height: 36px;
    font-size: 24px;
  }
}
.ftl-viral__subtitle {
  font-size: 20px;
  line-height: 30px;
  color: #333940;
}
@media (max-width: 768px) {
  .ftl-viral__subtitle {
    line-height: 26px;
    font-size: 16px;
  }
}
.ftl-viral__subtitle b {
  font-weight: 700;
}
.ftl-viral__tour-steps {
  display: flex;
  width: 100%;
}
@media (max-width: 580px) {
  .ftl-viral__tour-steps {
    flex-direction: column;
  }
}
.ftl-viral__counter {
  margin-right: 16px;
  font-size: 64px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .ftl-viral__counter {
    font-size: 48px;
  }
}
.ftl-viral__skip-btn.btn {
  margin-top: auto;
}
@media (max-width: 768px) {
  .ftl-viral__skip-btn.btn {
    margin-top: 24px;
  }
}
.ftl-viral__skip-btn.btn.gray-btn {
  color: #536880;
}
.ftl-viral__skip-btn.btn.gray-btn .inline-svg svg {
  fill: #536880;
}
.ftl-viral__collection {
  max-width: 480px;
}
.ftl-viral__collection .count {
  line-height: 60px;
  background: linear-gradient(to left, #0daeff, #0080ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ftl-viral__collection .count.negative {
  background: linear-gradient(to left, #ff5737, #f63048);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ftl-viral__collection .items .item.shadow {
  position: relative;
}
.ftl-viral__collection .items .item.shadow:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}
.ftl-viral__collection .items .item .preview {
  width: 88px;
  height: 56px;
  border-radius: 4px;
  background-color: #ebeff2;
  overflow: hidden;
  background-image: url("/assets/components/custom-table/house.svg");
  background-size: 100% 80%;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.ftl-viral__collection .items .item .preview img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.ftl-viral__collection .items .item .info {
  position: relative;
  flex-direction: column;
  justify-content: center;
}
.ftl-viral__collection .items .item .name {
  line-height: 1.57;
}
.ftl-viral__collection .items .item .address {
  line-height: 1.4;
}
.ftl-viral__collection .items .item .date {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .ftl-viral__collection .items .item .date {
    position: static;
    transform: none;
    line-height: 18px;
    font-size: 12px;
  }
}

:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

.settings-flex-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 80px;
}
@media (max-width: 1350px) {
  .settings-flex-wrapper {
    flex-direction: column;
    gap: 64px;
    align-items: center;
  }
}

.settings-content-wrapper {
  width: 100%;
}
@media (max-width: 1350px) {
  .settings-content-wrapper {
    max-width: calc(100vw - 32px);
  }
}

.inner-container {
  max-width: 740px;
}
@media (max-width: 1350px) {
  .inner-container {
    max-width: calc(100vw - 270px);
  }
}
@media (max-width: 1100px) {
  .inner-container {
    max-width: calc(100vw - 60px);
  }
}

.settings-support {
  min-width: 270px;
}
@media (max-width: 1024px) {
  .settings-support {
    min-width: initial;
  }
}

:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.iq-dashboard__wrapper {
  padding: 24px 32px 32px;
  border-radius: 12px;
  background-color: #fff;
}
@media (max-width: 576px) {
  .iq-dashboard__wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.iq-dashboard__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.iq-dashboard__header h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 576px) {
  .iq-dashboard__header {
    gap: 16px;
  }
}
.iq-dashboard__divider, .iq-dashboard__divider--horizontal, .iq-dashboard__divider--vertical {
  background-color: #ebeff2;
}
.iq-dashboard__divider--vertical {
  min-width: 1px;
  min-height: 100%;
}
.iq-dashboard__divider--horizontal {
  min-width: 100%;
  min-height: 1px;
}

.iq-dashboard__wrapper .value {
  padding-left: 16px;
}
.iq-dashboard__wrapper .value__title, .iq-dashboard__wrapper .value__title--dark-blue, .iq-dashboard__wrapper .value__title--blue {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #536880;
}
.iq-dashboard__wrapper .value__title::after, .iq-dashboard__wrapper .value__title--dark-blue::after, .iq-dashboard__wrapper .value__title--blue::after {
  content: "";
  position: absolute;
  display: block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
}
.iq-dashboard__wrapper .value__title--blue::after {
  background-color: #0DAAFF;
}
.iq-dashboard__wrapper .value__title--dark-blue::after {
  background-color: #0C509E;
}
.iq-dashboard__wrapper .value__details {
  font-size: 12px;
  line-height: 18px;
}
.iq-dashboard__wrapper .value__count {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.iq-dashboard__wrapper .value__average {
  display: inline-block;
  padding: 4px 8px;
  color: #536880;
  font-size: 11px;
  line-height: 13px;
  background: rgba(83, 104, 128, 0.08);
  border-radius: 4px;
}

.dashboard-listings__header h2 {
  text-transform: capitalize;
}
.dashboard-listings__row {
  display: flex;
  gap: 24px;
}
.dashboard-listings__column {
  flex: 1;
}
.dashboard-listings__title, .dashboard-listings__title--grey, .dashboard-listings__title--green, .dashboard-listings__title--dark-blue, .dashboard-listings__title--blue {
  position: relative;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #536880;
}
.dashboard-listings__title::after, .dashboard-listings__title--grey::after, .dashboard-listings__title--green::after, .dashboard-listings__title--dark-blue::after, .dashboard-listings__title--blue::after {
  content: "";
  position: absolute;
  display: block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
}
.dashboard-listings__title--blue::after {
  background-color: #0DAAFF;
}
.dashboard-listings__title--dark-blue::after {
  background-color: #0C509E;
}
.dashboard-listings__title--green::after {
  background-color: #29A72B;
}
.dashboard-listings__title--grey::after {
  background-color: #dae1e6;
}
.dashboard-listings__text {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.4px;
}
.dashboard-listings__text--bold {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}
.dashboard-listings__text--semi-bold {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: -0.4px;
}
.dashboard-listings .red {
  color: #ff3d55;
}

:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

/* Variables related to mobile adaptive */
:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.intelligence-user-page {
  position: relative;
  display: flex;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1200px) {
  .intelligence-user-page {
    flex-direction: column;
  }
}
.intelligence-user-page__sidebar {
  width: 360px;
  min-height: calc(100vh - var(--header-nav-height));
  padding: 16px 24px;
  background-color: #fff;
}
@media (max-width: 1200px) {
  .intelligence-user-page__sidebar {
    width: auto;
    min-height: auto;
  }
}
.intelligence-user-page__sidebar .user {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px auto 24px 0;
}
.intelligence-user-page__sidebar .user-avatar {
  position: relative;
}
.intelligence-user-page__sidebar .user-internal-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.intelligence-user-page__sidebar .user-info {
  width: calc(100% - 96px);
}
.intelligence-user-page__sidebar .user-info__name {
  position: relative;
  width: fit-content;
}
.intelligence-user-page__content {
  position: relative;
  flex-basis: calc(100% - 360px);
  max-width: calc(100% - 360px);
  padding: 0 32px;
}
@media (max-width: 1200px) {
  .intelligence-user-page__content {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .intelligence-user-page__content {
    padding: 0 16px;
  }
}
.intelligence-user-page__content .tab-nav, .intelligence-user-page__content .tab-nav__menu {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.intelligence-user-page__content .tab-nav__link.active .tab-nav__label {
  color: #333940;
}
.intelligence-user-page__content .tab-nav__label {
  color: #78899c;
}

.intelligence-details-card {
  background-color: #fff;
  border-radius: 12px;
}
.intelligence-details-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
}
@media (max-width: 576px) {
  .intelligence-details-card__header {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.intelligence-details-card__content {
  padding: 0 32px 32px;
}
@media (max-width: 576px) {
  .intelligence-details-card__content {
    padding-left: 16px;
    padding-right: 16px;
  }
}

a {
  color: #0389ff;
  text-decoration: none;
  transition: color 0.2s ease;
}
a:hover, a:active {
  color: #26a5ff;
  text-decoration: underline;
  cursor: pointer;
}
a.link {
  font-size: 14px;
  color: #0389ff;
}
a.link-dark {
  color: #000;
}
a.btn:hover {
  text-decoration: none;
}
a.no-underline {
  text-decoration: none;
}
a.no-underline:hover {
  text-decoration: none;
}
a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.pseudo-link {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #0389ff;
  transition: color 0.2s ease;
}
.pseudo-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  background-color: #0389ff;
  transition: opacity 0.2s ease;
  opacity: 0;
}
.pseudo-link:hover, .pseudo-link:active {
  color: #26a5ff;
  cursor: pointer;
}
.pseudo-link:hover:before, .pseudo-link:active:before {
  opacity: 1;
}

.pseudo-btn {
  display: inline-block;
  color: #5f7389;
  cursor: pointer;
}
.pseudo-btn:hover {
  color: #0389ff;
}

.w-1x {
  width: 8px !important;
}

.w-2x {
  width: 16px !important;
}

.w-3x {
  width: 24px !important;
}

.w-4x {
  width: 32px !important;
}

.w-5x {
  width: 40px !important;
}

.w-6x {
  width: 48px !important;
}

.w-7x {
  width: 56px !important;
}

.w-8x {
  width: 64px !important;
}

.w-9x {
  width: 72px !important;
}

.w-10x {
  width: 80px !important;
}

.w-11x {
  width: 88px !important;
}

.w-12x {
  width: 96px !important;
}

.h-1x {
  height: 8px !important;
}

.h-2x {
  height: 16px !important;
}

.h-3x {
  height: 24px !important;
}

.h-4x {
  height: 32px !important;
}

.h-5x {
  height: 40px !important;
}

.h-6x {
  height: 48px !important;
}

.h-7x {
  height: 56px !important;
}

.h-8x {
  height: 64px !important;
}

.h-9x {
  height: 72px !important;
}

.h-10x {
  height: 80px !important;
}

.h-11x {
  height: 88px !important;
}

.h-12x {
  height: 96px !important;
}

.w-56 {
  width: 56px;
}

.h-56 {
  height: 56px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

/*
.m-r-10 which gives margin-right 10 pixels.
.m-r-15 gives MARGIN to the RIGHT 15 pixels.
.m-t-15 gives MARGIN to the TOP 15 pixels and so on.
.p-b-5 gives PADDING to the BOTTOM of 5 pixels
.p-l-40 gives PADDING to the LEFT of 40 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.

Multiples:
.m-t-4x,
.p-b-3x,
.m-12x,
.p-8x

With media:
.m-b-4x-min640
.p-t-2x-min768
.m-l-1x-max1024
.m-p-1x-max1280
*/
.m-t-0 {
  margin-top: 0px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.m-t-4 {
  margin-top: 4px !important;
}

.p-t-4 {
  padding-top: 4px !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.p-b-4 {
  padding-bottom: 4px !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.p-l-4 {
  padding-left: 4px !important;
}

.m-r-4 {
  margin-right: 4px !important;
}

.p-r-4 {
  padding-right: 4px !important;
}

.m-4 {
  margin: 4px !important;
}

.p-4 {
  padding: 4px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.m-5 {
  margin: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.m-t-6 {
  margin-top: 6px !important;
}

.p-t-6 {
  padding-top: 6px !important;
}

.m-b-6 {
  margin-bottom: 6px !important;
}

.p-b-6 {
  padding-bottom: 6px !important;
}

.m-l-6 {
  margin-left: 6px !important;
}

.p-l-6 {
  padding-left: 6px !important;
}

.m-r-6 {
  margin-right: 6px !important;
}

.p-r-6 {
  padding-right: 6px !important;
}

.m-6 {
  margin: 6px !important;
}

.p-6 {
  padding: 6px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.p-b-8 {
  padding-bottom: 8px !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.p-l-8 {
  padding-left: 8px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.p-r-8 {
  padding-right: 8px !important;
}

.m-8 {
  margin: 8px !important;
}

.p-8 {
  padding: 8px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.m-10 {
  margin: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.p-t-12 {
  padding-top: 12px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.p-b-12 {
  padding-bottom: 12px !important;
}

.m-l-12 {
  margin-left: 12px !important;
}

.p-l-12 {
  padding-left: 12px !important;
}

.m-r-12 {
  margin-right: 12px !important;
}

.p-r-12 {
  padding-right: 12px !important;
}

.m-12 {
  margin: 12px !important;
}

.p-12 {
  padding: 12px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.m-15 {
  margin: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.p-t-16 {
  padding-top: 16px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.p-b-16 {
  padding-bottom: 16px !important;
}

.m-l-16 {
  margin-left: 16px !important;
}

.p-l-16 {
  padding-left: 16px !important;
}

.m-r-16 {
  margin-right: 16px !important;
}

.p-r-16 {
  padding-right: 16px !important;
}

.m-16 {
  margin: 16px !important;
}

.p-16 {
  padding: 16px !important;
}

.m-t-17 {
  margin-top: 17px !important;
}

.p-t-17 {
  padding-top: 17px !important;
}

.m-b-17 {
  margin-bottom: 17px !important;
}

.p-b-17 {
  padding-bottom: 17px !important;
}

.m-l-17 {
  margin-left: 17px !important;
}

.p-l-17 {
  padding-left: 17px !important;
}

.m-r-17 {
  margin-right: 17px !important;
}

.p-r-17 {
  padding-right: 17px !important;
}

.m-17 {
  margin: 17px !important;
}

.p-17 {
  padding: 17px !important;
}

.m-t-18 {
  margin-top: 18px !important;
}

.p-t-18 {
  padding-top: 18px !important;
}

.m-b-18 {
  margin-bottom: 18px !important;
}

.p-b-18 {
  padding-bottom: 18px !important;
}

.m-l-18 {
  margin-left: 18px !important;
}

.p-l-18 {
  padding-left: 18px !important;
}

.m-r-18 {
  margin-right: 18px !important;
}

.p-r-18 {
  padding-right: 18px !important;
}

.m-18 {
  margin: 18px !important;
}

.p-18 {
  padding: 18px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.m-20 {
  margin: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.m-t-22 {
  margin-top: 22px !important;
}

.p-t-22 {
  padding-top: 22px !important;
}

.m-b-22 {
  margin-bottom: 22px !important;
}

.p-b-22 {
  padding-bottom: 22px !important;
}

.m-l-22 {
  margin-left: 22px !important;
}

.p-l-22 {
  padding-left: 22px !important;
}

.m-r-22 {
  margin-right: 22px !important;
}

.p-r-22 {
  padding-right: 22px !important;
}

.m-22 {
  margin: 22px !important;
}

.p-22 {
  padding: 22px !important;
}

.m-t-24 {
  margin-top: 24px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.m-b-24 {
  margin-bottom: 24px !important;
}

.p-b-24 {
  padding-bottom: 24px !important;
}

.m-l-24 {
  margin-left: 24px !important;
}

.p-l-24 {
  padding-left: 24px !important;
}

.m-r-24 {
  margin-right: 24px !important;
}

.p-r-24 {
  padding-right: 24px !important;
}

.m-24 {
  margin: 24px !important;
}

.p-24 {
  padding: 24px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.m-25 {
  margin: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.m-30 {
  margin: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.m-t-32 {
  margin-top: 32px !important;
}

.p-t-32 {
  padding-top: 32px !important;
}

.m-b-32 {
  margin-bottom: 32px !important;
}

.p-b-32 {
  padding-bottom: 32px !important;
}

.m-l-32 {
  margin-left: 32px !important;
}

.p-l-32 {
  padding-left: 32px !important;
}

.m-r-32 {
  margin-right: 32px !important;
}

.p-r-32 {
  padding-right: 32px !important;
}

.m-32 {
  margin: 32px !important;
}

.p-32 {
  padding: 32px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.m-35 {
  margin: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.m-t-36 {
  margin-top: 36px !important;
}

.p-t-36 {
  padding-top: 36px !important;
}

.m-b-36 {
  margin-bottom: 36px !important;
}

.p-b-36 {
  padding-bottom: 36px !important;
}

.m-l-36 {
  margin-left: 36px !important;
}

.p-l-36 {
  padding-left: 36px !important;
}

.m-r-36 {
  margin-right: 36px !important;
}

.p-r-36 {
  padding-right: 36px !important;
}

.m-36 {
  margin: 36px !important;
}

.p-36 {
  padding: 36px !important;
}

.m-t-38 {
  margin-top: 38px !important;
}

.p-t-38 {
  padding-top: 38px !important;
}

.m-b-38 {
  margin-bottom: 38px !important;
}

.p-b-38 {
  padding-bottom: 38px !important;
}

.m-l-38 {
  margin-left: 38px !important;
}

.p-l-38 {
  padding-left: 38px !important;
}

.m-r-38 {
  margin-right: 38px !important;
}

.p-r-38 {
  padding-right: 38px !important;
}

.m-38 {
  margin: 38px !important;
}

.p-38 {
  padding: 38px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.m-40 {
  margin: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.m-45 {
  margin: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.m-t-48 {
  margin-top: 48px !important;
}

.p-t-48 {
  padding-top: 48px !important;
}

.m-b-48 {
  margin-bottom: 48px !important;
}

.p-b-48 {
  padding-bottom: 48px !important;
}

.m-l-48 {
  margin-left: 48px !important;
}

.p-l-48 {
  padding-left: 48px !important;
}

.m-r-48 {
  margin-right: 48px !important;
}

.p-r-48 {
  padding-right: 48px !important;
}

.m-48 {
  margin: 48px !important;
}

.p-48 {
  padding: 48px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.m-50 {
  margin: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.m-t-55 {
  margin-top: 55px !important;
}

.p-t-55 {
  padding-top: 55px !important;
}

.m-b-55 {
  margin-bottom: 55px !important;
}

.p-b-55 {
  padding-bottom: 55px !important;
}

.m-l-55 {
  margin-left: 55px !important;
}

.p-l-55 {
  padding-left: 55px !important;
}

.m-r-55 {
  margin-right: 55px !important;
}

.p-r-55 {
  padding-right: 55px !important;
}

.m-55 {
  margin: 55px !important;
}

.p-55 {
  padding: 55px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.m-60 {
  margin: 60px !important;
}

.p-60 {
  padding: 60px !important;
}

.m-t-64 {
  margin-top: 64px !important;
}

.p-t-64 {
  padding-top: 64px !important;
}

.m-b-64 {
  margin-bottom: 64px !important;
}

.p-b-64 {
  padding-bottom: 64px !important;
}

.m-l-64 {
  margin-left: 64px !important;
}

.p-l-64 {
  padding-left: 64px !important;
}

.m-r-64 {
  margin-right: 64px !important;
}

.p-r-64 {
  padding-right: 64px !important;
}

.m-64 {
  margin: 64px !important;
}

.p-64 {
  padding: 64px !important;
}

.m-t-65 {
  margin-top: 65px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.m-b-65 {
  margin-bottom: 65px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.m-r-65 {
  margin-right: 65px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.m-65 {
  margin: 65px !important;
}

.p-65 {
  padding: 65px !important;
}

.m-t-70 {
  margin-top: 70px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.m-l-70 {
  margin-left: 70px !important;
}

.p-l-70 {
  padding-left: 70px !important;
}

.m-r-70 {
  margin-right: 70px !important;
}

.p-r-70 {
  padding-right: 70px !important;
}

.m-70 {
  margin: 70px !important;
}

.p-70 {
  padding: 70px !important;
}

.m-t-75 {
  margin-top: 75px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.m-b-75 {
  margin-bottom: 75px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.m-l-75 {
  margin-left: 75px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.m-r-75 {
  margin-right: 75px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

.m-75 {
  margin: 75px !important;
}

.p-75 {
  padding: 75px !important;
}

.m-t-85 {
  margin-top: 85px !important;
}

.p-t-85 {
  padding-top: 85px !important;
}

.m-b-85 {
  margin-bottom: 85px !important;
}

.p-b-85 {
  padding-bottom: 85px !important;
}

.m-l-85 {
  margin-left: 85px !important;
}

.p-l-85 {
  padding-left: 85px !important;
}

.m-r-85 {
  margin-right: 85px !important;
}

.p-r-85 {
  padding-right: 85px !important;
}

.m-85 {
  margin: 85px !important;
}

.p-85 {
  padding: 85px !important;
}

.m-t-90 {
  margin-top: 90px !important;
}

.p-t-90 {
  padding-top: 90px !important;
}

.m-b-90 {
  margin-bottom: 90px !important;
}

.p-b-90 {
  padding-bottom: 90px !important;
}

.m-l-90 {
  margin-left: 90px !important;
}

.p-l-90 {
  padding-left: 90px !important;
}

.m-r-90 {
  margin-right: 90px !important;
}

.p-r-90 {
  padding-right: 90px !important;
}

.m-90 {
  margin: 90px !important;
}

.p-90 {
  padding: 90px !important;
}

.m-t-96 {
  margin-top: 96px !important;
}

.p-t-96 {
  padding-top: 96px !important;
}

.m-b-96 {
  margin-bottom: 96px !important;
}

.p-b-96 {
  padding-bottom: 96px !important;
}

.m-l-96 {
  margin-left: 96px !important;
}

.p-l-96 {
  padding-left: 96px !important;
}

.m-r-96 {
  margin-right: 96px !important;
}

.p-r-96 {
  padding-right: 96px !important;
}

.m-96 {
  margin: 96px !important;
}

.p-96 {
  padding: 96px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.m-r-100 {
  margin-right: 100px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.m-100 {
  margin: 100px !important;
}

.p-100 {
  padding: 100px !important;
}

.m-t-105 {
  margin-top: 105px !important;
}

.p-t-105 {
  padding-top: 105px !important;
}

.m-b-105 {
  margin-bottom: 105px !important;
}

.p-b-105 {
  padding-bottom: 105px !important;
}

.m-l-105 {
  margin-left: 105px !important;
}

.p-l-105 {
  padding-left: 105px !important;
}

.m-r-105 {
  margin-right: 105px !important;
}

.p-r-105 {
  padding-right: 105px !important;
}

.m-105 {
  margin: 105px !important;
}

.p-105 {
  padding: 105px !important;
}

.m-t-115 {
  margin-top: 115px !important;
}

.p-t-115 {
  padding-top: 115px !important;
}

.m-b-115 {
  margin-bottom: 115px !important;
}

.p-b-115 {
  padding-bottom: 115px !important;
}

.m-l-115 {
  margin-left: 115px !important;
}

.p-l-115 {
  padding-left: 115px !important;
}

.m-r-115 {
  margin-right: 115px !important;
}

.p-r-115 {
  padding-right: 115px !important;
}

.m-115 {
  margin: 115px !important;
}

.p-115 {
  padding: 115px !important;
}

.m-t-154 {
  margin-top: 154px !important;
}

.p-t-154 {
  padding-top: 154px !important;
}

.m-b-154 {
  margin-bottom: 154px !important;
}

.p-b-154 {
  padding-bottom: 154px !important;
}

.m-l-154 {
  margin-left: 154px !important;
}

.p-l-154 {
  padding-left: 154px !important;
}

.m-r-154 {
  margin-right: 154px !important;
}

.p-r-154 {
  padding-right: 154px !important;
}

.m-154 {
  margin: 154px !important;
}

.p-154 {
  padding: 154px !important;
}

.m-t-220 {
  margin-top: 220px !important;
}

.p-t-220 {
  padding-top: 220px !important;
}

.m-b-220 {
  margin-bottom: 220px !important;
}

.p-b-220 {
  padding-bottom: 220px !important;
}

.m-l-220 {
  margin-left: 220px !important;
}

.p-l-220 {
  padding-left: 220px !important;
}

.m-r-220 {
  margin-right: 220px !important;
}

.p-r-220 {
  padding-right: 220px !important;
}

.m-220 {
  margin: 220px !important;
}

.p-220 {
  padding: 220px !important;
}

.m-t-auto {
  margin-top: auto !important;
}

.m-b-auto {
  margin-bottom: auto !important;
}

.m-l-auto {
  margin-left: auto !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.m-auto {
  margin: auto;
}

.m-l-auto {
  margin-left: auto;
}

.m-r-auto {
  margin-right: auto;
}

.m-t-1x {
  margin-top: 8px !important;
}

.p-t-1x {
  padding-top: 8px !important;
}

.m-b-1x {
  margin-bottom: 8px !important;
}

.p-b-1x {
  padding-bottom: 8px !important;
}

.m-l-1x {
  margin-left: 8px !important;
}

.p-l-1x {
  padding-left: 8px !important;
}

.m-r-1x {
  margin-right: 8px !important;
}

.p-r-1x {
  padding-right: 8px !important;
}

.m-1x {
  margin: 8px !important;
}

.p-1x {
  padding: 8px !important;
}

.m-t-2x {
  margin-top: 16px !important;
}

.p-t-2x {
  padding-top: 16px !important;
}

.m-b-2x {
  margin-bottom: 16px !important;
}

.p-b-2x {
  padding-bottom: 16px !important;
}

.m-l-2x {
  margin-left: 16px !important;
}

.p-l-2x {
  padding-left: 16px !important;
}

.m-r-2x {
  margin-right: 16px !important;
}

.p-r-2x {
  padding-right: 16px !important;
}

.m-2x {
  margin: 16px !important;
}

.p-2x {
  padding: 16px !important;
}

.m-t-3x {
  margin-top: 24px !important;
}

.p-t-3x {
  padding-top: 24px !important;
}

.m-b-3x {
  margin-bottom: 24px !important;
}

.p-b-3x {
  padding-bottom: 24px !important;
}

.m-l-3x {
  margin-left: 24px !important;
}

.p-l-3x {
  padding-left: 24px !important;
}

.m-r-3x {
  margin-right: 24px !important;
}

.p-r-3x {
  padding-right: 24px !important;
}

.m-3x {
  margin: 24px !important;
}

.p-3x {
  padding: 24px !important;
}

.m-t-4x {
  margin-top: 32px !important;
}

.p-t-4x {
  padding-top: 32px !important;
}

.m-b-4x {
  margin-bottom: 32px !important;
}

.p-b-4x {
  padding-bottom: 32px !important;
}

.m-l-4x {
  margin-left: 32px !important;
}

.p-l-4x {
  padding-left: 32px !important;
}

.m-r-4x {
  margin-right: 32px !important;
}

.p-r-4x {
  padding-right: 32px !important;
}

.m-4x {
  margin: 32px !important;
}

.p-4x {
  padding: 32px !important;
}

.m-t-5x {
  margin-top: 40px !important;
}

.p-t-5x {
  padding-top: 40px !important;
}

.m-b-5x {
  margin-bottom: 40px !important;
}

.p-b-5x {
  padding-bottom: 40px !important;
}

.m-l-5x {
  margin-left: 40px !important;
}

.p-l-5x {
  padding-left: 40px !important;
}

.m-r-5x {
  margin-right: 40px !important;
}

.p-r-5x {
  padding-right: 40px !important;
}

.m-5x {
  margin: 40px !important;
}

.p-5x {
  padding: 40px !important;
}

.m-t-6x {
  margin-top: 48px !important;
}

.p-t-6x {
  padding-top: 48px !important;
}

.m-b-6x {
  margin-bottom: 48px !important;
}

.p-b-6x {
  padding-bottom: 48px !important;
}

.m-l-6x {
  margin-left: 48px !important;
}

.p-l-6x {
  padding-left: 48px !important;
}

.m-r-6x {
  margin-right: 48px !important;
}

.p-r-6x {
  padding-right: 48px !important;
}

.m-6x {
  margin: 48px !important;
}

.p-6x {
  padding: 48px !important;
}

.m-t-7x {
  margin-top: 56px !important;
}

.p-t-7x {
  padding-top: 56px !important;
}

.m-b-7x {
  margin-bottom: 56px !important;
}

.p-b-7x {
  padding-bottom: 56px !important;
}

.m-l-7x {
  margin-left: 56px !important;
}

.p-l-7x {
  padding-left: 56px !important;
}

.m-r-7x {
  margin-right: 56px !important;
}

.p-r-7x {
  padding-right: 56px !important;
}

.m-7x {
  margin: 56px !important;
}

.p-7x {
  padding: 56px !important;
}

.m-t-8x {
  margin-top: 64px !important;
}

.p-t-8x {
  padding-top: 64px !important;
}

.m-b-8x {
  margin-bottom: 64px !important;
}

.p-b-8x {
  padding-bottom: 64px !important;
}

.m-l-8x {
  margin-left: 64px !important;
}

.p-l-8x {
  padding-left: 64px !important;
}

.m-r-8x {
  margin-right: 64px !important;
}

.p-r-8x {
  padding-right: 64px !important;
}

.m-8x {
  margin: 64px !important;
}

.p-8x {
  padding: 64px !important;
}

.m-t-9x {
  margin-top: 72px !important;
}

.p-t-9x {
  padding-top: 72px !important;
}

.m-b-9x {
  margin-bottom: 72px !important;
}

.p-b-9x {
  padding-bottom: 72px !important;
}

.m-l-9x {
  margin-left: 72px !important;
}

.p-l-9x {
  padding-left: 72px !important;
}

.m-r-9x {
  margin-right: 72px !important;
}

.p-r-9x {
  padding-right: 72px !important;
}

.m-9x {
  margin: 72px !important;
}

.p-9x {
  padding: 72px !important;
}

.m-t-10x {
  margin-top: 80px !important;
}

.p-t-10x {
  padding-top: 80px !important;
}

.m-b-10x {
  margin-bottom: 80px !important;
}

.p-b-10x {
  padding-bottom: 80px !important;
}

.m-l-10x {
  margin-left: 80px !important;
}

.p-l-10x {
  padding-left: 80px !important;
}

.m-r-10x {
  margin-right: 80px !important;
}

.p-r-10x {
  padding-right: 80px !important;
}

.m-10x {
  margin: 80px !important;
}

.p-10x {
  padding: 80px !important;
}

.m-t-11x {
  margin-top: 88px !important;
}

.p-t-11x {
  padding-top: 88px !important;
}

.m-b-11x {
  margin-bottom: 88px !important;
}

.p-b-11x {
  padding-bottom: 88px !important;
}

.m-l-11x {
  margin-left: 88px !important;
}

.p-l-11x {
  padding-left: 88px !important;
}

.m-r-11x {
  margin-right: 88px !important;
}

.p-r-11x {
  padding-right: 88px !important;
}

.m-11x {
  margin: 88px !important;
}

.p-11x {
  padding: 88px !important;
}

.m-t-12x {
  margin-top: 96px !important;
}

.p-t-12x {
  padding-top: 96px !important;
}

.m-b-12x {
  margin-bottom: 96px !important;
}

.p-b-12x {
  padding-bottom: 96px !important;
}

.m-l-12x {
  margin-left: 96px !important;
}

.p-l-12x {
  padding-left: 96px !important;
}

.m-r-12x {
  margin-right: 96px !important;
}

.p-r-12x {
  padding-right: 96px !important;
}

.m-12x {
  margin: 96px !important;
}

.p-12x {
  padding: 96px !important;
}

@media only screen and (min-width: 640px) {
  .m-t-1x-min640 {
    margin-top: 8px !important;
  }
  .p-t-1x-min640 {
    padding-top: 8px !important;
  }
  .m-b-1x-min640 {
    margin-bottom: 8px !important;
  }
  .p-b-1x-min640 {
    padding-bottom: 8px !important;
  }
  .m-l-1x-min640 {
    margin-left: 8px !important;
  }
  .p-l-1x-min640 {
    padding-left: 8px !important;
  }
  .m-r-1x-min640 {
    margin-right: 8px !important;
  }
  .p-r-1x-min640 {
    padding-right: 8px !important;
  }
  .m-t-2x-min640 {
    margin-top: 16px !important;
  }
  .p-t-2x-min640 {
    padding-top: 16px !important;
  }
  .m-b-2x-min640 {
    margin-bottom: 16px !important;
  }
  .p-b-2x-min640 {
    padding-bottom: 16px !important;
  }
  .m-l-2x-min640 {
    margin-left: 16px !important;
  }
  .p-l-2x-min640 {
    padding-left: 16px !important;
  }
  .m-r-2x-min640 {
    margin-right: 16px !important;
  }
  .p-r-2x-min640 {
    padding-right: 16px !important;
  }
  .m-t-3x-min640 {
    margin-top: 24px !important;
  }
  .p-t-3x-min640 {
    padding-top: 24px !important;
  }
  .m-b-3x-min640 {
    margin-bottom: 24px !important;
  }
  .p-b-3x-min640 {
    padding-bottom: 24px !important;
  }
  .m-l-3x-min640 {
    margin-left: 24px !important;
  }
  .p-l-3x-min640 {
    padding-left: 24px !important;
  }
  .m-r-3x-min640 {
    margin-right: 24px !important;
  }
  .p-r-3x-min640 {
    padding-right: 24px !important;
  }
  .m-t-4x-min640 {
    margin-top: 32px !important;
  }
  .p-t-4x-min640 {
    padding-top: 32px !important;
  }
  .m-b-4x-min640 {
    margin-bottom: 32px !important;
  }
  .p-b-4x-min640 {
    padding-bottom: 32px !important;
  }
  .m-l-4x-min640 {
    margin-left: 32px !important;
  }
  .p-l-4x-min640 {
    padding-left: 32px !important;
  }
  .m-r-4x-min640 {
    margin-right: 32px !important;
  }
  .p-r-4x-min640 {
    padding-right: 32px !important;
  }
  .m-t-5x-min640 {
    margin-top: 40px !important;
  }
  .p-t-5x-min640 {
    padding-top: 40px !important;
  }
  .m-b-5x-min640 {
    margin-bottom: 40px !important;
  }
  .p-b-5x-min640 {
    padding-bottom: 40px !important;
  }
  .m-l-5x-min640 {
    margin-left: 40px !important;
  }
  .p-l-5x-min640 {
    padding-left: 40px !important;
  }
  .m-r-5x-min640 {
    margin-right: 40px !important;
  }
  .p-r-5x-min640 {
    padding-right: 40px !important;
  }
  .m-t-6x-min640 {
    margin-top: 48px !important;
  }
  .p-t-6x-min640 {
    padding-top: 48px !important;
  }
  .m-b-6x-min640 {
    margin-bottom: 48px !important;
  }
  .p-b-6x-min640 {
    padding-bottom: 48px !important;
  }
  .m-l-6x-min640 {
    margin-left: 48px !important;
  }
  .p-l-6x-min640 {
    padding-left: 48px !important;
  }
  .m-r-6x-min640 {
    margin-right: 48px !important;
  }
  .p-r-6x-min640 {
    padding-right: 48px !important;
  }
  .m-t-7x-min640 {
    margin-top: 56px !important;
  }
  .p-t-7x-min640 {
    padding-top: 56px !important;
  }
  .m-b-7x-min640 {
    margin-bottom: 56px !important;
  }
  .p-b-7x-min640 {
    padding-bottom: 56px !important;
  }
  .m-l-7x-min640 {
    margin-left: 56px !important;
  }
  .p-l-7x-min640 {
    padding-left: 56px !important;
  }
  .m-r-7x-min640 {
    margin-right: 56px !important;
  }
  .p-r-7x-min640 {
    padding-right: 56px !important;
  }
  .m-t-8x-min640 {
    margin-top: 64px !important;
  }
  .p-t-8x-min640 {
    padding-top: 64px !important;
  }
  .m-b-8x-min640 {
    margin-bottom: 64px !important;
  }
  .p-b-8x-min640 {
    padding-bottom: 64px !important;
  }
  .m-l-8x-min640 {
    margin-left: 64px !important;
  }
  .p-l-8x-min640 {
    padding-left: 64px !important;
  }
  .m-r-8x-min640 {
    margin-right: 64px !important;
  }
  .p-r-8x-min640 {
    padding-right: 64px !important;
  }
  .m-t-9x-min640 {
    margin-top: 72px !important;
  }
  .p-t-9x-min640 {
    padding-top: 72px !important;
  }
  .m-b-9x-min640 {
    margin-bottom: 72px !important;
  }
  .p-b-9x-min640 {
    padding-bottom: 72px !important;
  }
  .m-l-9x-min640 {
    margin-left: 72px !important;
  }
  .p-l-9x-min640 {
    padding-left: 72px !important;
  }
  .m-r-9x-min640 {
    margin-right: 72px !important;
  }
  .p-r-9x-min640 {
    padding-right: 72px !important;
  }
  .m-t-10x-min640 {
    margin-top: 80px !important;
  }
  .p-t-10x-min640 {
    padding-top: 80px !important;
  }
  .m-b-10x-min640 {
    margin-bottom: 80px !important;
  }
  .p-b-10x-min640 {
    padding-bottom: 80px !important;
  }
  .m-l-10x-min640 {
    margin-left: 80px !important;
  }
  .p-l-10x-min640 {
    padding-left: 80px !important;
  }
  .m-r-10x-min640 {
    margin-right: 80px !important;
  }
  .p-r-10x-min640 {
    padding-right: 80px !important;
  }
  .m-t-11x-min640 {
    margin-top: 88px !important;
  }
  .p-t-11x-min640 {
    padding-top: 88px !important;
  }
  .m-b-11x-min640 {
    margin-bottom: 88px !important;
  }
  .p-b-11x-min640 {
    padding-bottom: 88px !important;
  }
  .m-l-11x-min640 {
    margin-left: 88px !important;
  }
  .p-l-11x-min640 {
    padding-left: 88px !important;
  }
  .m-r-11x-min640 {
    margin-right: 88px !important;
  }
  .p-r-11x-min640 {
    padding-right: 88px !important;
  }
  .m-t-12x-min640 {
    margin-top: 96px !important;
  }
  .p-t-12x-min640 {
    padding-top: 96px !important;
  }
  .m-b-12x-min640 {
    margin-bottom: 96px !important;
  }
  .p-b-12x-min640 {
    padding-bottom: 96px !important;
  }
  .m-l-12x-min640 {
    margin-left: 96px !important;
  }
  .p-l-12x-min640 {
    padding-left: 96px !important;
  }
  .m-r-12x-min640 {
    margin-right: 96px !important;
  }
  .p-r-12x-min640 {
    padding-right: 96px !important;
  }
}
@media only screen and (max-width: 640px) {
  .m-t-1x-max640 {
    margin-top: 8px !important;
  }
  .p-t-1x-max640 {
    padding-top: 8px !important;
  }
  .m-b-1x-max640 {
    margin-bottom: 8px !important;
  }
  .p-b-1x-max640 {
    padding-bottom: 8px !important;
  }
  .m-l-1x-max640 {
    margin-left: 8px !important;
  }
  .p-l-1x-max640 {
    padding-left: 8px !important;
  }
  .m-r-1x-max640 {
    margin-right: 8px !important;
  }
  .p-r-1x-max640 {
    padding-right: 8px !important;
  }
  .m-t-2x-max640 {
    margin-top: 16px !important;
  }
  .p-t-2x-max640 {
    padding-top: 16px !important;
  }
  .m-b-2x-max640 {
    margin-bottom: 16px !important;
  }
  .p-b-2x-max640 {
    padding-bottom: 16px !important;
  }
  .m-l-2x-max640 {
    margin-left: 16px !important;
  }
  .p-l-2x-max640 {
    padding-left: 16px !important;
  }
  .m-r-2x-max640 {
    margin-right: 16px !important;
  }
  .p-r-2x-max640 {
    padding-right: 16px !important;
  }
  .m-t-3x-max640 {
    margin-top: 24px !important;
  }
  .p-t-3x-max640 {
    padding-top: 24px !important;
  }
  .m-b-3x-max640 {
    margin-bottom: 24px !important;
  }
  .p-b-3x-max640 {
    padding-bottom: 24px !important;
  }
  .m-l-3x-max640 {
    margin-left: 24px !important;
  }
  .p-l-3x-max640 {
    padding-left: 24px !important;
  }
  .m-r-3x-max640 {
    margin-right: 24px !important;
  }
  .p-r-3x-max640 {
    padding-right: 24px !important;
  }
  .m-t-4x-max640 {
    margin-top: 32px !important;
  }
  .p-t-4x-max640 {
    padding-top: 32px !important;
  }
  .m-b-4x-max640 {
    margin-bottom: 32px !important;
  }
  .p-b-4x-max640 {
    padding-bottom: 32px !important;
  }
  .m-l-4x-max640 {
    margin-left: 32px !important;
  }
  .p-l-4x-max640 {
    padding-left: 32px !important;
  }
  .m-r-4x-max640 {
    margin-right: 32px !important;
  }
  .p-r-4x-max640 {
    padding-right: 32px !important;
  }
  .m-t-5x-max640 {
    margin-top: 40px !important;
  }
  .p-t-5x-max640 {
    padding-top: 40px !important;
  }
  .m-b-5x-max640 {
    margin-bottom: 40px !important;
  }
  .p-b-5x-max640 {
    padding-bottom: 40px !important;
  }
  .m-l-5x-max640 {
    margin-left: 40px !important;
  }
  .p-l-5x-max640 {
    padding-left: 40px !important;
  }
  .m-r-5x-max640 {
    margin-right: 40px !important;
  }
  .p-r-5x-max640 {
    padding-right: 40px !important;
  }
  .m-t-6x-max640 {
    margin-top: 48px !important;
  }
  .p-t-6x-max640 {
    padding-top: 48px !important;
  }
  .m-b-6x-max640 {
    margin-bottom: 48px !important;
  }
  .p-b-6x-max640 {
    padding-bottom: 48px !important;
  }
  .m-l-6x-max640 {
    margin-left: 48px !important;
  }
  .p-l-6x-max640 {
    padding-left: 48px !important;
  }
  .m-r-6x-max640 {
    margin-right: 48px !important;
  }
  .p-r-6x-max640 {
    padding-right: 48px !important;
  }
  .m-t-7x-max640 {
    margin-top: 56px !important;
  }
  .p-t-7x-max640 {
    padding-top: 56px !important;
  }
  .m-b-7x-max640 {
    margin-bottom: 56px !important;
  }
  .p-b-7x-max640 {
    padding-bottom: 56px !important;
  }
  .m-l-7x-max640 {
    margin-left: 56px !important;
  }
  .p-l-7x-max640 {
    padding-left: 56px !important;
  }
  .m-r-7x-max640 {
    margin-right: 56px !important;
  }
  .p-r-7x-max640 {
    padding-right: 56px !important;
  }
  .m-t-8x-max640 {
    margin-top: 64px !important;
  }
  .p-t-8x-max640 {
    padding-top: 64px !important;
  }
  .m-b-8x-max640 {
    margin-bottom: 64px !important;
  }
  .p-b-8x-max640 {
    padding-bottom: 64px !important;
  }
  .m-l-8x-max640 {
    margin-left: 64px !important;
  }
  .p-l-8x-max640 {
    padding-left: 64px !important;
  }
  .m-r-8x-max640 {
    margin-right: 64px !important;
  }
  .p-r-8x-max640 {
    padding-right: 64px !important;
  }
  .m-t-9x-max640 {
    margin-top: 72px !important;
  }
  .p-t-9x-max640 {
    padding-top: 72px !important;
  }
  .m-b-9x-max640 {
    margin-bottom: 72px !important;
  }
  .p-b-9x-max640 {
    padding-bottom: 72px !important;
  }
  .m-l-9x-max640 {
    margin-left: 72px !important;
  }
  .p-l-9x-max640 {
    padding-left: 72px !important;
  }
  .m-r-9x-max640 {
    margin-right: 72px !important;
  }
  .p-r-9x-max640 {
    padding-right: 72px !important;
  }
  .m-t-10x-max640 {
    margin-top: 80px !important;
  }
  .p-t-10x-max640 {
    padding-top: 80px !important;
  }
  .m-b-10x-max640 {
    margin-bottom: 80px !important;
  }
  .p-b-10x-max640 {
    padding-bottom: 80px !important;
  }
  .m-l-10x-max640 {
    margin-left: 80px !important;
  }
  .p-l-10x-max640 {
    padding-left: 80px !important;
  }
  .m-r-10x-max640 {
    margin-right: 80px !important;
  }
  .p-r-10x-max640 {
    padding-right: 80px !important;
  }
  .m-t-11x-max640 {
    margin-top: 88px !important;
  }
  .p-t-11x-max640 {
    padding-top: 88px !important;
  }
  .m-b-11x-max640 {
    margin-bottom: 88px !important;
  }
  .p-b-11x-max640 {
    padding-bottom: 88px !important;
  }
  .m-l-11x-max640 {
    margin-left: 88px !important;
  }
  .p-l-11x-max640 {
    padding-left: 88px !important;
  }
  .m-r-11x-max640 {
    margin-right: 88px !important;
  }
  .p-r-11x-max640 {
    padding-right: 88px !important;
  }
  .m-t-12x-max640 {
    margin-top: 96px !important;
  }
  .p-t-12x-max640 {
    padding-top: 96px !important;
  }
  .m-b-12x-max640 {
    margin-bottom: 96px !important;
  }
  .p-b-12x-max640 {
    padding-bottom: 96px !important;
  }
  .m-l-12x-max640 {
    margin-left: 96px !important;
  }
  .p-l-12x-max640 {
    padding-left: 96px !important;
  }
  .m-r-12x-max640 {
    margin-right: 96px !important;
  }
  .p-r-12x-max640 {
    padding-right: 96px !important;
  }
}
@media only screen and (min-width: 768px) {
  .m-t-1x-min768 {
    margin-top: 8px !important;
  }
  .p-t-1x-min768 {
    padding-top: 8px !important;
  }
  .m-b-1x-min768 {
    margin-bottom: 8px !important;
  }
  .p-b-1x-min768 {
    padding-bottom: 8px !important;
  }
  .m-l-1x-min768 {
    margin-left: 8px !important;
  }
  .p-l-1x-min768 {
    padding-left: 8px !important;
  }
  .m-r-1x-min768 {
    margin-right: 8px !important;
  }
  .p-r-1x-min768 {
    padding-right: 8px !important;
  }
  .m-t-2x-min768 {
    margin-top: 16px !important;
  }
  .p-t-2x-min768 {
    padding-top: 16px !important;
  }
  .m-b-2x-min768 {
    margin-bottom: 16px !important;
  }
  .p-b-2x-min768 {
    padding-bottom: 16px !important;
  }
  .m-l-2x-min768 {
    margin-left: 16px !important;
  }
  .p-l-2x-min768 {
    padding-left: 16px !important;
  }
  .m-r-2x-min768 {
    margin-right: 16px !important;
  }
  .p-r-2x-min768 {
    padding-right: 16px !important;
  }
  .m-t-3x-min768 {
    margin-top: 24px !important;
  }
  .p-t-3x-min768 {
    padding-top: 24px !important;
  }
  .m-b-3x-min768 {
    margin-bottom: 24px !important;
  }
  .p-b-3x-min768 {
    padding-bottom: 24px !important;
  }
  .m-l-3x-min768 {
    margin-left: 24px !important;
  }
  .p-l-3x-min768 {
    padding-left: 24px !important;
  }
  .m-r-3x-min768 {
    margin-right: 24px !important;
  }
  .p-r-3x-min768 {
    padding-right: 24px !important;
  }
  .m-t-4x-min768 {
    margin-top: 32px !important;
  }
  .p-t-4x-min768 {
    padding-top: 32px !important;
  }
  .m-b-4x-min768 {
    margin-bottom: 32px !important;
  }
  .p-b-4x-min768 {
    padding-bottom: 32px !important;
  }
  .m-l-4x-min768 {
    margin-left: 32px !important;
  }
  .p-l-4x-min768 {
    padding-left: 32px !important;
  }
  .m-r-4x-min768 {
    margin-right: 32px !important;
  }
  .p-r-4x-min768 {
    padding-right: 32px !important;
  }
  .m-t-5x-min768 {
    margin-top: 40px !important;
  }
  .p-t-5x-min768 {
    padding-top: 40px !important;
  }
  .m-b-5x-min768 {
    margin-bottom: 40px !important;
  }
  .p-b-5x-min768 {
    padding-bottom: 40px !important;
  }
  .m-l-5x-min768 {
    margin-left: 40px !important;
  }
  .p-l-5x-min768 {
    padding-left: 40px !important;
  }
  .m-r-5x-min768 {
    margin-right: 40px !important;
  }
  .p-r-5x-min768 {
    padding-right: 40px !important;
  }
  .m-t-6x-min768 {
    margin-top: 48px !important;
  }
  .p-t-6x-min768 {
    padding-top: 48px !important;
  }
  .m-b-6x-min768 {
    margin-bottom: 48px !important;
  }
  .p-b-6x-min768 {
    padding-bottom: 48px !important;
  }
  .m-l-6x-min768 {
    margin-left: 48px !important;
  }
  .p-l-6x-min768 {
    padding-left: 48px !important;
  }
  .m-r-6x-min768 {
    margin-right: 48px !important;
  }
  .p-r-6x-min768 {
    padding-right: 48px !important;
  }
  .m-t-7x-min768 {
    margin-top: 56px !important;
  }
  .p-t-7x-min768 {
    padding-top: 56px !important;
  }
  .m-b-7x-min768 {
    margin-bottom: 56px !important;
  }
  .p-b-7x-min768 {
    padding-bottom: 56px !important;
  }
  .m-l-7x-min768 {
    margin-left: 56px !important;
  }
  .p-l-7x-min768 {
    padding-left: 56px !important;
  }
  .m-r-7x-min768 {
    margin-right: 56px !important;
  }
  .p-r-7x-min768 {
    padding-right: 56px !important;
  }
  .m-t-8x-min768 {
    margin-top: 64px !important;
  }
  .p-t-8x-min768 {
    padding-top: 64px !important;
  }
  .m-b-8x-min768 {
    margin-bottom: 64px !important;
  }
  .p-b-8x-min768 {
    padding-bottom: 64px !important;
  }
  .m-l-8x-min768 {
    margin-left: 64px !important;
  }
  .p-l-8x-min768 {
    padding-left: 64px !important;
  }
  .m-r-8x-min768 {
    margin-right: 64px !important;
  }
  .p-r-8x-min768 {
    padding-right: 64px !important;
  }
  .m-t-9x-min768 {
    margin-top: 72px !important;
  }
  .p-t-9x-min768 {
    padding-top: 72px !important;
  }
  .m-b-9x-min768 {
    margin-bottom: 72px !important;
  }
  .p-b-9x-min768 {
    padding-bottom: 72px !important;
  }
  .m-l-9x-min768 {
    margin-left: 72px !important;
  }
  .p-l-9x-min768 {
    padding-left: 72px !important;
  }
  .m-r-9x-min768 {
    margin-right: 72px !important;
  }
  .p-r-9x-min768 {
    padding-right: 72px !important;
  }
  .m-t-10x-min768 {
    margin-top: 80px !important;
  }
  .p-t-10x-min768 {
    padding-top: 80px !important;
  }
  .m-b-10x-min768 {
    margin-bottom: 80px !important;
  }
  .p-b-10x-min768 {
    padding-bottom: 80px !important;
  }
  .m-l-10x-min768 {
    margin-left: 80px !important;
  }
  .p-l-10x-min768 {
    padding-left: 80px !important;
  }
  .m-r-10x-min768 {
    margin-right: 80px !important;
  }
  .p-r-10x-min768 {
    padding-right: 80px !important;
  }
  .m-t-11x-min768 {
    margin-top: 88px !important;
  }
  .p-t-11x-min768 {
    padding-top: 88px !important;
  }
  .m-b-11x-min768 {
    margin-bottom: 88px !important;
  }
  .p-b-11x-min768 {
    padding-bottom: 88px !important;
  }
  .m-l-11x-min768 {
    margin-left: 88px !important;
  }
  .p-l-11x-min768 {
    padding-left: 88px !important;
  }
  .m-r-11x-min768 {
    margin-right: 88px !important;
  }
  .p-r-11x-min768 {
    padding-right: 88px !important;
  }
  .m-t-12x-min768 {
    margin-top: 96px !important;
  }
  .p-t-12x-min768 {
    padding-top: 96px !important;
  }
  .m-b-12x-min768 {
    margin-bottom: 96px !important;
  }
  .p-b-12x-min768 {
    padding-bottom: 96px !important;
  }
  .m-l-12x-min768 {
    margin-left: 96px !important;
  }
  .p-l-12x-min768 {
    padding-left: 96px !important;
  }
  .m-r-12x-min768 {
    margin-right: 96px !important;
  }
  .p-r-12x-min768 {
    padding-right: 96px !important;
  }
}
@media only screen and (max-width: 768px) {
  .m-t-1x-max768 {
    margin-top: 8px !important;
  }
  .p-t-1x-max768 {
    padding-top: 8px !important;
  }
  .m-b-1x-max768 {
    margin-bottom: 8px !important;
  }
  .p-b-1x-max768 {
    padding-bottom: 8px !important;
  }
  .m-l-1x-max768 {
    margin-left: 8px !important;
  }
  .p-l-1x-max768 {
    padding-left: 8px !important;
  }
  .m-r-1x-max768 {
    margin-right: 8px !important;
  }
  .p-r-1x-max768 {
    padding-right: 8px !important;
  }
  .m-t-2x-max768 {
    margin-top: 16px !important;
  }
  .p-t-2x-max768 {
    padding-top: 16px !important;
  }
  .m-b-2x-max768 {
    margin-bottom: 16px !important;
  }
  .p-b-2x-max768 {
    padding-bottom: 16px !important;
  }
  .m-l-2x-max768 {
    margin-left: 16px !important;
  }
  .p-l-2x-max768 {
    padding-left: 16px !important;
  }
  .m-r-2x-max768 {
    margin-right: 16px !important;
  }
  .p-r-2x-max768 {
    padding-right: 16px !important;
  }
  .m-t-3x-max768 {
    margin-top: 24px !important;
  }
  .p-t-3x-max768 {
    padding-top: 24px !important;
  }
  .m-b-3x-max768 {
    margin-bottom: 24px !important;
  }
  .p-b-3x-max768 {
    padding-bottom: 24px !important;
  }
  .m-l-3x-max768 {
    margin-left: 24px !important;
  }
  .p-l-3x-max768 {
    padding-left: 24px !important;
  }
  .m-r-3x-max768 {
    margin-right: 24px !important;
  }
  .p-r-3x-max768 {
    padding-right: 24px !important;
  }
  .m-t-4x-max768 {
    margin-top: 32px !important;
  }
  .p-t-4x-max768 {
    padding-top: 32px !important;
  }
  .m-b-4x-max768 {
    margin-bottom: 32px !important;
  }
  .p-b-4x-max768 {
    padding-bottom: 32px !important;
  }
  .m-l-4x-max768 {
    margin-left: 32px !important;
  }
  .p-l-4x-max768 {
    padding-left: 32px !important;
  }
  .m-r-4x-max768 {
    margin-right: 32px !important;
  }
  .p-r-4x-max768 {
    padding-right: 32px !important;
  }
  .m-t-5x-max768 {
    margin-top: 40px !important;
  }
  .p-t-5x-max768 {
    padding-top: 40px !important;
  }
  .m-b-5x-max768 {
    margin-bottom: 40px !important;
  }
  .p-b-5x-max768 {
    padding-bottom: 40px !important;
  }
  .m-l-5x-max768 {
    margin-left: 40px !important;
  }
  .p-l-5x-max768 {
    padding-left: 40px !important;
  }
  .m-r-5x-max768 {
    margin-right: 40px !important;
  }
  .p-r-5x-max768 {
    padding-right: 40px !important;
  }
  .m-t-6x-max768 {
    margin-top: 48px !important;
  }
  .p-t-6x-max768 {
    padding-top: 48px !important;
  }
  .m-b-6x-max768 {
    margin-bottom: 48px !important;
  }
  .p-b-6x-max768 {
    padding-bottom: 48px !important;
  }
  .m-l-6x-max768 {
    margin-left: 48px !important;
  }
  .p-l-6x-max768 {
    padding-left: 48px !important;
  }
  .m-r-6x-max768 {
    margin-right: 48px !important;
  }
  .p-r-6x-max768 {
    padding-right: 48px !important;
  }
  .m-t-7x-max768 {
    margin-top: 56px !important;
  }
  .p-t-7x-max768 {
    padding-top: 56px !important;
  }
  .m-b-7x-max768 {
    margin-bottom: 56px !important;
  }
  .p-b-7x-max768 {
    padding-bottom: 56px !important;
  }
  .m-l-7x-max768 {
    margin-left: 56px !important;
  }
  .p-l-7x-max768 {
    padding-left: 56px !important;
  }
  .m-r-7x-max768 {
    margin-right: 56px !important;
  }
  .p-r-7x-max768 {
    padding-right: 56px !important;
  }
  .m-t-8x-max768 {
    margin-top: 64px !important;
  }
  .p-t-8x-max768 {
    padding-top: 64px !important;
  }
  .m-b-8x-max768 {
    margin-bottom: 64px !important;
  }
  .p-b-8x-max768 {
    padding-bottom: 64px !important;
  }
  .m-l-8x-max768 {
    margin-left: 64px !important;
  }
  .p-l-8x-max768 {
    padding-left: 64px !important;
  }
  .m-r-8x-max768 {
    margin-right: 64px !important;
  }
  .p-r-8x-max768 {
    padding-right: 64px !important;
  }
  .m-t-9x-max768 {
    margin-top: 72px !important;
  }
  .p-t-9x-max768 {
    padding-top: 72px !important;
  }
  .m-b-9x-max768 {
    margin-bottom: 72px !important;
  }
  .p-b-9x-max768 {
    padding-bottom: 72px !important;
  }
  .m-l-9x-max768 {
    margin-left: 72px !important;
  }
  .p-l-9x-max768 {
    padding-left: 72px !important;
  }
  .m-r-9x-max768 {
    margin-right: 72px !important;
  }
  .p-r-9x-max768 {
    padding-right: 72px !important;
  }
  .m-t-10x-max768 {
    margin-top: 80px !important;
  }
  .p-t-10x-max768 {
    padding-top: 80px !important;
  }
  .m-b-10x-max768 {
    margin-bottom: 80px !important;
  }
  .p-b-10x-max768 {
    padding-bottom: 80px !important;
  }
  .m-l-10x-max768 {
    margin-left: 80px !important;
  }
  .p-l-10x-max768 {
    padding-left: 80px !important;
  }
  .m-r-10x-max768 {
    margin-right: 80px !important;
  }
  .p-r-10x-max768 {
    padding-right: 80px !important;
  }
  .m-t-11x-max768 {
    margin-top: 88px !important;
  }
  .p-t-11x-max768 {
    padding-top: 88px !important;
  }
  .m-b-11x-max768 {
    margin-bottom: 88px !important;
  }
  .p-b-11x-max768 {
    padding-bottom: 88px !important;
  }
  .m-l-11x-max768 {
    margin-left: 88px !important;
  }
  .p-l-11x-max768 {
    padding-left: 88px !important;
  }
  .m-r-11x-max768 {
    margin-right: 88px !important;
  }
  .p-r-11x-max768 {
    padding-right: 88px !important;
  }
  .m-t-12x-max768 {
    margin-top: 96px !important;
  }
  .p-t-12x-max768 {
    padding-top: 96px !important;
  }
  .m-b-12x-max768 {
    margin-bottom: 96px !important;
  }
  .p-b-12x-max768 {
    padding-bottom: 96px !important;
  }
  .m-l-12x-max768 {
    margin-left: 96px !important;
  }
  .p-l-12x-max768 {
    padding-left: 96px !important;
  }
  .m-r-12x-max768 {
    margin-right: 96px !important;
  }
  .p-r-12x-max768 {
    padding-right: 96px !important;
  }
}
@media only screen and (min-width: 1024px) {
  .m-t-1x-min1024 {
    margin-top: 8px !important;
  }
  .p-t-1x-min1024 {
    padding-top: 8px !important;
  }
  .m-b-1x-min1024 {
    margin-bottom: 8px !important;
  }
  .p-b-1x-min1024 {
    padding-bottom: 8px !important;
  }
  .m-l-1x-min1024 {
    margin-left: 8px !important;
  }
  .p-l-1x-min1024 {
    padding-left: 8px !important;
  }
  .m-r-1x-min1024 {
    margin-right: 8px !important;
  }
  .p-r-1x-min1024 {
    padding-right: 8px !important;
  }
  .m-t-2x-min1024 {
    margin-top: 16px !important;
  }
  .p-t-2x-min1024 {
    padding-top: 16px !important;
  }
  .m-b-2x-min1024 {
    margin-bottom: 16px !important;
  }
  .p-b-2x-min1024 {
    padding-bottom: 16px !important;
  }
  .m-l-2x-min1024 {
    margin-left: 16px !important;
  }
  .p-l-2x-min1024 {
    padding-left: 16px !important;
  }
  .m-r-2x-min1024 {
    margin-right: 16px !important;
  }
  .p-r-2x-min1024 {
    padding-right: 16px !important;
  }
  .m-t-3x-min1024 {
    margin-top: 24px !important;
  }
  .p-t-3x-min1024 {
    padding-top: 24px !important;
  }
  .m-b-3x-min1024 {
    margin-bottom: 24px !important;
  }
  .p-b-3x-min1024 {
    padding-bottom: 24px !important;
  }
  .m-l-3x-min1024 {
    margin-left: 24px !important;
  }
  .p-l-3x-min1024 {
    padding-left: 24px !important;
  }
  .m-r-3x-min1024 {
    margin-right: 24px !important;
  }
  .p-r-3x-min1024 {
    padding-right: 24px !important;
  }
  .m-t-4x-min1024 {
    margin-top: 32px !important;
  }
  .p-t-4x-min1024 {
    padding-top: 32px !important;
  }
  .m-b-4x-min1024 {
    margin-bottom: 32px !important;
  }
  .p-b-4x-min1024 {
    padding-bottom: 32px !important;
  }
  .m-l-4x-min1024 {
    margin-left: 32px !important;
  }
  .p-l-4x-min1024 {
    padding-left: 32px !important;
  }
  .m-r-4x-min1024 {
    margin-right: 32px !important;
  }
  .p-r-4x-min1024 {
    padding-right: 32px !important;
  }
  .m-t-5x-min1024 {
    margin-top: 40px !important;
  }
  .p-t-5x-min1024 {
    padding-top: 40px !important;
  }
  .m-b-5x-min1024 {
    margin-bottom: 40px !important;
  }
  .p-b-5x-min1024 {
    padding-bottom: 40px !important;
  }
  .m-l-5x-min1024 {
    margin-left: 40px !important;
  }
  .p-l-5x-min1024 {
    padding-left: 40px !important;
  }
  .m-r-5x-min1024 {
    margin-right: 40px !important;
  }
  .p-r-5x-min1024 {
    padding-right: 40px !important;
  }
  .m-t-6x-min1024 {
    margin-top: 48px !important;
  }
  .p-t-6x-min1024 {
    padding-top: 48px !important;
  }
  .m-b-6x-min1024 {
    margin-bottom: 48px !important;
  }
  .p-b-6x-min1024 {
    padding-bottom: 48px !important;
  }
  .m-l-6x-min1024 {
    margin-left: 48px !important;
  }
  .p-l-6x-min1024 {
    padding-left: 48px !important;
  }
  .m-r-6x-min1024 {
    margin-right: 48px !important;
  }
  .p-r-6x-min1024 {
    padding-right: 48px !important;
  }
  .m-t-7x-min1024 {
    margin-top: 56px !important;
  }
  .p-t-7x-min1024 {
    padding-top: 56px !important;
  }
  .m-b-7x-min1024 {
    margin-bottom: 56px !important;
  }
  .p-b-7x-min1024 {
    padding-bottom: 56px !important;
  }
  .m-l-7x-min1024 {
    margin-left: 56px !important;
  }
  .p-l-7x-min1024 {
    padding-left: 56px !important;
  }
  .m-r-7x-min1024 {
    margin-right: 56px !important;
  }
  .p-r-7x-min1024 {
    padding-right: 56px !important;
  }
  .m-t-8x-min1024 {
    margin-top: 64px !important;
  }
  .p-t-8x-min1024 {
    padding-top: 64px !important;
  }
  .m-b-8x-min1024 {
    margin-bottom: 64px !important;
  }
  .p-b-8x-min1024 {
    padding-bottom: 64px !important;
  }
  .m-l-8x-min1024 {
    margin-left: 64px !important;
  }
  .p-l-8x-min1024 {
    padding-left: 64px !important;
  }
  .m-r-8x-min1024 {
    margin-right: 64px !important;
  }
  .p-r-8x-min1024 {
    padding-right: 64px !important;
  }
  .m-t-9x-min1024 {
    margin-top: 72px !important;
  }
  .p-t-9x-min1024 {
    padding-top: 72px !important;
  }
  .m-b-9x-min1024 {
    margin-bottom: 72px !important;
  }
  .p-b-9x-min1024 {
    padding-bottom: 72px !important;
  }
  .m-l-9x-min1024 {
    margin-left: 72px !important;
  }
  .p-l-9x-min1024 {
    padding-left: 72px !important;
  }
  .m-r-9x-min1024 {
    margin-right: 72px !important;
  }
  .p-r-9x-min1024 {
    padding-right: 72px !important;
  }
  .m-t-10x-min1024 {
    margin-top: 80px !important;
  }
  .p-t-10x-min1024 {
    padding-top: 80px !important;
  }
  .m-b-10x-min1024 {
    margin-bottom: 80px !important;
  }
  .p-b-10x-min1024 {
    padding-bottom: 80px !important;
  }
  .m-l-10x-min1024 {
    margin-left: 80px !important;
  }
  .p-l-10x-min1024 {
    padding-left: 80px !important;
  }
  .m-r-10x-min1024 {
    margin-right: 80px !important;
  }
  .p-r-10x-min1024 {
    padding-right: 80px !important;
  }
  .m-t-11x-min1024 {
    margin-top: 88px !important;
  }
  .p-t-11x-min1024 {
    padding-top: 88px !important;
  }
  .m-b-11x-min1024 {
    margin-bottom: 88px !important;
  }
  .p-b-11x-min1024 {
    padding-bottom: 88px !important;
  }
  .m-l-11x-min1024 {
    margin-left: 88px !important;
  }
  .p-l-11x-min1024 {
    padding-left: 88px !important;
  }
  .m-r-11x-min1024 {
    margin-right: 88px !important;
  }
  .p-r-11x-min1024 {
    padding-right: 88px !important;
  }
  .m-t-12x-min1024 {
    margin-top: 96px !important;
  }
  .p-t-12x-min1024 {
    padding-top: 96px !important;
  }
  .m-b-12x-min1024 {
    margin-bottom: 96px !important;
  }
  .p-b-12x-min1024 {
    padding-bottom: 96px !important;
  }
  .m-l-12x-min1024 {
    margin-left: 96px !important;
  }
  .p-l-12x-min1024 {
    padding-left: 96px !important;
  }
  .m-r-12x-min1024 {
    margin-right: 96px !important;
  }
  .p-r-12x-min1024 {
    padding-right: 96px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .m-t-1x-max1024 {
    margin-top: 8px !important;
  }
  .p-t-1x-max1024 {
    padding-top: 8px !important;
  }
  .m-b-1x-max1024 {
    margin-bottom: 8px !important;
  }
  .p-b-1x-max1024 {
    padding-bottom: 8px !important;
  }
  .m-l-1x-max1024 {
    margin-left: 8px !important;
  }
  .p-l-1x-max1024 {
    padding-left: 8px !important;
  }
  .m-r-1x-max1024 {
    margin-right: 8px !important;
  }
  .p-r-1x-max1024 {
    padding-right: 8px !important;
  }
  .m-t-2x-max1024 {
    margin-top: 16px !important;
  }
  .p-t-2x-max1024 {
    padding-top: 16px !important;
  }
  .m-b-2x-max1024 {
    margin-bottom: 16px !important;
  }
  .p-b-2x-max1024 {
    padding-bottom: 16px !important;
  }
  .m-l-2x-max1024 {
    margin-left: 16px !important;
  }
  .p-l-2x-max1024 {
    padding-left: 16px !important;
  }
  .m-r-2x-max1024 {
    margin-right: 16px !important;
  }
  .p-r-2x-max1024 {
    padding-right: 16px !important;
  }
  .m-t-3x-max1024 {
    margin-top: 24px !important;
  }
  .p-t-3x-max1024 {
    padding-top: 24px !important;
  }
  .m-b-3x-max1024 {
    margin-bottom: 24px !important;
  }
  .p-b-3x-max1024 {
    padding-bottom: 24px !important;
  }
  .m-l-3x-max1024 {
    margin-left: 24px !important;
  }
  .p-l-3x-max1024 {
    padding-left: 24px !important;
  }
  .m-r-3x-max1024 {
    margin-right: 24px !important;
  }
  .p-r-3x-max1024 {
    padding-right: 24px !important;
  }
  .m-t-4x-max1024 {
    margin-top: 32px !important;
  }
  .p-t-4x-max1024 {
    padding-top: 32px !important;
  }
  .m-b-4x-max1024 {
    margin-bottom: 32px !important;
  }
  .p-b-4x-max1024 {
    padding-bottom: 32px !important;
  }
  .m-l-4x-max1024 {
    margin-left: 32px !important;
  }
  .p-l-4x-max1024 {
    padding-left: 32px !important;
  }
  .m-r-4x-max1024 {
    margin-right: 32px !important;
  }
  .p-r-4x-max1024 {
    padding-right: 32px !important;
  }
  .m-t-5x-max1024 {
    margin-top: 40px !important;
  }
  .p-t-5x-max1024 {
    padding-top: 40px !important;
  }
  .m-b-5x-max1024 {
    margin-bottom: 40px !important;
  }
  .p-b-5x-max1024 {
    padding-bottom: 40px !important;
  }
  .m-l-5x-max1024 {
    margin-left: 40px !important;
  }
  .p-l-5x-max1024 {
    padding-left: 40px !important;
  }
  .m-r-5x-max1024 {
    margin-right: 40px !important;
  }
  .p-r-5x-max1024 {
    padding-right: 40px !important;
  }
  .m-t-6x-max1024 {
    margin-top: 48px !important;
  }
  .p-t-6x-max1024 {
    padding-top: 48px !important;
  }
  .m-b-6x-max1024 {
    margin-bottom: 48px !important;
  }
  .p-b-6x-max1024 {
    padding-bottom: 48px !important;
  }
  .m-l-6x-max1024 {
    margin-left: 48px !important;
  }
  .p-l-6x-max1024 {
    padding-left: 48px !important;
  }
  .m-r-6x-max1024 {
    margin-right: 48px !important;
  }
  .p-r-6x-max1024 {
    padding-right: 48px !important;
  }
  .m-t-7x-max1024 {
    margin-top: 56px !important;
  }
  .p-t-7x-max1024 {
    padding-top: 56px !important;
  }
  .m-b-7x-max1024 {
    margin-bottom: 56px !important;
  }
  .p-b-7x-max1024 {
    padding-bottom: 56px !important;
  }
  .m-l-7x-max1024 {
    margin-left: 56px !important;
  }
  .p-l-7x-max1024 {
    padding-left: 56px !important;
  }
  .m-r-7x-max1024 {
    margin-right: 56px !important;
  }
  .p-r-7x-max1024 {
    padding-right: 56px !important;
  }
  .m-t-8x-max1024 {
    margin-top: 64px !important;
  }
  .p-t-8x-max1024 {
    padding-top: 64px !important;
  }
  .m-b-8x-max1024 {
    margin-bottom: 64px !important;
  }
  .p-b-8x-max1024 {
    padding-bottom: 64px !important;
  }
  .m-l-8x-max1024 {
    margin-left: 64px !important;
  }
  .p-l-8x-max1024 {
    padding-left: 64px !important;
  }
  .m-r-8x-max1024 {
    margin-right: 64px !important;
  }
  .p-r-8x-max1024 {
    padding-right: 64px !important;
  }
  .m-t-9x-max1024 {
    margin-top: 72px !important;
  }
  .p-t-9x-max1024 {
    padding-top: 72px !important;
  }
  .m-b-9x-max1024 {
    margin-bottom: 72px !important;
  }
  .p-b-9x-max1024 {
    padding-bottom: 72px !important;
  }
  .m-l-9x-max1024 {
    margin-left: 72px !important;
  }
  .p-l-9x-max1024 {
    padding-left: 72px !important;
  }
  .m-r-9x-max1024 {
    margin-right: 72px !important;
  }
  .p-r-9x-max1024 {
    padding-right: 72px !important;
  }
  .m-t-10x-max1024 {
    margin-top: 80px !important;
  }
  .p-t-10x-max1024 {
    padding-top: 80px !important;
  }
  .m-b-10x-max1024 {
    margin-bottom: 80px !important;
  }
  .p-b-10x-max1024 {
    padding-bottom: 80px !important;
  }
  .m-l-10x-max1024 {
    margin-left: 80px !important;
  }
  .p-l-10x-max1024 {
    padding-left: 80px !important;
  }
  .m-r-10x-max1024 {
    margin-right: 80px !important;
  }
  .p-r-10x-max1024 {
    padding-right: 80px !important;
  }
  .m-t-11x-max1024 {
    margin-top: 88px !important;
  }
  .p-t-11x-max1024 {
    padding-top: 88px !important;
  }
  .m-b-11x-max1024 {
    margin-bottom: 88px !important;
  }
  .p-b-11x-max1024 {
    padding-bottom: 88px !important;
  }
  .m-l-11x-max1024 {
    margin-left: 88px !important;
  }
  .p-l-11x-max1024 {
    padding-left: 88px !important;
  }
  .m-r-11x-max1024 {
    margin-right: 88px !important;
  }
  .p-r-11x-max1024 {
    padding-right: 88px !important;
  }
  .m-t-12x-max1024 {
    margin-top: 96px !important;
  }
  .p-t-12x-max1024 {
    padding-top: 96px !important;
  }
  .m-b-12x-max1024 {
    margin-bottom: 96px !important;
  }
  .p-b-12x-max1024 {
    padding-bottom: 96px !important;
  }
  .m-l-12x-max1024 {
    margin-left: 96px !important;
  }
  .p-l-12x-max1024 {
    padding-left: 96px !important;
  }
  .m-r-12x-max1024 {
    margin-right: 96px !important;
  }
  .p-r-12x-max1024 {
    padding-right: 96px !important;
  }
}
@media only screen and (min-width: 1280px) {
  .m-t-1x-min1280 {
    margin-top: 8px !important;
  }
  .p-t-1x-min1280 {
    padding-top: 8px !important;
  }
  .m-b-1x-min1280 {
    margin-bottom: 8px !important;
  }
  .p-b-1x-min1280 {
    padding-bottom: 8px !important;
  }
  .m-l-1x-min1280 {
    margin-left: 8px !important;
  }
  .p-l-1x-min1280 {
    padding-left: 8px !important;
  }
  .m-r-1x-min1280 {
    margin-right: 8px !important;
  }
  .p-r-1x-min1280 {
    padding-right: 8px !important;
  }
  .m-t-2x-min1280 {
    margin-top: 16px !important;
  }
  .p-t-2x-min1280 {
    padding-top: 16px !important;
  }
  .m-b-2x-min1280 {
    margin-bottom: 16px !important;
  }
  .p-b-2x-min1280 {
    padding-bottom: 16px !important;
  }
  .m-l-2x-min1280 {
    margin-left: 16px !important;
  }
  .p-l-2x-min1280 {
    padding-left: 16px !important;
  }
  .m-r-2x-min1280 {
    margin-right: 16px !important;
  }
  .p-r-2x-min1280 {
    padding-right: 16px !important;
  }
  .m-t-3x-min1280 {
    margin-top: 24px !important;
  }
  .p-t-3x-min1280 {
    padding-top: 24px !important;
  }
  .m-b-3x-min1280 {
    margin-bottom: 24px !important;
  }
  .p-b-3x-min1280 {
    padding-bottom: 24px !important;
  }
  .m-l-3x-min1280 {
    margin-left: 24px !important;
  }
  .p-l-3x-min1280 {
    padding-left: 24px !important;
  }
  .m-r-3x-min1280 {
    margin-right: 24px !important;
  }
  .p-r-3x-min1280 {
    padding-right: 24px !important;
  }
  .m-t-4x-min1280 {
    margin-top: 32px !important;
  }
  .p-t-4x-min1280 {
    padding-top: 32px !important;
  }
  .m-b-4x-min1280 {
    margin-bottom: 32px !important;
  }
  .p-b-4x-min1280 {
    padding-bottom: 32px !important;
  }
  .m-l-4x-min1280 {
    margin-left: 32px !important;
  }
  .p-l-4x-min1280 {
    padding-left: 32px !important;
  }
  .m-r-4x-min1280 {
    margin-right: 32px !important;
  }
  .p-r-4x-min1280 {
    padding-right: 32px !important;
  }
  .m-t-5x-min1280 {
    margin-top: 40px !important;
  }
  .p-t-5x-min1280 {
    padding-top: 40px !important;
  }
  .m-b-5x-min1280 {
    margin-bottom: 40px !important;
  }
  .p-b-5x-min1280 {
    padding-bottom: 40px !important;
  }
  .m-l-5x-min1280 {
    margin-left: 40px !important;
  }
  .p-l-5x-min1280 {
    padding-left: 40px !important;
  }
  .m-r-5x-min1280 {
    margin-right: 40px !important;
  }
  .p-r-5x-min1280 {
    padding-right: 40px !important;
  }
  .m-t-6x-min1280 {
    margin-top: 48px !important;
  }
  .p-t-6x-min1280 {
    padding-top: 48px !important;
  }
  .m-b-6x-min1280 {
    margin-bottom: 48px !important;
  }
  .p-b-6x-min1280 {
    padding-bottom: 48px !important;
  }
  .m-l-6x-min1280 {
    margin-left: 48px !important;
  }
  .p-l-6x-min1280 {
    padding-left: 48px !important;
  }
  .m-r-6x-min1280 {
    margin-right: 48px !important;
  }
  .p-r-6x-min1280 {
    padding-right: 48px !important;
  }
  .m-t-7x-min1280 {
    margin-top: 56px !important;
  }
  .p-t-7x-min1280 {
    padding-top: 56px !important;
  }
  .m-b-7x-min1280 {
    margin-bottom: 56px !important;
  }
  .p-b-7x-min1280 {
    padding-bottom: 56px !important;
  }
  .m-l-7x-min1280 {
    margin-left: 56px !important;
  }
  .p-l-7x-min1280 {
    padding-left: 56px !important;
  }
  .m-r-7x-min1280 {
    margin-right: 56px !important;
  }
  .p-r-7x-min1280 {
    padding-right: 56px !important;
  }
  .m-t-8x-min1280 {
    margin-top: 64px !important;
  }
  .p-t-8x-min1280 {
    padding-top: 64px !important;
  }
  .m-b-8x-min1280 {
    margin-bottom: 64px !important;
  }
  .p-b-8x-min1280 {
    padding-bottom: 64px !important;
  }
  .m-l-8x-min1280 {
    margin-left: 64px !important;
  }
  .p-l-8x-min1280 {
    padding-left: 64px !important;
  }
  .m-r-8x-min1280 {
    margin-right: 64px !important;
  }
  .p-r-8x-min1280 {
    padding-right: 64px !important;
  }
  .m-t-9x-min1280 {
    margin-top: 72px !important;
  }
  .p-t-9x-min1280 {
    padding-top: 72px !important;
  }
  .m-b-9x-min1280 {
    margin-bottom: 72px !important;
  }
  .p-b-9x-min1280 {
    padding-bottom: 72px !important;
  }
  .m-l-9x-min1280 {
    margin-left: 72px !important;
  }
  .p-l-9x-min1280 {
    padding-left: 72px !important;
  }
  .m-r-9x-min1280 {
    margin-right: 72px !important;
  }
  .p-r-9x-min1280 {
    padding-right: 72px !important;
  }
  .m-t-10x-min1280 {
    margin-top: 80px !important;
  }
  .p-t-10x-min1280 {
    padding-top: 80px !important;
  }
  .m-b-10x-min1280 {
    margin-bottom: 80px !important;
  }
  .p-b-10x-min1280 {
    padding-bottom: 80px !important;
  }
  .m-l-10x-min1280 {
    margin-left: 80px !important;
  }
  .p-l-10x-min1280 {
    padding-left: 80px !important;
  }
  .m-r-10x-min1280 {
    margin-right: 80px !important;
  }
  .p-r-10x-min1280 {
    padding-right: 80px !important;
  }
  .m-t-11x-min1280 {
    margin-top: 88px !important;
  }
  .p-t-11x-min1280 {
    padding-top: 88px !important;
  }
  .m-b-11x-min1280 {
    margin-bottom: 88px !important;
  }
  .p-b-11x-min1280 {
    padding-bottom: 88px !important;
  }
  .m-l-11x-min1280 {
    margin-left: 88px !important;
  }
  .p-l-11x-min1280 {
    padding-left: 88px !important;
  }
  .m-r-11x-min1280 {
    margin-right: 88px !important;
  }
  .p-r-11x-min1280 {
    padding-right: 88px !important;
  }
  .m-t-12x-min1280 {
    margin-top: 96px !important;
  }
  .p-t-12x-min1280 {
    padding-top: 96px !important;
  }
  .m-b-12x-min1280 {
    margin-bottom: 96px !important;
  }
  .p-b-12x-min1280 {
    padding-bottom: 96px !important;
  }
  .m-l-12x-min1280 {
    margin-left: 96px !important;
  }
  .p-l-12x-min1280 {
    padding-left: 96px !important;
  }
  .m-r-12x-min1280 {
    margin-right: 96px !important;
  }
  .p-r-12x-min1280 {
    padding-right: 96px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .m-t-1x-max1280 {
    margin-top: 8px !important;
  }
  .p-t-1x-max1280 {
    padding-top: 8px !important;
  }
  .m-b-1x-max1280 {
    margin-bottom: 8px !important;
  }
  .p-b-1x-max1280 {
    padding-bottom: 8px !important;
  }
  .m-l-1x-max1280 {
    margin-left: 8px !important;
  }
  .p-l-1x-max1280 {
    padding-left: 8px !important;
  }
  .m-r-1x-max1280 {
    margin-right: 8px !important;
  }
  .p-r-1x-max1280 {
    padding-right: 8px !important;
  }
  .m-t-2x-max1280 {
    margin-top: 16px !important;
  }
  .p-t-2x-max1280 {
    padding-top: 16px !important;
  }
  .m-b-2x-max1280 {
    margin-bottom: 16px !important;
  }
  .p-b-2x-max1280 {
    padding-bottom: 16px !important;
  }
  .m-l-2x-max1280 {
    margin-left: 16px !important;
  }
  .p-l-2x-max1280 {
    padding-left: 16px !important;
  }
  .m-r-2x-max1280 {
    margin-right: 16px !important;
  }
  .p-r-2x-max1280 {
    padding-right: 16px !important;
  }
  .m-t-3x-max1280 {
    margin-top: 24px !important;
  }
  .p-t-3x-max1280 {
    padding-top: 24px !important;
  }
  .m-b-3x-max1280 {
    margin-bottom: 24px !important;
  }
  .p-b-3x-max1280 {
    padding-bottom: 24px !important;
  }
  .m-l-3x-max1280 {
    margin-left: 24px !important;
  }
  .p-l-3x-max1280 {
    padding-left: 24px !important;
  }
  .m-r-3x-max1280 {
    margin-right: 24px !important;
  }
  .p-r-3x-max1280 {
    padding-right: 24px !important;
  }
  .m-t-4x-max1280 {
    margin-top: 32px !important;
  }
  .p-t-4x-max1280 {
    padding-top: 32px !important;
  }
  .m-b-4x-max1280 {
    margin-bottom: 32px !important;
  }
  .p-b-4x-max1280 {
    padding-bottom: 32px !important;
  }
  .m-l-4x-max1280 {
    margin-left: 32px !important;
  }
  .p-l-4x-max1280 {
    padding-left: 32px !important;
  }
  .m-r-4x-max1280 {
    margin-right: 32px !important;
  }
  .p-r-4x-max1280 {
    padding-right: 32px !important;
  }
  .m-t-5x-max1280 {
    margin-top: 40px !important;
  }
  .p-t-5x-max1280 {
    padding-top: 40px !important;
  }
  .m-b-5x-max1280 {
    margin-bottom: 40px !important;
  }
  .p-b-5x-max1280 {
    padding-bottom: 40px !important;
  }
  .m-l-5x-max1280 {
    margin-left: 40px !important;
  }
  .p-l-5x-max1280 {
    padding-left: 40px !important;
  }
  .m-r-5x-max1280 {
    margin-right: 40px !important;
  }
  .p-r-5x-max1280 {
    padding-right: 40px !important;
  }
  .m-t-6x-max1280 {
    margin-top: 48px !important;
  }
  .p-t-6x-max1280 {
    padding-top: 48px !important;
  }
  .m-b-6x-max1280 {
    margin-bottom: 48px !important;
  }
  .p-b-6x-max1280 {
    padding-bottom: 48px !important;
  }
  .m-l-6x-max1280 {
    margin-left: 48px !important;
  }
  .p-l-6x-max1280 {
    padding-left: 48px !important;
  }
  .m-r-6x-max1280 {
    margin-right: 48px !important;
  }
  .p-r-6x-max1280 {
    padding-right: 48px !important;
  }
  .m-t-7x-max1280 {
    margin-top: 56px !important;
  }
  .p-t-7x-max1280 {
    padding-top: 56px !important;
  }
  .m-b-7x-max1280 {
    margin-bottom: 56px !important;
  }
  .p-b-7x-max1280 {
    padding-bottom: 56px !important;
  }
  .m-l-7x-max1280 {
    margin-left: 56px !important;
  }
  .p-l-7x-max1280 {
    padding-left: 56px !important;
  }
  .m-r-7x-max1280 {
    margin-right: 56px !important;
  }
  .p-r-7x-max1280 {
    padding-right: 56px !important;
  }
  .m-t-8x-max1280 {
    margin-top: 64px !important;
  }
  .p-t-8x-max1280 {
    padding-top: 64px !important;
  }
  .m-b-8x-max1280 {
    margin-bottom: 64px !important;
  }
  .p-b-8x-max1280 {
    padding-bottom: 64px !important;
  }
  .m-l-8x-max1280 {
    margin-left: 64px !important;
  }
  .p-l-8x-max1280 {
    padding-left: 64px !important;
  }
  .m-r-8x-max1280 {
    margin-right: 64px !important;
  }
  .p-r-8x-max1280 {
    padding-right: 64px !important;
  }
  .m-t-9x-max1280 {
    margin-top: 72px !important;
  }
  .p-t-9x-max1280 {
    padding-top: 72px !important;
  }
  .m-b-9x-max1280 {
    margin-bottom: 72px !important;
  }
  .p-b-9x-max1280 {
    padding-bottom: 72px !important;
  }
  .m-l-9x-max1280 {
    margin-left: 72px !important;
  }
  .p-l-9x-max1280 {
    padding-left: 72px !important;
  }
  .m-r-9x-max1280 {
    margin-right: 72px !important;
  }
  .p-r-9x-max1280 {
    padding-right: 72px !important;
  }
  .m-t-10x-max1280 {
    margin-top: 80px !important;
  }
  .p-t-10x-max1280 {
    padding-top: 80px !important;
  }
  .m-b-10x-max1280 {
    margin-bottom: 80px !important;
  }
  .p-b-10x-max1280 {
    padding-bottom: 80px !important;
  }
  .m-l-10x-max1280 {
    margin-left: 80px !important;
  }
  .p-l-10x-max1280 {
    padding-left: 80px !important;
  }
  .m-r-10x-max1280 {
    margin-right: 80px !important;
  }
  .p-r-10x-max1280 {
    padding-right: 80px !important;
  }
  .m-t-11x-max1280 {
    margin-top: 88px !important;
  }
  .p-t-11x-max1280 {
    padding-top: 88px !important;
  }
  .m-b-11x-max1280 {
    margin-bottom: 88px !important;
  }
  .p-b-11x-max1280 {
    padding-bottom: 88px !important;
  }
  .m-l-11x-max1280 {
    margin-left: 88px !important;
  }
  .p-l-11x-max1280 {
    padding-left: 88px !important;
  }
  .m-r-11x-max1280 {
    margin-right: 88px !important;
  }
  .p-r-11x-max1280 {
    padding-right: 88px !important;
  }
  .m-t-12x-max1280 {
    margin-top: 96px !important;
  }
  .p-t-12x-max1280 {
    padding-top: 96px !important;
  }
  .m-b-12x-max1280 {
    margin-bottom: 96px !important;
  }
  .p-b-12x-max1280 {
    padding-bottom: 96px !important;
  }
  .m-l-12x-max1280 {
    margin-left: 96px !important;
  }
  .p-l-12x-max1280 {
    padding-left: 96px !important;
  }
  .m-r-12x-max1280 {
    margin-right: 96px !important;
  }
  .p-r-12x-max1280 {
    padding-right: 96px !important;
  }
}
/*
.f-15-400 {
  font-size: 15px;
  font-weight: 400;
}

.l-h-24 {
  line-height: 24px;
}

.f-c-gray-main {
  color: $grey-main;
}
*/
.f-10-normal {
  font-size: 10px !important;
  font-weight: normal !important;
}

.f-10-400 {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.f-10-500 {
  font-size: 10px !important;
  font-weight: 500 !important;
}

.f-10-600 {
  font-size: 10px !important;
  font-weight: 600 !important;
}

.f-10-700 {
  font-size: 10px !important;
  font-weight: 700 !important;
}

.f-10-bold {
  font-size: 10px !important;
  font-weight: bold !important;
}

.f-11-normal {
  font-size: 11px !important;
  font-weight: normal !important;
}

.f-11-400 {
  font-size: 11px !important;
  font-weight: 400 !important;
}

.f-11-500 {
  font-size: 11px !important;
  font-weight: 500 !important;
}

.f-11-600 {
  font-size: 11px !important;
  font-weight: 600 !important;
}

.f-11-700 {
  font-size: 11px !important;
  font-weight: 700 !important;
}

.f-11-bold {
  font-size: 11px !important;
  font-weight: bold !important;
}

.f-12-normal {
  font-size: 12px !important;
  font-weight: normal !important;
}

.f-12-400 {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.f-12-500 {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.f-12-600 {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.f-12-700 {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.f-12-bold {
  font-size: 12px !important;
  font-weight: bold !important;
}

.f-13-normal {
  font-size: 13px !important;
  font-weight: normal !important;
}

.f-13-400 {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.f-13-500 {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.f-13-600 {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.f-13-700 {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.f-13-bold {
  font-size: 13px !important;
  font-weight: bold !important;
}

.f-14-normal {
  font-size: 14px !important;
  font-weight: normal !important;
}

.f-14-400 {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.f-14-500 {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.f-14-600 {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.f-14-700 {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.f-14-bold {
  font-size: 14px !important;
  font-weight: bold !important;
}

.f-16-normal {
  font-size: 16px !important;
  font-weight: normal !important;
}

.f-16-400 {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.f-16-500 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.f-16-600 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.f-16-700 {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.f-16-bold {
  font-size: 16px !important;
  font-weight: bold !important;
}

.f-18-normal {
  font-size: 18px !important;
  font-weight: normal !important;
}

.f-18-400 {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.f-18-500 {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.f-18-600 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.f-18-700 {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.f-18-bold {
  font-size: 18px !important;
  font-weight: bold !important;
}

.f-19-normal {
  font-size: 19px !important;
  font-weight: normal !important;
}

.f-19-400 {
  font-size: 19px !important;
  font-weight: 400 !important;
}

.f-19-500 {
  font-size: 19px !important;
  font-weight: 500 !important;
}

.f-19-600 {
  font-size: 19px !important;
  font-weight: 600 !important;
}

.f-19-700 {
  font-size: 19px !important;
  font-weight: 700 !important;
}

.f-19-bold {
  font-size: 19px !important;
  font-weight: bold !important;
}

.f-20-normal {
  font-size: 20px !important;
  font-weight: normal !important;
}

.f-20-400 {
  font-size: 20px !important;
  font-weight: 400 !important;
}

.f-20-500 {
  font-size: 20px !important;
  font-weight: 500 !important;
}

.f-20-600 {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.f-20-700 {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.f-20-bold {
  font-size: 20px !important;
  font-weight: bold !important;
}

.f-24-normal {
  font-size: 24px !important;
  font-weight: normal !important;
}

.f-24-400 {
  font-size: 24px !important;
  font-weight: 400 !important;
}

.f-24-500 {
  font-size: 24px !important;
  font-weight: 500 !important;
}

.f-24-600 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.f-24-700 {
  font-size: 24px !important;
  font-weight: 700 !important;
}

.f-24-bold {
  font-size: 24px !important;
  font-weight: bold !important;
}

.f-26-normal {
  font-size: 26px !important;
  font-weight: normal !important;
}

.f-26-400 {
  font-size: 26px !important;
  font-weight: 400 !important;
}

.f-26-500 {
  font-size: 26px !important;
  font-weight: 500 !important;
}

.f-26-600 {
  font-size: 26px !important;
  font-weight: 600 !important;
}

.f-26-700 {
  font-size: 26px !important;
  font-weight: 700 !important;
}

.f-26-bold {
  font-size: 26px !important;
  font-weight: bold !important;
}

.f-32-normal {
  font-size: 32px !important;
  font-weight: normal !important;
}

.f-32-400 {
  font-size: 32px !important;
  font-weight: 400 !important;
}

.f-32-500 {
  font-size: 32px !important;
  font-weight: 500 !important;
}

.f-32-600 {
  font-size: 32px !important;
  font-weight: 600 !important;
}

.f-32-700 {
  font-size: 32px !important;
  font-weight: 700 !important;
}

.f-32-bold {
  font-size: 32px !important;
  font-weight: bold !important;
}

.f-34-normal {
  font-size: 34px !important;
  font-weight: normal !important;
}

.f-34-400 {
  font-size: 34px !important;
  font-weight: 400 !important;
}

.f-34-500 {
  font-size: 34px !important;
  font-weight: 500 !important;
}

.f-34-600 {
  font-size: 34px !important;
  font-weight: 600 !important;
}

.f-34-700 {
  font-size: 34px !important;
  font-weight: 700 !important;
}

.f-34-bold {
  font-size: 34px !important;
  font-weight: bold !important;
}

.f-40-normal {
  font-size: 40px !important;
  font-weight: normal !important;
}

.f-40-400 {
  font-size: 40px !important;
  font-weight: 400 !important;
}

.f-40-500 {
  font-size: 40px !important;
  font-weight: 500 !important;
}

.f-40-600 {
  font-size: 40px !important;
  font-weight: 600 !important;
}

.f-40-700 {
  font-size: 40px !important;
  font-weight: 700 !important;
}

.f-40-bold {
  font-size: 40px !important;
  font-weight: bold !important;
}

.f-64-normal {
  font-size: 64px !important;
  font-weight: normal !important;
}

.f-64-400 {
  font-size: 64px !important;
  font-weight: 400 !important;
}

.f-64-500 {
  font-size: 64px !important;
  font-weight: 500 !important;
}

.f-64-600 {
  font-size: 64px !important;
  font-weight: 600 !important;
}

.f-64-700 {
  font-size: 64px !important;
  font-weight: 700 !important;
}

.f-64-bold {
  font-size: 64px !important;
  font-weight: bold !important;
}

.l-h-14 {
  line-height: 14px !important;
}

.l-h-16 {
  line-height: 16px !important;
}

.l-h-18 {
  line-height: 18px !important;
}

.l-h-22 {
  line-height: 22px !important;
}

.l-h-24 {
  line-height: 24px !important;
}

.l-h-30 {
  line-height: 30px !important;
}

.l-h-32 {
  line-height: 32px !important;
}

.l-h-36 {
  line-height: 36px !important;
}

.l-h-40 {
  line-height: 40px !important;
}

.l-h-44 {
  line-height: 44px !important;
}

.l-h-48 {
  line-height: 48px !important;
}

.f-c-azure {
  color: #0babff !important;
}

.f-c-blue-main {
  color: #0389ff !important;
}

.f-c-blue-grey {
  color: #5f7389 !important;
}

.f-c-blue-grey-two {
  color: #7f93a9 !important;
}

.f-c-blue-grey-three {
  color: #98a5b3 !important;
}

.f-c-blue-grey-four {
  color: #94a5b8 !important;
}

.f-c-charcoal-grey {
  color: #323c47 !important;
}

.f-c-cloudy-blue {
  color: #b8c2cc !important;
}

.f-c-gray-main {
  color: #333940 !important;
}

.f-c-grey-blue {
  color: #8194aa !important;
}

.f-c-light-blue-grey {
  color: #d5dce6 !important;
}

.f-c-slate {
  color: #536880 !important;
}

.f-c-steel-light {
  color: #9cacbe !important;
}

.f-c-steel {
  color: #78899c !important;
}

.f-c-vermillion {
  color: #ff1010 !important;
}

.f-c-white {
  color: #fff !important;
}

.f-c-orange {
  color: #cf7c1d !important;
}

.f-c-pale-sky-blue {
  color: #dae1e6 !important;
}

.f-c-leafy-green {
  color: #0AA765 !important;
}

.f-c-red {
  color: #ff3d55 !important;
}

@media only screen and (min-width: 640px) {
  .f-10-normal-min-640 {
    font-size: 10px !important;
    font-weight: normal !important;
  }
  .f-10-400-min-640 {
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .f-10-500-min-640 {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .f-10-600-min-640 {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .f-10-700-min-640 {
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  .f-10-bold-min-640 {
    font-size: 10px !important;
    font-weight: bold !important;
  }
  .f-11-normal-min-640 {
    font-size: 11px !important;
    font-weight: normal !important;
  }
  .f-11-400-min-640 {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
  .f-11-500-min-640 {
    font-size: 11px !important;
    font-weight: 500 !important;
  }
  .f-11-600-min-640 {
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  .f-11-700-min-640 {
    font-size: 11px !important;
    font-weight: 700 !important;
  }
  .f-11-bold-min-640 {
    font-size: 11px !important;
    font-weight: bold !important;
  }
  .f-12-normal-min-640 {
    font-size: 12px !important;
    font-weight: normal !important;
  }
  .f-12-400-min-640 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .f-12-500-min-640 {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .f-12-600-min-640 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .f-12-700-min-640 {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  .f-12-bold-min-640 {
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .f-13-normal-min-640 {
    font-size: 13px !important;
    font-weight: normal !important;
  }
  .f-13-400-min-640 {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .f-13-500-min-640 {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .f-13-600-min-640 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  .f-13-700-min-640 {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
  .f-13-bold-min-640 {
    font-size: 13px !important;
    font-weight: bold !important;
  }
  .f-14-normal-min-640 {
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .f-14-400-min-640 {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .f-14-500-min-640 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .f-14-600-min-640 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .f-14-700-min-640 {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .f-14-bold-min-640 {
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .f-16-normal-min-640 {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .f-16-400-min-640 {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .f-16-500-min-640 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .f-16-600-min-640 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .f-16-700-min-640 {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .f-16-bold-min-640 {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .f-18-normal-min-640 {
    font-size: 18px !important;
    font-weight: normal !important;
  }
  .f-18-400-min-640 {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .f-18-500-min-640 {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .f-18-600-min-640 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .f-18-700-min-640 {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .f-18-bold-min-640 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .f-19-normal-min-640 {
    font-size: 19px !important;
    font-weight: normal !important;
  }
  .f-19-400-min-640 {
    font-size: 19px !important;
    font-weight: 400 !important;
  }
  .f-19-500-min-640 {
    font-size: 19px !important;
    font-weight: 500 !important;
  }
  .f-19-600-min-640 {
    font-size: 19px !important;
    font-weight: 600 !important;
  }
  .f-19-700-min-640 {
    font-size: 19px !important;
    font-weight: 700 !important;
  }
  .f-19-bold-min-640 {
    font-size: 19px !important;
    font-weight: bold !important;
  }
  .f-20-normal-min-640 {
    font-size: 20px !important;
    font-weight: normal !important;
  }
  .f-20-400-min-640 {
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  .f-20-500-min-640 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .f-20-600-min-640 {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .f-20-700-min-640 {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .f-20-bold-min-640 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .f-24-normal-min-640 {
    font-size: 24px !important;
    font-weight: normal !important;
  }
  .f-24-400-min-640 {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
  .f-24-500-min-640 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .f-24-600-min-640 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .f-24-700-min-640 {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
  .f-24-bold-min-640 {
    font-size: 24px !important;
    font-weight: bold !important;
  }
  .f-26-normal-min-640 {
    font-size: 26px !important;
    font-weight: normal !important;
  }
  .f-26-400-min-640 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }
  .f-26-500-min-640 {
    font-size: 26px !important;
    font-weight: 500 !important;
  }
  .f-26-600-min-640 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
  .f-26-700-min-640 {
    font-size: 26px !important;
    font-weight: 700 !important;
  }
  .f-26-bold-min-640 {
    font-size: 26px !important;
    font-weight: bold !important;
  }
  .f-32-normal-min-640 {
    font-size: 32px !important;
    font-weight: normal !important;
  }
  .f-32-400-min-640 {
    font-size: 32px !important;
    font-weight: 400 !important;
  }
  .f-32-500-min-640 {
    font-size: 32px !important;
    font-weight: 500 !important;
  }
  .f-32-600-min-640 {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .f-32-700-min-640 {
    font-size: 32px !important;
    font-weight: 700 !important;
  }
  .f-32-bold-min-640 {
    font-size: 32px !important;
    font-weight: bold !important;
  }
  .f-34-normal-min-640 {
    font-size: 34px !important;
    font-weight: normal !important;
  }
  .f-34-400-min-640 {
    font-size: 34px !important;
    font-weight: 400 !important;
  }
  .f-34-500-min-640 {
    font-size: 34px !important;
    font-weight: 500 !important;
  }
  .f-34-600-min-640 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }
  .f-34-700-min-640 {
    font-size: 34px !important;
    font-weight: 700 !important;
  }
  .f-34-bold-min-640 {
    font-size: 34px !important;
    font-weight: bold !important;
  }
  .f-40-normal-min-640 {
    font-size: 40px !important;
    font-weight: normal !important;
  }
  .f-40-400-min-640 {
    font-size: 40px !important;
    font-weight: 400 !important;
  }
  .f-40-500-min-640 {
    font-size: 40px !important;
    font-weight: 500 !important;
  }
  .f-40-600-min-640 {
    font-size: 40px !important;
    font-weight: 600 !important;
  }
  .f-40-700-min-640 {
    font-size: 40px !important;
    font-weight: 700 !important;
  }
  .f-40-bold-min-640 {
    font-size: 40px !important;
    font-weight: bold !important;
  }
  .f-64-normal-min-640 {
    font-size: 64px !important;
    font-weight: normal !important;
  }
  .f-64-400-min-640 {
    font-size: 64px !important;
    font-weight: 400 !important;
  }
  .f-64-500-min-640 {
    font-size: 64px !important;
    font-weight: 500 !important;
  }
  .f-64-600-min-640 {
    font-size: 64px !important;
    font-weight: 600 !important;
  }
  .f-64-700-min-640 {
    font-size: 64px !important;
    font-weight: 700 !important;
  }
  .f-64-bold-min-640 {
    font-size: 64px !important;
    font-weight: bold !important;
  }
}
@media only screen and (max-width: 640px) {
  .f-10-normal-max-640 {
    font-size: 10px !important;
    font-weight: normal !important;
  }
  .f-10-400-max-640 {
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .f-10-500-max-640 {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .f-10-600-max-640 {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .f-10-700-max-640 {
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  .f-10-bold-max-640 {
    font-size: 10px !important;
    font-weight: bold !important;
  }
  .f-11-normal-max-640 {
    font-size: 11px !important;
    font-weight: normal !important;
  }
  .f-11-400-max-640 {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
  .f-11-500-max-640 {
    font-size: 11px !important;
    font-weight: 500 !important;
  }
  .f-11-600-max-640 {
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  .f-11-700-max-640 {
    font-size: 11px !important;
    font-weight: 700 !important;
  }
  .f-11-bold-max-640 {
    font-size: 11px !important;
    font-weight: bold !important;
  }
  .f-12-normal-max-640 {
    font-size: 12px !important;
    font-weight: normal !important;
  }
  .f-12-400-max-640 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .f-12-500-max-640 {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .f-12-600-max-640 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .f-12-700-max-640 {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  .f-12-bold-max-640 {
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .f-13-normal-max-640 {
    font-size: 13px !important;
    font-weight: normal !important;
  }
  .f-13-400-max-640 {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .f-13-500-max-640 {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .f-13-600-max-640 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  .f-13-700-max-640 {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
  .f-13-bold-max-640 {
    font-size: 13px !important;
    font-weight: bold !important;
  }
  .f-14-normal-max-640 {
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .f-14-400-max-640 {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .f-14-500-max-640 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .f-14-600-max-640 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .f-14-700-max-640 {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .f-14-bold-max-640 {
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .f-16-normal-max-640 {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .f-16-400-max-640 {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .f-16-500-max-640 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .f-16-600-max-640 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .f-16-700-max-640 {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .f-16-bold-max-640 {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .f-18-normal-max-640 {
    font-size: 18px !important;
    font-weight: normal !important;
  }
  .f-18-400-max-640 {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .f-18-500-max-640 {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .f-18-600-max-640 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .f-18-700-max-640 {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .f-18-bold-max-640 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .f-19-normal-max-640 {
    font-size: 19px !important;
    font-weight: normal !important;
  }
  .f-19-400-max-640 {
    font-size: 19px !important;
    font-weight: 400 !important;
  }
  .f-19-500-max-640 {
    font-size: 19px !important;
    font-weight: 500 !important;
  }
  .f-19-600-max-640 {
    font-size: 19px !important;
    font-weight: 600 !important;
  }
  .f-19-700-max-640 {
    font-size: 19px !important;
    font-weight: 700 !important;
  }
  .f-19-bold-max-640 {
    font-size: 19px !important;
    font-weight: bold !important;
  }
  .f-20-normal-max-640 {
    font-size: 20px !important;
    font-weight: normal !important;
  }
  .f-20-400-max-640 {
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  .f-20-500-max-640 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .f-20-600-max-640 {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .f-20-700-max-640 {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .f-20-bold-max-640 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .f-24-normal-max-640 {
    font-size: 24px !important;
    font-weight: normal !important;
  }
  .f-24-400-max-640 {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
  .f-24-500-max-640 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .f-24-600-max-640 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .f-24-700-max-640 {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
  .f-24-bold-max-640 {
    font-size: 24px !important;
    font-weight: bold !important;
  }
  .f-26-normal-max-640 {
    font-size: 26px !important;
    font-weight: normal !important;
  }
  .f-26-400-max-640 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }
  .f-26-500-max-640 {
    font-size: 26px !important;
    font-weight: 500 !important;
  }
  .f-26-600-max-640 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
  .f-26-700-max-640 {
    font-size: 26px !important;
    font-weight: 700 !important;
  }
  .f-26-bold-max-640 {
    font-size: 26px !important;
    font-weight: bold !important;
  }
  .f-32-normal-max-640 {
    font-size: 32px !important;
    font-weight: normal !important;
  }
  .f-32-400-max-640 {
    font-size: 32px !important;
    font-weight: 400 !important;
  }
  .f-32-500-max-640 {
    font-size: 32px !important;
    font-weight: 500 !important;
  }
  .f-32-600-max-640 {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .f-32-700-max-640 {
    font-size: 32px !important;
    font-weight: 700 !important;
  }
  .f-32-bold-max-640 {
    font-size: 32px !important;
    font-weight: bold !important;
  }
  .f-34-normal-max-640 {
    font-size: 34px !important;
    font-weight: normal !important;
  }
  .f-34-400-max-640 {
    font-size: 34px !important;
    font-weight: 400 !important;
  }
  .f-34-500-max-640 {
    font-size: 34px !important;
    font-weight: 500 !important;
  }
  .f-34-600-max-640 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }
  .f-34-700-max-640 {
    font-size: 34px !important;
    font-weight: 700 !important;
  }
  .f-34-bold-max-640 {
    font-size: 34px !important;
    font-weight: bold !important;
  }
  .f-40-normal-max-640 {
    font-size: 40px !important;
    font-weight: normal !important;
  }
  .f-40-400-max-640 {
    font-size: 40px !important;
    font-weight: 400 !important;
  }
  .f-40-500-max-640 {
    font-size: 40px !important;
    font-weight: 500 !important;
  }
  .f-40-600-max-640 {
    font-size: 40px !important;
    font-weight: 600 !important;
  }
  .f-40-700-max-640 {
    font-size: 40px !important;
    font-weight: 700 !important;
  }
  .f-40-bold-max-640 {
    font-size: 40px !important;
    font-weight: bold !important;
  }
  .f-64-normal-max-640 {
    font-size: 64px !important;
    font-weight: normal !important;
  }
  .f-64-400-max-640 {
    font-size: 64px !important;
    font-weight: 400 !important;
  }
  .f-64-500-max-640 {
    font-size: 64px !important;
    font-weight: 500 !important;
  }
  .f-64-600-max-640 {
    font-size: 64px !important;
    font-weight: 600 !important;
  }
  .f-64-700-max-640 {
    font-size: 64px !important;
    font-weight: 700 !important;
  }
  .f-64-bold-max-640 {
    font-size: 64px !important;
    font-weight: bold !important;
  }
}
@media only screen and (min-width: 768px) {
  .f-10-normal-min-768 {
    font-size: 10px !important;
    font-weight: normal !important;
  }
  .f-10-400-min-768 {
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .f-10-500-min-768 {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .f-10-600-min-768 {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .f-10-700-min-768 {
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  .f-10-bold-min-768 {
    font-size: 10px !important;
    font-weight: bold !important;
  }
  .f-11-normal-min-768 {
    font-size: 11px !important;
    font-weight: normal !important;
  }
  .f-11-400-min-768 {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
  .f-11-500-min-768 {
    font-size: 11px !important;
    font-weight: 500 !important;
  }
  .f-11-600-min-768 {
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  .f-11-700-min-768 {
    font-size: 11px !important;
    font-weight: 700 !important;
  }
  .f-11-bold-min-768 {
    font-size: 11px !important;
    font-weight: bold !important;
  }
  .f-12-normal-min-768 {
    font-size: 12px !important;
    font-weight: normal !important;
  }
  .f-12-400-min-768 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .f-12-500-min-768 {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .f-12-600-min-768 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .f-12-700-min-768 {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  .f-12-bold-min-768 {
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .f-13-normal-min-768 {
    font-size: 13px !important;
    font-weight: normal !important;
  }
  .f-13-400-min-768 {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .f-13-500-min-768 {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .f-13-600-min-768 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  .f-13-700-min-768 {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
  .f-13-bold-min-768 {
    font-size: 13px !important;
    font-weight: bold !important;
  }
  .f-14-normal-min-768 {
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .f-14-400-min-768 {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .f-14-500-min-768 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .f-14-600-min-768 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .f-14-700-min-768 {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .f-14-bold-min-768 {
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .f-16-normal-min-768 {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .f-16-400-min-768 {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .f-16-500-min-768 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .f-16-600-min-768 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .f-16-700-min-768 {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .f-16-bold-min-768 {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .f-18-normal-min-768 {
    font-size: 18px !important;
    font-weight: normal !important;
  }
  .f-18-400-min-768 {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .f-18-500-min-768 {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .f-18-600-min-768 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .f-18-700-min-768 {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .f-18-bold-min-768 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .f-19-normal-min-768 {
    font-size: 19px !important;
    font-weight: normal !important;
  }
  .f-19-400-min-768 {
    font-size: 19px !important;
    font-weight: 400 !important;
  }
  .f-19-500-min-768 {
    font-size: 19px !important;
    font-weight: 500 !important;
  }
  .f-19-600-min-768 {
    font-size: 19px !important;
    font-weight: 600 !important;
  }
  .f-19-700-min-768 {
    font-size: 19px !important;
    font-weight: 700 !important;
  }
  .f-19-bold-min-768 {
    font-size: 19px !important;
    font-weight: bold !important;
  }
  .f-20-normal-min-768 {
    font-size: 20px !important;
    font-weight: normal !important;
  }
  .f-20-400-min-768 {
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  .f-20-500-min-768 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .f-20-600-min-768 {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .f-20-700-min-768 {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .f-20-bold-min-768 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .f-24-normal-min-768 {
    font-size: 24px !important;
    font-weight: normal !important;
  }
  .f-24-400-min-768 {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
  .f-24-500-min-768 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .f-24-600-min-768 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .f-24-700-min-768 {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
  .f-24-bold-min-768 {
    font-size: 24px !important;
    font-weight: bold !important;
  }
  .f-26-normal-min-768 {
    font-size: 26px !important;
    font-weight: normal !important;
  }
  .f-26-400-min-768 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }
  .f-26-500-min-768 {
    font-size: 26px !important;
    font-weight: 500 !important;
  }
  .f-26-600-min-768 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
  .f-26-700-min-768 {
    font-size: 26px !important;
    font-weight: 700 !important;
  }
  .f-26-bold-min-768 {
    font-size: 26px !important;
    font-weight: bold !important;
  }
  .f-32-normal-min-768 {
    font-size: 32px !important;
    font-weight: normal !important;
  }
  .f-32-400-min-768 {
    font-size: 32px !important;
    font-weight: 400 !important;
  }
  .f-32-500-min-768 {
    font-size: 32px !important;
    font-weight: 500 !important;
  }
  .f-32-600-min-768 {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .f-32-700-min-768 {
    font-size: 32px !important;
    font-weight: 700 !important;
  }
  .f-32-bold-min-768 {
    font-size: 32px !important;
    font-weight: bold !important;
  }
  .f-34-normal-min-768 {
    font-size: 34px !important;
    font-weight: normal !important;
  }
  .f-34-400-min-768 {
    font-size: 34px !important;
    font-weight: 400 !important;
  }
  .f-34-500-min-768 {
    font-size: 34px !important;
    font-weight: 500 !important;
  }
  .f-34-600-min-768 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }
  .f-34-700-min-768 {
    font-size: 34px !important;
    font-weight: 700 !important;
  }
  .f-34-bold-min-768 {
    font-size: 34px !important;
    font-weight: bold !important;
  }
  .f-40-normal-min-768 {
    font-size: 40px !important;
    font-weight: normal !important;
  }
  .f-40-400-min-768 {
    font-size: 40px !important;
    font-weight: 400 !important;
  }
  .f-40-500-min-768 {
    font-size: 40px !important;
    font-weight: 500 !important;
  }
  .f-40-600-min-768 {
    font-size: 40px !important;
    font-weight: 600 !important;
  }
  .f-40-700-min-768 {
    font-size: 40px !important;
    font-weight: 700 !important;
  }
  .f-40-bold-min-768 {
    font-size: 40px !important;
    font-weight: bold !important;
  }
  .f-64-normal-min-768 {
    font-size: 64px !important;
    font-weight: normal !important;
  }
  .f-64-400-min-768 {
    font-size: 64px !important;
    font-weight: 400 !important;
  }
  .f-64-500-min-768 {
    font-size: 64px !important;
    font-weight: 500 !important;
  }
  .f-64-600-min-768 {
    font-size: 64px !important;
    font-weight: 600 !important;
  }
  .f-64-700-min-768 {
    font-size: 64px !important;
    font-weight: 700 !important;
  }
  .f-64-bold-min-768 {
    font-size: 64px !important;
    font-weight: bold !important;
  }
}
@media only screen and (max-width: 768px) {
  .f-10-normal-max-768 {
    font-size: 10px !important;
    font-weight: normal !important;
  }
  .f-10-400-max-768 {
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .f-10-500-max-768 {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .f-10-600-max-768 {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .f-10-700-max-768 {
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  .f-10-bold-max-768 {
    font-size: 10px !important;
    font-weight: bold !important;
  }
  .f-11-normal-max-768 {
    font-size: 11px !important;
    font-weight: normal !important;
  }
  .f-11-400-max-768 {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
  .f-11-500-max-768 {
    font-size: 11px !important;
    font-weight: 500 !important;
  }
  .f-11-600-max-768 {
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  .f-11-700-max-768 {
    font-size: 11px !important;
    font-weight: 700 !important;
  }
  .f-11-bold-max-768 {
    font-size: 11px !important;
    font-weight: bold !important;
  }
  .f-12-normal-max-768 {
    font-size: 12px !important;
    font-weight: normal !important;
  }
  .f-12-400-max-768 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .f-12-500-max-768 {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .f-12-600-max-768 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .f-12-700-max-768 {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  .f-12-bold-max-768 {
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .f-13-normal-max-768 {
    font-size: 13px !important;
    font-weight: normal !important;
  }
  .f-13-400-max-768 {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .f-13-500-max-768 {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .f-13-600-max-768 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  .f-13-700-max-768 {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
  .f-13-bold-max-768 {
    font-size: 13px !important;
    font-weight: bold !important;
  }
  .f-14-normal-max-768 {
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .f-14-400-max-768 {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .f-14-500-max-768 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .f-14-600-max-768 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .f-14-700-max-768 {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .f-14-bold-max-768 {
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .f-16-normal-max-768 {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .f-16-400-max-768 {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .f-16-500-max-768 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .f-16-600-max-768 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .f-16-700-max-768 {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .f-16-bold-max-768 {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .f-18-normal-max-768 {
    font-size: 18px !important;
    font-weight: normal !important;
  }
  .f-18-400-max-768 {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .f-18-500-max-768 {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .f-18-600-max-768 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .f-18-700-max-768 {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .f-18-bold-max-768 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .f-19-normal-max-768 {
    font-size: 19px !important;
    font-weight: normal !important;
  }
  .f-19-400-max-768 {
    font-size: 19px !important;
    font-weight: 400 !important;
  }
  .f-19-500-max-768 {
    font-size: 19px !important;
    font-weight: 500 !important;
  }
  .f-19-600-max-768 {
    font-size: 19px !important;
    font-weight: 600 !important;
  }
  .f-19-700-max-768 {
    font-size: 19px !important;
    font-weight: 700 !important;
  }
  .f-19-bold-max-768 {
    font-size: 19px !important;
    font-weight: bold !important;
  }
  .f-20-normal-max-768 {
    font-size: 20px !important;
    font-weight: normal !important;
  }
  .f-20-400-max-768 {
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  .f-20-500-max-768 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .f-20-600-max-768 {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .f-20-700-max-768 {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .f-20-bold-max-768 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .f-24-normal-max-768 {
    font-size: 24px !important;
    font-weight: normal !important;
  }
  .f-24-400-max-768 {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
  .f-24-500-max-768 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .f-24-600-max-768 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .f-24-700-max-768 {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
  .f-24-bold-max-768 {
    font-size: 24px !important;
    font-weight: bold !important;
  }
  .f-26-normal-max-768 {
    font-size: 26px !important;
    font-weight: normal !important;
  }
  .f-26-400-max-768 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }
  .f-26-500-max-768 {
    font-size: 26px !important;
    font-weight: 500 !important;
  }
  .f-26-600-max-768 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
  .f-26-700-max-768 {
    font-size: 26px !important;
    font-weight: 700 !important;
  }
  .f-26-bold-max-768 {
    font-size: 26px !important;
    font-weight: bold !important;
  }
  .f-32-normal-max-768 {
    font-size: 32px !important;
    font-weight: normal !important;
  }
  .f-32-400-max-768 {
    font-size: 32px !important;
    font-weight: 400 !important;
  }
  .f-32-500-max-768 {
    font-size: 32px !important;
    font-weight: 500 !important;
  }
  .f-32-600-max-768 {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .f-32-700-max-768 {
    font-size: 32px !important;
    font-weight: 700 !important;
  }
  .f-32-bold-max-768 {
    font-size: 32px !important;
    font-weight: bold !important;
  }
  .f-34-normal-max-768 {
    font-size: 34px !important;
    font-weight: normal !important;
  }
  .f-34-400-max-768 {
    font-size: 34px !important;
    font-weight: 400 !important;
  }
  .f-34-500-max-768 {
    font-size: 34px !important;
    font-weight: 500 !important;
  }
  .f-34-600-max-768 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }
  .f-34-700-max-768 {
    font-size: 34px !important;
    font-weight: 700 !important;
  }
  .f-34-bold-max-768 {
    font-size: 34px !important;
    font-weight: bold !important;
  }
  .f-40-normal-max-768 {
    font-size: 40px !important;
    font-weight: normal !important;
  }
  .f-40-400-max-768 {
    font-size: 40px !important;
    font-weight: 400 !important;
  }
  .f-40-500-max-768 {
    font-size: 40px !important;
    font-weight: 500 !important;
  }
  .f-40-600-max-768 {
    font-size: 40px !important;
    font-weight: 600 !important;
  }
  .f-40-700-max-768 {
    font-size: 40px !important;
    font-weight: 700 !important;
  }
  .f-40-bold-max-768 {
    font-size: 40px !important;
    font-weight: bold !important;
  }
  .f-64-normal-max-768 {
    font-size: 64px !important;
    font-weight: normal !important;
  }
  .f-64-400-max-768 {
    font-size: 64px !important;
    font-weight: 400 !important;
  }
  .f-64-500-max-768 {
    font-size: 64px !important;
    font-weight: 500 !important;
  }
  .f-64-600-max-768 {
    font-size: 64px !important;
    font-weight: 600 !important;
  }
  .f-64-700-max-768 {
    font-size: 64px !important;
    font-weight: 700 !important;
  }
  .f-64-bold-max-768 {
    font-size: 64px !important;
    font-weight: bold !important;
  }
}
@media only screen and (min-width: 1024px) {
  .f-10-normal-min-1024 {
    font-size: 10px !important;
    font-weight: normal !important;
  }
  .f-10-400-min-1024 {
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .f-10-500-min-1024 {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .f-10-600-min-1024 {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .f-10-700-min-1024 {
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  .f-10-bold-min-1024 {
    font-size: 10px !important;
    font-weight: bold !important;
  }
  .f-11-normal-min-1024 {
    font-size: 11px !important;
    font-weight: normal !important;
  }
  .f-11-400-min-1024 {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
  .f-11-500-min-1024 {
    font-size: 11px !important;
    font-weight: 500 !important;
  }
  .f-11-600-min-1024 {
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  .f-11-700-min-1024 {
    font-size: 11px !important;
    font-weight: 700 !important;
  }
  .f-11-bold-min-1024 {
    font-size: 11px !important;
    font-weight: bold !important;
  }
  .f-12-normal-min-1024 {
    font-size: 12px !important;
    font-weight: normal !important;
  }
  .f-12-400-min-1024 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .f-12-500-min-1024 {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .f-12-600-min-1024 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .f-12-700-min-1024 {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  .f-12-bold-min-1024 {
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .f-13-normal-min-1024 {
    font-size: 13px !important;
    font-weight: normal !important;
  }
  .f-13-400-min-1024 {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .f-13-500-min-1024 {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .f-13-600-min-1024 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  .f-13-700-min-1024 {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
  .f-13-bold-min-1024 {
    font-size: 13px !important;
    font-weight: bold !important;
  }
  .f-14-normal-min-1024 {
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .f-14-400-min-1024 {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .f-14-500-min-1024 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .f-14-600-min-1024 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .f-14-700-min-1024 {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .f-14-bold-min-1024 {
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .f-16-normal-min-1024 {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .f-16-400-min-1024 {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .f-16-500-min-1024 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .f-16-600-min-1024 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .f-16-700-min-1024 {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .f-16-bold-min-1024 {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .f-18-normal-min-1024 {
    font-size: 18px !important;
    font-weight: normal !important;
  }
  .f-18-400-min-1024 {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .f-18-500-min-1024 {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .f-18-600-min-1024 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .f-18-700-min-1024 {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .f-18-bold-min-1024 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .f-19-normal-min-1024 {
    font-size: 19px !important;
    font-weight: normal !important;
  }
  .f-19-400-min-1024 {
    font-size: 19px !important;
    font-weight: 400 !important;
  }
  .f-19-500-min-1024 {
    font-size: 19px !important;
    font-weight: 500 !important;
  }
  .f-19-600-min-1024 {
    font-size: 19px !important;
    font-weight: 600 !important;
  }
  .f-19-700-min-1024 {
    font-size: 19px !important;
    font-weight: 700 !important;
  }
  .f-19-bold-min-1024 {
    font-size: 19px !important;
    font-weight: bold !important;
  }
  .f-20-normal-min-1024 {
    font-size: 20px !important;
    font-weight: normal !important;
  }
  .f-20-400-min-1024 {
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  .f-20-500-min-1024 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .f-20-600-min-1024 {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .f-20-700-min-1024 {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .f-20-bold-min-1024 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .f-24-normal-min-1024 {
    font-size: 24px !important;
    font-weight: normal !important;
  }
  .f-24-400-min-1024 {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
  .f-24-500-min-1024 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .f-24-600-min-1024 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .f-24-700-min-1024 {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
  .f-24-bold-min-1024 {
    font-size: 24px !important;
    font-weight: bold !important;
  }
  .f-26-normal-min-1024 {
    font-size: 26px !important;
    font-weight: normal !important;
  }
  .f-26-400-min-1024 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }
  .f-26-500-min-1024 {
    font-size: 26px !important;
    font-weight: 500 !important;
  }
  .f-26-600-min-1024 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
  .f-26-700-min-1024 {
    font-size: 26px !important;
    font-weight: 700 !important;
  }
  .f-26-bold-min-1024 {
    font-size: 26px !important;
    font-weight: bold !important;
  }
  .f-32-normal-min-1024 {
    font-size: 32px !important;
    font-weight: normal !important;
  }
  .f-32-400-min-1024 {
    font-size: 32px !important;
    font-weight: 400 !important;
  }
  .f-32-500-min-1024 {
    font-size: 32px !important;
    font-weight: 500 !important;
  }
  .f-32-600-min-1024 {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .f-32-700-min-1024 {
    font-size: 32px !important;
    font-weight: 700 !important;
  }
  .f-32-bold-min-1024 {
    font-size: 32px !important;
    font-weight: bold !important;
  }
  .f-34-normal-min-1024 {
    font-size: 34px !important;
    font-weight: normal !important;
  }
  .f-34-400-min-1024 {
    font-size: 34px !important;
    font-weight: 400 !important;
  }
  .f-34-500-min-1024 {
    font-size: 34px !important;
    font-weight: 500 !important;
  }
  .f-34-600-min-1024 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }
  .f-34-700-min-1024 {
    font-size: 34px !important;
    font-weight: 700 !important;
  }
  .f-34-bold-min-1024 {
    font-size: 34px !important;
    font-weight: bold !important;
  }
  .f-40-normal-min-1024 {
    font-size: 40px !important;
    font-weight: normal !important;
  }
  .f-40-400-min-1024 {
    font-size: 40px !important;
    font-weight: 400 !important;
  }
  .f-40-500-min-1024 {
    font-size: 40px !important;
    font-weight: 500 !important;
  }
  .f-40-600-min-1024 {
    font-size: 40px !important;
    font-weight: 600 !important;
  }
  .f-40-700-min-1024 {
    font-size: 40px !important;
    font-weight: 700 !important;
  }
  .f-40-bold-min-1024 {
    font-size: 40px !important;
    font-weight: bold !important;
  }
  .f-64-normal-min-1024 {
    font-size: 64px !important;
    font-weight: normal !important;
  }
  .f-64-400-min-1024 {
    font-size: 64px !important;
    font-weight: 400 !important;
  }
  .f-64-500-min-1024 {
    font-size: 64px !important;
    font-weight: 500 !important;
  }
  .f-64-600-min-1024 {
    font-size: 64px !important;
    font-weight: 600 !important;
  }
  .f-64-700-min-1024 {
    font-size: 64px !important;
    font-weight: 700 !important;
  }
  .f-64-bold-min-1024 {
    font-size: 64px !important;
    font-weight: bold !important;
  }
}
@media only screen and (max-width: 1024px) {
  .f-10-normal-max-1024 {
    font-size: 10px !important;
    font-weight: normal !important;
  }
  .f-10-400-max-1024 {
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .f-10-500-max-1024 {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .f-10-600-max-1024 {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .f-10-700-max-1024 {
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  .f-10-bold-max-1024 {
    font-size: 10px !important;
    font-weight: bold !important;
  }
  .f-11-normal-max-1024 {
    font-size: 11px !important;
    font-weight: normal !important;
  }
  .f-11-400-max-1024 {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
  .f-11-500-max-1024 {
    font-size: 11px !important;
    font-weight: 500 !important;
  }
  .f-11-600-max-1024 {
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  .f-11-700-max-1024 {
    font-size: 11px !important;
    font-weight: 700 !important;
  }
  .f-11-bold-max-1024 {
    font-size: 11px !important;
    font-weight: bold !important;
  }
  .f-12-normal-max-1024 {
    font-size: 12px !important;
    font-weight: normal !important;
  }
  .f-12-400-max-1024 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .f-12-500-max-1024 {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .f-12-600-max-1024 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .f-12-700-max-1024 {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  .f-12-bold-max-1024 {
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .f-13-normal-max-1024 {
    font-size: 13px !important;
    font-weight: normal !important;
  }
  .f-13-400-max-1024 {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .f-13-500-max-1024 {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .f-13-600-max-1024 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  .f-13-700-max-1024 {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
  .f-13-bold-max-1024 {
    font-size: 13px !important;
    font-weight: bold !important;
  }
  .f-14-normal-max-1024 {
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .f-14-400-max-1024 {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .f-14-500-max-1024 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .f-14-600-max-1024 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .f-14-700-max-1024 {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .f-14-bold-max-1024 {
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .f-16-normal-max-1024 {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .f-16-400-max-1024 {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .f-16-500-max-1024 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .f-16-600-max-1024 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .f-16-700-max-1024 {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .f-16-bold-max-1024 {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .f-18-normal-max-1024 {
    font-size: 18px !important;
    font-weight: normal !important;
  }
  .f-18-400-max-1024 {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .f-18-500-max-1024 {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .f-18-600-max-1024 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .f-18-700-max-1024 {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .f-18-bold-max-1024 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .f-19-normal-max-1024 {
    font-size: 19px !important;
    font-weight: normal !important;
  }
  .f-19-400-max-1024 {
    font-size: 19px !important;
    font-weight: 400 !important;
  }
  .f-19-500-max-1024 {
    font-size: 19px !important;
    font-weight: 500 !important;
  }
  .f-19-600-max-1024 {
    font-size: 19px !important;
    font-weight: 600 !important;
  }
  .f-19-700-max-1024 {
    font-size: 19px !important;
    font-weight: 700 !important;
  }
  .f-19-bold-max-1024 {
    font-size: 19px !important;
    font-weight: bold !important;
  }
  .f-20-normal-max-1024 {
    font-size: 20px !important;
    font-weight: normal !important;
  }
  .f-20-400-max-1024 {
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  .f-20-500-max-1024 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .f-20-600-max-1024 {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .f-20-700-max-1024 {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .f-20-bold-max-1024 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .f-24-normal-max-1024 {
    font-size: 24px !important;
    font-weight: normal !important;
  }
  .f-24-400-max-1024 {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
  .f-24-500-max-1024 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .f-24-600-max-1024 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .f-24-700-max-1024 {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
  .f-24-bold-max-1024 {
    font-size: 24px !important;
    font-weight: bold !important;
  }
  .f-26-normal-max-1024 {
    font-size: 26px !important;
    font-weight: normal !important;
  }
  .f-26-400-max-1024 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }
  .f-26-500-max-1024 {
    font-size: 26px !important;
    font-weight: 500 !important;
  }
  .f-26-600-max-1024 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
  .f-26-700-max-1024 {
    font-size: 26px !important;
    font-weight: 700 !important;
  }
  .f-26-bold-max-1024 {
    font-size: 26px !important;
    font-weight: bold !important;
  }
  .f-32-normal-max-1024 {
    font-size: 32px !important;
    font-weight: normal !important;
  }
  .f-32-400-max-1024 {
    font-size: 32px !important;
    font-weight: 400 !important;
  }
  .f-32-500-max-1024 {
    font-size: 32px !important;
    font-weight: 500 !important;
  }
  .f-32-600-max-1024 {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .f-32-700-max-1024 {
    font-size: 32px !important;
    font-weight: 700 !important;
  }
  .f-32-bold-max-1024 {
    font-size: 32px !important;
    font-weight: bold !important;
  }
  .f-34-normal-max-1024 {
    font-size: 34px !important;
    font-weight: normal !important;
  }
  .f-34-400-max-1024 {
    font-size: 34px !important;
    font-weight: 400 !important;
  }
  .f-34-500-max-1024 {
    font-size: 34px !important;
    font-weight: 500 !important;
  }
  .f-34-600-max-1024 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }
  .f-34-700-max-1024 {
    font-size: 34px !important;
    font-weight: 700 !important;
  }
  .f-34-bold-max-1024 {
    font-size: 34px !important;
    font-weight: bold !important;
  }
  .f-40-normal-max-1024 {
    font-size: 40px !important;
    font-weight: normal !important;
  }
  .f-40-400-max-1024 {
    font-size: 40px !important;
    font-weight: 400 !important;
  }
  .f-40-500-max-1024 {
    font-size: 40px !important;
    font-weight: 500 !important;
  }
  .f-40-600-max-1024 {
    font-size: 40px !important;
    font-weight: 600 !important;
  }
  .f-40-700-max-1024 {
    font-size: 40px !important;
    font-weight: 700 !important;
  }
  .f-40-bold-max-1024 {
    font-size: 40px !important;
    font-weight: bold !important;
  }
  .f-64-normal-max-1024 {
    font-size: 64px !important;
    font-weight: normal !important;
  }
  .f-64-400-max-1024 {
    font-size: 64px !important;
    font-weight: 400 !important;
  }
  .f-64-500-max-1024 {
    font-size: 64px !important;
    font-weight: 500 !important;
  }
  .f-64-600-max-1024 {
    font-size: 64px !important;
    font-weight: 600 !important;
  }
  .f-64-700-max-1024 {
    font-size: 64px !important;
    font-weight: 700 !important;
  }
  .f-64-bold-max-1024 {
    font-size: 64px !important;
    font-weight: bold !important;
  }
}
@media only screen and (min-width: 1280px) {
  .f-10-normal-min-1280 {
    font-size: 10px !important;
    font-weight: normal !important;
  }
  .f-10-400-min-1280 {
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .f-10-500-min-1280 {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .f-10-600-min-1280 {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .f-10-700-min-1280 {
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  .f-10-bold-min-1280 {
    font-size: 10px !important;
    font-weight: bold !important;
  }
  .f-11-normal-min-1280 {
    font-size: 11px !important;
    font-weight: normal !important;
  }
  .f-11-400-min-1280 {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
  .f-11-500-min-1280 {
    font-size: 11px !important;
    font-weight: 500 !important;
  }
  .f-11-600-min-1280 {
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  .f-11-700-min-1280 {
    font-size: 11px !important;
    font-weight: 700 !important;
  }
  .f-11-bold-min-1280 {
    font-size: 11px !important;
    font-weight: bold !important;
  }
  .f-12-normal-min-1280 {
    font-size: 12px !important;
    font-weight: normal !important;
  }
  .f-12-400-min-1280 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .f-12-500-min-1280 {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .f-12-600-min-1280 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .f-12-700-min-1280 {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  .f-12-bold-min-1280 {
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .f-13-normal-min-1280 {
    font-size: 13px !important;
    font-weight: normal !important;
  }
  .f-13-400-min-1280 {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .f-13-500-min-1280 {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .f-13-600-min-1280 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  .f-13-700-min-1280 {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
  .f-13-bold-min-1280 {
    font-size: 13px !important;
    font-weight: bold !important;
  }
  .f-14-normal-min-1280 {
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .f-14-400-min-1280 {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .f-14-500-min-1280 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .f-14-600-min-1280 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .f-14-700-min-1280 {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .f-14-bold-min-1280 {
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .f-16-normal-min-1280 {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .f-16-400-min-1280 {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .f-16-500-min-1280 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .f-16-600-min-1280 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .f-16-700-min-1280 {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .f-16-bold-min-1280 {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .f-18-normal-min-1280 {
    font-size: 18px !important;
    font-weight: normal !important;
  }
  .f-18-400-min-1280 {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .f-18-500-min-1280 {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .f-18-600-min-1280 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .f-18-700-min-1280 {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .f-18-bold-min-1280 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .f-19-normal-min-1280 {
    font-size: 19px !important;
    font-weight: normal !important;
  }
  .f-19-400-min-1280 {
    font-size: 19px !important;
    font-weight: 400 !important;
  }
  .f-19-500-min-1280 {
    font-size: 19px !important;
    font-weight: 500 !important;
  }
  .f-19-600-min-1280 {
    font-size: 19px !important;
    font-weight: 600 !important;
  }
  .f-19-700-min-1280 {
    font-size: 19px !important;
    font-weight: 700 !important;
  }
  .f-19-bold-min-1280 {
    font-size: 19px !important;
    font-weight: bold !important;
  }
  .f-20-normal-min-1280 {
    font-size: 20px !important;
    font-weight: normal !important;
  }
  .f-20-400-min-1280 {
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  .f-20-500-min-1280 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .f-20-600-min-1280 {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .f-20-700-min-1280 {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .f-20-bold-min-1280 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .f-24-normal-min-1280 {
    font-size: 24px !important;
    font-weight: normal !important;
  }
  .f-24-400-min-1280 {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
  .f-24-500-min-1280 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .f-24-600-min-1280 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .f-24-700-min-1280 {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
  .f-24-bold-min-1280 {
    font-size: 24px !important;
    font-weight: bold !important;
  }
  .f-26-normal-min-1280 {
    font-size: 26px !important;
    font-weight: normal !important;
  }
  .f-26-400-min-1280 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }
  .f-26-500-min-1280 {
    font-size: 26px !important;
    font-weight: 500 !important;
  }
  .f-26-600-min-1280 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
  .f-26-700-min-1280 {
    font-size: 26px !important;
    font-weight: 700 !important;
  }
  .f-26-bold-min-1280 {
    font-size: 26px !important;
    font-weight: bold !important;
  }
  .f-32-normal-min-1280 {
    font-size: 32px !important;
    font-weight: normal !important;
  }
  .f-32-400-min-1280 {
    font-size: 32px !important;
    font-weight: 400 !important;
  }
  .f-32-500-min-1280 {
    font-size: 32px !important;
    font-weight: 500 !important;
  }
  .f-32-600-min-1280 {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .f-32-700-min-1280 {
    font-size: 32px !important;
    font-weight: 700 !important;
  }
  .f-32-bold-min-1280 {
    font-size: 32px !important;
    font-weight: bold !important;
  }
  .f-34-normal-min-1280 {
    font-size: 34px !important;
    font-weight: normal !important;
  }
  .f-34-400-min-1280 {
    font-size: 34px !important;
    font-weight: 400 !important;
  }
  .f-34-500-min-1280 {
    font-size: 34px !important;
    font-weight: 500 !important;
  }
  .f-34-600-min-1280 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }
  .f-34-700-min-1280 {
    font-size: 34px !important;
    font-weight: 700 !important;
  }
  .f-34-bold-min-1280 {
    font-size: 34px !important;
    font-weight: bold !important;
  }
  .f-40-normal-min-1280 {
    font-size: 40px !important;
    font-weight: normal !important;
  }
  .f-40-400-min-1280 {
    font-size: 40px !important;
    font-weight: 400 !important;
  }
  .f-40-500-min-1280 {
    font-size: 40px !important;
    font-weight: 500 !important;
  }
  .f-40-600-min-1280 {
    font-size: 40px !important;
    font-weight: 600 !important;
  }
  .f-40-700-min-1280 {
    font-size: 40px !important;
    font-weight: 700 !important;
  }
  .f-40-bold-min-1280 {
    font-size: 40px !important;
    font-weight: bold !important;
  }
  .f-64-normal-min-1280 {
    font-size: 64px !important;
    font-weight: normal !important;
  }
  .f-64-400-min-1280 {
    font-size: 64px !important;
    font-weight: 400 !important;
  }
  .f-64-500-min-1280 {
    font-size: 64px !important;
    font-weight: 500 !important;
  }
  .f-64-600-min-1280 {
    font-size: 64px !important;
    font-weight: 600 !important;
  }
  .f-64-700-min-1280 {
    font-size: 64px !important;
    font-weight: 700 !important;
  }
  .f-64-bold-min-1280 {
    font-size: 64px !important;
    font-weight: bold !important;
  }
}
@media only screen and (max-width: 1280px) {
  .f-10-normal-max-1280 {
    font-size: 10px !important;
    font-weight: normal !important;
  }
  .f-10-400-max-1280 {
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .f-10-500-max-1280 {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .f-10-600-max-1280 {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .f-10-700-max-1280 {
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  .f-10-bold-max-1280 {
    font-size: 10px !important;
    font-weight: bold !important;
  }
  .f-11-normal-max-1280 {
    font-size: 11px !important;
    font-weight: normal !important;
  }
  .f-11-400-max-1280 {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
  .f-11-500-max-1280 {
    font-size: 11px !important;
    font-weight: 500 !important;
  }
  .f-11-600-max-1280 {
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  .f-11-700-max-1280 {
    font-size: 11px !important;
    font-weight: 700 !important;
  }
  .f-11-bold-max-1280 {
    font-size: 11px !important;
    font-weight: bold !important;
  }
  .f-12-normal-max-1280 {
    font-size: 12px !important;
    font-weight: normal !important;
  }
  .f-12-400-max-1280 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .f-12-500-max-1280 {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .f-12-600-max-1280 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .f-12-700-max-1280 {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  .f-12-bold-max-1280 {
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .f-13-normal-max-1280 {
    font-size: 13px !important;
    font-weight: normal !important;
  }
  .f-13-400-max-1280 {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .f-13-500-max-1280 {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .f-13-600-max-1280 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  .f-13-700-max-1280 {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
  .f-13-bold-max-1280 {
    font-size: 13px !important;
    font-weight: bold !important;
  }
  .f-14-normal-max-1280 {
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .f-14-400-max-1280 {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .f-14-500-max-1280 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .f-14-600-max-1280 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .f-14-700-max-1280 {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .f-14-bold-max-1280 {
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .f-16-normal-max-1280 {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .f-16-400-max-1280 {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .f-16-500-max-1280 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .f-16-600-max-1280 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .f-16-700-max-1280 {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .f-16-bold-max-1280 {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .f-18-normal-max-1280 {
    font-size: 18px !important;
    font-weight: normal !important;
  }
  .f-18-400-max-1280 {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .f-18-500-max-1280 {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .f-18-600-max-1280 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .f-18-700-max-1280 {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .f-18-bold-max-1280 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .f-19-normal-max-1280 {
    font-size: 19px !important;
    font-weight: normal !important;
  }
  .f-19-400-max-1280 {
    font-size: 19px !important;
    font-weight: 400 !important;
  }
  .f-19-500-max-1280 {
    font-size: 19px !important;
    font-weight: 500 !important;
  }
  .f-19-600-max-1280 {
    font-size: 19px !important;
    font-weight: 600 !important;
  }
  .f-19-700-max-1280 {
    font-size: 19px !important;
    font-weight: 700 !important;
  }
  .f-19-bold-max-1280 {
    font-size: 19px !important;
    font-weight: bold !important;
  }
  .f-20-normal-max-1280 {
    font-size: 20px !important;
    font-weight: normal !important;
  }
  .f-20-400-max-1280 {
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  .f-20-500-max-1280 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .f-20-600-max-1280 {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .f-20-700-max-1280 {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .f-20-bold-max-1280 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .f-24-normal-max-1280 {
    font-size: 24px !important;
    font-weight: normal !important;
  }
  .f-24-400-max-1280 {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
  .f-24-500-max-1280 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .f-24-600-max-1280 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .f-24-700-max-1280 {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
  .f-24-bold-max-1280 {
    font-size: 24px !important;
    font-weight: bold !important;
  }
  .f-26-normal-max-1280 {
    font-size: 26px !important;
    font-weight: normal !important;
  }
  .f-26-400-max-1280 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }
  .f-26-500-max-1280 {
    font-size: 26px !important;
    font-weight: 500 !important;
  }
  .f-26-600-max-1280 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
  .f-26-700-max-1280 {
    font-size: 26px !important;
    font-weight: 700 !important;
  }
  .f-26-bold-max-1280 {
    font-size: 26px !important;
    font-weight: bold !important;
  }
  .f-32-normal-max-1280 {
    font-size: 32px !important;
    font-weight: normal !important;
  }
  .f-32-400-max-1280 {
    font-size: 32px !important;
    font-weight: 400 !important;
  }
  .f-32-500-max-1280 {
    font-size: 32px !important;
    font-weight: 500 !important;
  }
  .f-32-600-max-1280 {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .f-32-700-max-1280 {
    font-size: 32px !important;
    font-weight: 700 !important;
  }
  .f-32-bold-max-1280 {
    font-size: 32px !important;
    font-weight: bold !important;
  }
  .f-34-normal-max-1280 {
    font-size: 34px !important;
    font-weight: normal !important;
  }
  .f-34-400-max-1280 {
    font-size: 34px !important;
    font-weight: 400 !important;
  }
  .f-34-500-max-1280 {
    font-size: 34px !important;
    font-weight: 500 !important;
  }
  .f-34-600-max-1280 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }
  .f-34-700-max-1280 {
    font-size: 34px !important;
    font-weight: 700 !important;
  }
  .f-34-bold-max-1280 {
    font-size: 34px !important;
    font-weight: bold !important;
  }
  .f-40-normal-max-1280 {
    font-size: 40px !important;
    font-weight: normal !important;
  }
  .f-40-400-max-1280 {
    font-size: 40px !important;
    font-weight: 400 !important;
  }
  .f-40-500-max-1280 {
    font-size: 40px !important;
    font-weight: 500 !important;
  }
  .f-40-600-max-1280 {
    font-size: 40px !important;
    font-weight: 600 !important;
  }
  .f-40-700-max-1280 {
    font-size: 40px !important;
    font-weight: 700 !important;
  }
  .f-40-bold-max-1280 {
    font-size: 40px !important;
    font-weight: bold !important;
  }
  .f-64-normal-max-1280 {
    font-size: 64px !important;
    font-weight: normal !important;
  }
  .f-64-400-max-1280 {
    font-size: 64px !important;
    font-weight: 400 !important;
  }
  .f-64-500-max-1280 {
    font-size: 64px !important;
    font-weight: 500 !important;
  }
  .f-64-600-max-1280 {
    font-size: 64px !important;
    font-weight: 600 !important;
  }
  .f-64-700-max-1280 {
    font-size: 64px !important;
    font-weight: 700 !important;
  }
  .f-64-bold-max-1280 {
    font-size: 64px !important;
    font-weight: bold !important;
  }
}
.text-overflow {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.font-style-italic {
  font-style: italic !important;
}

.iq-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1050;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  padding: 20px 0;
  background-color: rgba(0, 30, 64, 0.6);
  outline: 0;
  pointer-events: none;
}
@media (min-width: 900px) {
  .iq-modal {
    margin-right: calc(-1 * (100vw - 100%));
  }
}
@media (max-width: 560px) {
  .iq-modal {
    padding: 20px 16px;
    align-items: flex-start;
  }
}
@media (max-height: 480px) and (orientation: landscape) {
  .iq-modal {
    align-items: flex-start;
  }
}
.iq-modal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 570px;
  max-width: calc(100vw - 32px);
  pointer-events: auto;
  background-color: #ebeff2;
  background-clip: padding-box;
  border-radius: 8px;
  outline: 0;
}
.iq-modal .modal-content__wide {
  width: 920px;
}
@media (max-width: 920px) {
  .iq-modal .modal-content__wide {
    width: 100%;
  }
}
.iq-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 80px;
  padding: 0 64px;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
}
@media (max-width: 560px) {
  .iq-modal .modal-content .modal-header {
    padding: 0 16px;
  }
}
.iq-modal .modal-content .modal-header::before {
  content: "";
  position: absolute;
  bottom: -11px;
  left: 0;
  right: 0;
  width: 30px;
  height: 30px;
  margin: auto;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 6px;
  z-index: 2;
}
.iq-modal .modal-content .modal-header.modal-hide-caret::before {
  display: none;
}
.iq-modal .modal-content .modal-header .modal-title {
  font-size: 24px;
  font-weight: 500;
  color: #333940;
}
.iq-modal .modal-content .modal-body {
  text-align: center;
  padding: 32px 40px 32px;
}
@media (max-width: 560px) {
  .iq-modal .modal-content .modal-body {
    padding: 40px 16px 32px;
  }
}
.iq-modal .modal-content .modal-body__icon {
  margin-bottom: 24px;
}
.iq-modal .modal-content .modal-body__title {
  margin-bottom: 8px;
  font-weight: 600;
}
.iq-modal .modal-content .modal-body__text {
  line-height: 1.5;
  color: #333940;
}
.iq-modal .modal-content .modal-body .image-full-width {
  margin: 0 -24px;
}
.iq-modal .modal-content .modal-body .image-full-width img {
  width: 100%;
}
@media (max-width: 560px) {
  .iq-modal .modal-content .modal-body .image-full-width {
    margin: 0;
  }
}
.iq-modal .modal-content .modal-footer {
  padding: 0 40px 24px;
}
.iq-modal .modal-content .modal-footer iq-button {
  margin-bottom: 16px;
}
@media (max-width: 560px) {
  .iq-modal .modal-content .modal-footer {
    padding: 0 16px 24px;
  }
}
.iq-modal .modal-content .close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  color: #fff;
  cursor: pointer;
}
.iq-modal .modal-content .close:hover {
  opacity: 0.7;
}
.iq-modal.fade-anim {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.iq-modal.fade-anim.in {
  opacity: 1;
}
.iq-modal.fade-anim.in .modal-content {
  animation: modal-fade-in-top 0.3s;
}
.iq-modal.fade-anim.in .modal-sidebar {
  animation: modal-fade-in-right 0.3s;
}
.iq-modal.fade-anim .modal-sidebar {
  animation: modal-fade-out-right 0.3s;
}

.iq-modal-top > .iq-modal {
  align-items: flex-start;
}

.iq-modal-left > .iq-modal {
  justify-content: flex-start;
}

.iq-modal-right > .iq-modal {
  justify-content: flex-end;
}

.iq-modal-bottom > .iq-modal {
  align-items: flex-end;
}

.iq-backdrop-no-bg {
  inset: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1040;
}

/*
 Iq-Sidebar
*/
.iq-backdrop {
  inset: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 30, 64, 0.6);
  z-index: 1040;
}

.iq-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: calc(1050 - 1);
  display: flex;
  flex-direction: column;
  width: 530px;
  height: calc(var(--vh, 1vh) * 100);
  padding: 24px;
  background-color: #fff;
}
@media (max-width: 530px) {
  .iq-sidebar {
    width: 100vw;
  }
}
.iq-sidebar .modal-header {
  position: relative;
  padding-bottom: 24px;
}
.iq-sidebar .modal-header__icon {
  margin-right: 16px;
}
.iq-sidebar .modal-header__icon svg {
  fill: #0389ff;
}
.iq-sidebar .modal-header__title {
  font-size: 24px;
  line-height: 1.5;
  color: #333940;
}
.iq-sidebar .modal-header .modal-close {
  position: absolute;
  top: 6px;
  right: 0;
  cursor: pointer;
}
.iq-sidebar .modal-header .modal-close:hover {
  opacity: 0.7;
}
.iq-sidebar .modal-body {
  flex: 1;
  margin-right: -14px;
  padding-right: 14px;
  overflow-y: auto;
}
.iq-sidebar .modal-body::-webkit-scrollbar {
  width: 5px;
}
.iq-sidebar .modal-body::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #ebeff2;
}
.iq-sidebar .modal-body::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #9cacbe;
}
.iq-sidebar .modal-body__information {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.iq-sidebar .modal-body__information .icon {
  margin-right: 8px;
}
.iq-sidebar .modal-body__information span {
  font-size: 14px;
  font-weight: bold;
  color: #536880;
}
.iq-sidebar .modal-footer {
  margin-top: auto;
}
.iq-sidebar .modal-footer .assign-member__link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 8px;
  border-radius: 28px 8px 8px 28px;
  border: 1px solid #dae1e6;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  color: #536880;
}
.iq-sidebar .modal-footer .assign-member__link i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #ebeff2;
}
.iq-sidebar .modal-footer .assign-member__link i svg {
  display: block;
  width: 24px;
  height: 24px;
  fill: #9cacbe;
}

.iq-sidebar-fade-animation {
  transition: opacity 200ms ease-in-out;
  animation: modal-fade-in-right 0.3s;
}

.iq-sidebar-fade-out-animation {
  transition: opacity 200ms ease-in-out;
  animation: modal-fade-out-right 0.3s;
  animation-fill-mode: forwards;
}

:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

.backdrop {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
}

.menu-nav {
  width: 240px;
  background: #ffffff;
  height: 100vh;
}
.menu-nav .menu-header {
  background-color: #001e40;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 16px;
}
.menu-nav .menu-header .close-button {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
}
.menu-nav .menu-header .close-button::before,
.menu-nav .menu-header .close-button::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #9cacbe;
  top: 50%;
  left: 0;
}
.menu-nav .menu-header .close-button::before {
  transform: rotate(45deg);
}
.menu-nav .menu-header .close-button::after {
  transform: rotate(-45deg);
}
.menu-nav .menu-content {
  padding-top: 24px;
  overflow: hidden auto;
  height: calc(100vh - 56px - 24px);
}
.menu-nav .menu-content .nav-link {
  width: 100%;
  display: flex;
  color: #536880;
  font: 600 16px/24px "Figtree", sans-serif;
  padding: 16px;
  text-decoration: none;
}
.menu-nav .menu-content .nav-link:hover {
  background-color: #e6f4ff;
}
.menu-nav .menu-content .active-nav-link img {
  filter: invert(45%) sepia(100%) saturate(7462%) hue-rotate(196deg) brightness(96%) contrast(102%);
}
.menu-nav .menu-content .active-child-link {
  color: #333940;
}
.menu-nav .menu-content hr {
  width: 192px;
  border-color: rgba(156, 172, 190, 0.15);
  margin: 2px auto;
}
.menu-nav .menu-content .chevron,
.menu-nav .menu-content .expanded {
  transition: transform 150ms ease-in;
}
.menu-nav .menu-content .expanded {
  transform: rotate(180deg);
  transition: 150ms ease-out;
}

.iq-modal.modal-top {
  align-items: flex-start;
  overflow: auto;
}

.iq-modal-content.rare {
  background-color: #fff;
}
.iq-modal-content.rare .modal-header::before {
  display: none;
}
.iq-modal-content.rare .modal-header .modal-title {
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #536880;
}
.iq-modal-content.rare .modal-body__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #536880;
}

@keyframes modal-fade-in-top {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes modal-fade-in-right {
  0% {
    transform: translateX(530px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes modal-fade-out-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(530px);
  }
}
@keyframes menu-open {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes profile-open {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

.send-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: rgba(0, 30, 64, 0.8);
  overflow: auto;
  z-index: 9;
}
.send-modal.open {
  display: flex;
  margin: 0;
}
.send-modal__container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  margin: auto;
  padding: 0 15px 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(2, 36, 71, 0.2), 0 3px 10px 0 rgba(2, 36, 71, 0.1);
}
.send-modal__content {
  position: relative;
}
.send-modal__header {
  position: static;
  width: 100%;
  margin-bottom: 10px;
  padding: 9px 0;
  border-bottom: 1px solid #ebeff2;
  text-align: center;
  line-height: 32px;
  font-weight: 600;
  color: #333940;
}
.send-modal__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.send-modal__item {
  margin: 0 auto 8px;
  width: 80%;
}
.send-modal__item .button {
  width: 100%;
}
.send-modal__item .button-label {
  height: auto !important;
  white-space: normal !important;
}
.send-modal__close {
  position: absolute;
  top: -30px;
  right: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.calendly-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 16px;
  z-index: 99;
}

.calendly-close-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 30, 64, 0.8);
}

.calendly-popup {
  position: relative;
  max-width: 1032px;
  width: 100%;
  height: 100%;
  max-height: 840px;
  margin: auto;
  z-index: 1;
}

.calendly-popup-content {
  position: relative;
  height: 100%;
}
.calendly-popup-content .calendly-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.calendly-popup-content .calendly-spinner > div {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #0389ff;
  animation: loading-dots-bounce-delay 1.4s infinite ease-in-out both;
}
.calendly-popup-content .calendly-spinner > div:nth-child(1) {
  animation-delay: -0.32s;
}
.calendly-popup-content .calendly-spinner > div:nth-child(2) {
  animation-delay: -0.16s;
}
.calendly-popup-content iframe {
  position: relative;
  z-index: 2;
}

.calendly-popup-close {
  position: absolute;
  top: 12px;
  right: 12px;
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.calendly-popup-close:hover {
  opacity: 0.8;
}
.calendly-popup-close::before, .calendly-popup-close::after {
  position: absolute;
  left: 11px;
  top: 3px;
  content: "";
  display: block;
  width: 2px;
  height: 18px;
  background: #fff;
}
.calendly-popup-close::before {
  transform: rotate(45deg);
}
.calendly-popup-close::after {
  transform: rotate(-45deg);
}

@keyframes loading-dots-bounce-delay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.no-display {
  display: none;
}

@media (min-width: 480px) {
  .no-display-from-480 {
    display: none !important;
  }
}

@media (min-width: 560px) {
  .no-display-from-560 {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .no-display-from-768 {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .no-display-from-1024 {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .no-display-from-1200 {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .no-display-to-480 {
    display: none !important;
  }
}

@media (max-width: 560px) {
  .no-display-to-560 {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .no-display-to-768 {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .no-display-to-1024 {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .no-display-to-1200 {
    display: none !important;
  }
}

.block {
  display: block;
}

@media (min-width: 480px) {
  .block-from-480 {
    display: block;
  }
}

@media (min-width: 560px) {
  .block-from-560 {
    display: block;
  }
}

@media (min-width: 768px) {
  .block-from-768 {
    display: block;
  }
}

@media (min-width: 1024px) {
  .block-from-1024 {
    display: block;
  }
}

@media (min-width: 1200px) {
  .block-from-1200 {
    display: block;
  }
}

@media (max-width: 480px) {
  .block-to-480 {
    display: block;
  }
}

@media (max-width: 560px) {
  .block-to-560 {
    display: block;
  }
}

@media (max-width: 768px) {
  .block-to-768 {
    display: block;
  }
}

@media (max-width: 1024px) {
  .block-to-1024 {
    display: block;
  }
}

@media (max-width: 1200px) {
  .block-to-1200 {
    display: block;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

@media (min-width: 480px) {
  .flex-from-480 {
    display: flex;
  }
}

@media (min-width: 560px) {
  .flex-from-560 {
    display: flex;
  }
}

@media (min-width: 768px) {
  .flex-from-768 {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .flex-from-1024 {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .flex-from-1200 {
    display: flex;
  }
}

@media (max-width: 480px) {
  .flex-to-480 {
    display: flex;
  }
}

@media (max-width: 560px) {
  .flex-to-560 {
    display: flex;
  }
}

@media (max-width: 768px) {
  .flex-to-768 {
    display: flex;
  }
}

@media (max-width: 1024px) {
  .flex-to-1024 {
    display: flex;
  }
}

@media (max-width: 1200px) {
  .flex-to-1200 {
    display: flex;
  }
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.flex-auto {
  flex: 1 1 auto;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-evenly {
  justify-content: space-evenly;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-self-end {
  justify-self: end;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-5 {
  gap: 5px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-14 {
  gap: 14px;
}

.gap-15 {
  gap: 15px;
}

.gap-16 {
  gap: 16px;
}

.gap-17 {
  gap: 17px;
}

.gap-18 {
  gap: 18px;
}

.gap-20 {
  gap: 20px;
}

.gap-25 {
  gap: 25px;
}

.gap-30 {
  gap: 30px;
}

.gap-32 {
  gap: 32px;
}

.gap-40 {
  gap: 40px;
}

.align-center {
  display: flex;
  align-self: center;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.position-relative {
  position: relative;
}

.hiq-container {
  max-width: 1360px;
  margin: 0 auto;
}
.hiq-container--md {
  max-width: 1202px;
}
@media (max-width: calc(1360px + 16px * 2)) {
  .hiq-container {
    padding: 0 16px;
  }
}

.hidden {
  opacity: 0 !important;
  visibility: hidden !important;
}

.visible {
  opacity: 1 !important;
  visibility: visible !important;
}

@media (min-width: 480px) {
  .hidden-from-480 {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

@media (min-width: 560px) {
  .hidden-from-560 {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

@media (min-width: 768px) {
  .hidden-from-768 {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

@media (min-width: 1024px) {
  .hidden-from-1024 {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

@media (min-width: 1200px) {
  .hidden-from-1200 {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

@media (min-width: 480px) {
  .visible-from-480 {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

@media (min-width: 560px) {
  .visible-from-560 {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

@media (min-width: 768px) {
  .visible-from-768 {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

@media (min-width: 1024px) {
  .visible-from-1024 {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

@media (min-width: 1200px) {
  .visible-from-1200 {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

@media (max-width: 480px) {
  .hidden-to-480 {
    display: none !important;
  }
}

@media (max-width: 560px) {
  .hidden-to-560 {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .hidden-to-768 {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .hidden-to-1024 {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .hidden-to-1200 {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .visible-to-480 {
    display: block !important;
  }
}

@media (max-width: 560px) {
  .visible-to-560 {
    display: block !important;
  }
}

@media (max-width: 768px) {
  .visible-to-768 {
    display: block !important;
  }
}

@media (max-width: 1024px) {
  .visible-to-1024 {
    display: block !important;
  }
}

@media (max-width: 1200px) {
  .visible-to-1200 {
    display: block !important;
  }
}

.scroll-shadow {
  position: sticky;
  bottom: 0;
  pointer-events: none;
}
.scroll-shadow:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  background-image: linear-gradient(to top, rgb(251, 251, 251), rgba(251, 251, 251, 0));
  opacity: 0;
  transition: opacity 0.27s ease;
  z-index: 1;
}
.scroll-shadow.show:before {
  opacity: 1;
}

.scrollbar ::-webkit-scrollbar, body ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.scrollbar ::-webkit-scrollbar-track, body ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.scrollbar ::-webkit-scrollbar-thumb, body ::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  transition: width 0.3s ease;
}
.scrollbar ::-webkit-scrollbar-thumb:hover, body ::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  width: 12px;
  transition: width 0.3s ease;
}

html {
  box-sizing: border-box;
}

body {
  min-width: 320px;
  margin: 0;
  color: #333940;
  font-family: "Figtree", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, textarea, select, button {
  font-weight: 500;
  outline: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

li {
  list-style-type: none;
}

hr.divider {
  display: block;
  height: 1px;
  width: 100%;
  border: none;
  box-shadow: none;
  background: #ebeff2;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed !important;
}

.hover--underline-blue-main:hover {
  text-decoration: underline;
  text-decoration-color: #0389ff;
}

.pac-container {
  z-index: 1051 !important;
}

.row-fix {
  margin-right: -8px;
  margin-left: -8px;
}

.col-fix {
  padding-left: 8px;
  padding-right: 8px;
}

.bs-datepicker {
  box-shadow: 0 5px 15px 0 rgba(2, 36, 71, 0.2), 0 3px 10px 0 rgba(2, 36, 71, 0.1);
}
.bs-datepicker.date-range {
  box-shadow: unset;
}
.bs-datepicker.date-range .bs-datepicker-head, .bs-datepicker.date-range .bs-datepicker-body {
  min-width: 255px;
}
.bs-datepicker.date-range button.current {
  padding: 0;
}
.bs-datepicker-head {
  background-color: unset;
  padding: 10px 0;
}
.bs-datepicker-head button {
  color: #333940;
}
.bs-datepicker-head button:disabled {
  color: #9cacbe;
  background-color: unset;
  cursor: not-allowed;
}
.bs-datepicker-head button:disabled:hover {
  color: #9cacbe;
  background-color: unset;
}
.bs-datepicker-head button:hover {
  background-color: unset;
}
.bs-datepicker-head button.previous, .bs-datepicker-head button.next {
  position: relative;
  top: -2px;
}
.bs-datepicker-body {
  border: none;
  min-height: unset;
}
.bs-datepicker-body table.days span {
  width: unset;
}
.bs-datepicker-body table.months td span {
  border-radius: unset;
}
.bs-datepicker-body table.months td.is-highlighted span {
  color: #333940;
  box-shadow: -1px -1px 0 0 #e4e7e7, inset -1px -1px 0 0 #e4e7e7;
}
.bs-datepicker-body table.months td.disabled span {
  cursor: not-allowed;
}
.bs-datepicker-body table thead tr {
  display: flex;
  justify-content: space-between;
}
.bs-datepicker-body table thead tr th {
  flex: 1;
}
.bs-datepicker-body table tbody {
  display: block;
  margin-top: 8px;
}
.bs-datepicker-body table tbody tr {
  display: flex;
  justify-content: space-between;
}
.bs-datepicker-body table tbody tr td {
  flex: 1;
}
.bs-datepicker-body table tbody tr td span {
  border-radius: unset;
  color: #333940;
  font-weight: 500;
  box-shadow: -1px -1px 0 0 #e4e7e7, inset -1px -1px 0 0 #e4e7e7;
}
.bs-datepicker-body table tbody tr td span::before {
  left: 0;
  right: 0;
}
.bs-datepicker-body table tbody tr td span[class*=select-] {
  border-radius: unset;
}
.bs-datepicker-body table tbody tr td span.disabled {
  color: #9cacbe;
  cursor: not-allowed;
}
.bs-datepicker-body table tbody tr td span.is-highlighted {
  border-radius: unset;
}
.bs-datepicker-body table tbody tr td span.is-highlighted.in-range {
  background-color: #cceeff !important;
}
.bs-datepicker-body table tbody tr td span.is-highlighted.selected {
  background-color: #0389ff !important;
}
.bs-datepicker-body table tbody tr td span.in-range {
  background-color: #cceeff;
  box-shadow: -1px -1px 0 0 #afe1fa, inset -1px -1px 0 0 #afe1fa;
  border-radius: unset;
}
.bs-datepicker-body table tbody tr td span.selected {
  z-index: 1;
  font-weight: bold;
  color: #fff;
  background-color: #0389ff;
  box-shadow: -1px -1px 0 0 #0389ff, inset -1px -1px 0 0 #0389ff;
}
.bs-datepicker-body table tbody tr td span.is-other-month {
  display: none;
}

/*ReportCollectionStats, HomeownersCard*/
@keyframes spin-pending {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
/*DashboardCards*/
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.auth__decor {
  flex: 0 1 auto;
  width: 453px;
  background-image: url("/assets/components/sign-in/bg/sign-bg.png");
  background-color: #001e40;
  background-position: bottom center;
  background-repeat: no-repeat;
}
@media (max-width: 1024px) {
  .auth__decor {
    display: none;
  }
}
.auth__logo {
  display: block;
  width: 150px;
  height: 36px;
  margin: 88px auto 0;
  background: url("/assets/components/toolbar/logo.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.auth__content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.auth__title {
  margin: 0 0 16px;
  font-size: 32px;
  color: #0389ff;
}
.auth__subtitle {
  margin: 0 0 32px;
  font-size: 14px;
  color: #78899c;
}
.auth__form {
  width: 450px;
}
@media (max-width: 480px) {
  .auth__form {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
}

:root {
  --header-nav-height: 70px;
  --borders-gap: 6px;
}

@keyframes ghost-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.settings__form {
  position: relative;
  margin: 0 auto;
}
.settings .settings-support {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.settings__form-group-wrap {
  width: 100%;
  border-bottom: 1px solid #dae1e6;
}
.settings__form-group {
  position: relative;
  margin: 0 auto;
  padding-top: 10px;
}
.settings__form-group .settings__col {
  max-width: 570px;
}