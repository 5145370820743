@import 'src/assets/scss/_variables';
@import 'src/assets/scss/mixins/all';

/*
.filter
  .filter-container
    .filter-main-controls
      .filter-label Filter by:
      <-- filters -->
    .filter-additional-controls
      <-- controls -->
*/

/*
.filter.filter--no-label
  .filter-container
    .filter-main-controls
      .filter-label Filter by:
      <-- filters -->
    .filter-additional-controls
      <-- controls -->
*/

.filter {
  max-width: 1170px;
  margin: size(2x) auto size(2x);

  @include maxW(1270px) {
    max-width: unset;
    width: 100%;
    padding: 0 size(2x);
  }

  &--hidden {
    visibility: hidden;
  }

  &-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 0 60px;
  }

  &-main-controls {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
  }

  &-label {
    position: absolute;
    left: 0;
    line-height: 28px;
    font-size: 14px;
    font-weight: 500;
    color: $slate;
  }

  &-item {
    margin: 4px 0 4px 10px;

    .dp-filter-btn {
      margin-right: 0;
    }

    &-btn {
      font-size: 14px;
      line-height: 28px;
      font-weight: 500;
      color: $slate;
    }
  }

  &-modal-close {
    display: none;
  }

  &--no-label {
    @include minW(768px) {
      .filter {
        &-container {
          padding: 0;
        }

        &-label {
          display: none;
        }
      }
    }
  }
}

.currency-filter {
  position: relative;

  .btn--dropdown {
    background-color: $pale-grey;
    transition: background-color 0.3s;
    padding: 0 36px 0 12px;

    &:hover {
      background-color: $pale-sky-blue;
    }
  }

  &__menu {
    max-height: 384px;
    border-color: transparent;
    box-shadow: 0 5px 15px 0 rgba(2, 36, 71, 0.2),
      0 3px 10px 0 rgba(2, 36, 71, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    width: 320px;
    min-width: 320px;
    padding-right: 12px;
    padding-left: 12px;

    .input-group {
      margin-bottom: 5px;
    }
  }
}

.filter-dropdown {
  display: block;
  position: relative;

  &.dropdown-checkbox {
    width: 100%;

    .btn--dropdown {
      width: 100%;
      justify-content: flex-start;

      &:hover {
        background-color: unset;
      }
    }
  }

  &__toggle {
    display: block;
    max-width: 100%;
    background-color: $pale-grey;

    &--label {
      &.selected {
        @include minW(768px) {
          display: none;
        }
      }

      @include maxW(767px) {
        color: $grey-main;
      }
    }
  }

  &__menu {
    position: absolute;
    min-width: 4rem;
    z-index: 2;
    padding: 0;
    margin-top: 2px;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(2, 36, 71, 0.2),
      0 3px 10px 0 rgba(2, 36, 71, 0.1);
    background-color: #fff;
    border: none;
    overflow: hidden;

    &--title {
      display: none;
    }
  }

  &__content {
    position: relative;
    max-height: 200px;
    padding: 5px 0;
    overflow-y: auto;

    @include maxW(768px) {
      width: 100%;
      max-width: 500px;
      margin: auto;
      background: #ffffff;
      border-radius: 5px;
    }

    .checkbox {
      display: block;
      width: 100%;
      padding: 0 14px;
    }

    .checkbox__label {
      display: block;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 0;
      white-space: nowrap;
    }
  }

  &-spinner {
    padding: 15px 0;
  }

  &__item {
    display: block;
    float: left;
    padding: 0 15px;
    width: 100%;
    font-size: 14px;
    text-align: left;
    line-height: 2.29;
    white-space: nowrap;

    &.active {
      color: $grey-main;
      background-color: darken($pale-grey, 5%);
    }

    &:hover {
      background-color: $pale-grey;
    }

    @include maxW(767px) {
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }
}

@include maxW(1200px) {
  .filter-container {
    margin: 0 15px;
  }
}
