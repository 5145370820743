.control--size-48 {
  .control {
    min-height: 48px;
    height: 48px;
    &__label {
      padding: 4px;
    }
  }
}

.control {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 56px;
  border-radius: 6px;
  box-shadow: none;
  transition: box-shadow 0.2s ease;

  &__label {
    display: flex;
    align-items: flex-start;
    height: 100%;
    padding: size(1x);
    background-color: $pale-grey;

    &-text {
      min-width: 0;
      padding: size(1x);
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      color: $steel;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: size(2x);
    color: $grey-main;
    line-height: 1.5;
    font-size: size(2x);
    font-weight: 500;
    text-overflow: ellipsis;
    background-color: #fff;
    border: none;
    -webkit-appearance: none;

    @include input-placeholder {
      color: $steel-light;
    }

    &:focus {
      z-index: 1;
    }

    &:disabled {
      background-color: $pale-grey;
      color: $cloudy-blue;
      cursor: not-allowed;
    }
  }

  &__prepend {
    position: relative;
    flex: 0 0 33.333%;

    @include maxW(420px) {
      max-width: 33.333%;
    }

    & > * {
      border-width: 1px;
      border-style: solid;
      border-color: $light-blue-grey;
      border-radius: 6px 0 0 6px;
      transition: border-color 0.2s;
    }

    &:only-child {
      flex: 1;

      & > * {
        border-radius: 6px;
      }
    }
  }

  &__content {
    position: relative;
    flex: 0 0 calc(66.666% + 1px);
    margin-left: -1px;

    & > * {
      height: 100%;
      border-width: 1px;
      border-style: solid;
      border-color: $light-blue-grey;
      border-radius: 0 6px 6px 0;
      transition: border-color 0.2s;
    }

    &:only-child {
      flex: 1;
      margin-left: 0;

      & > *,
      .control__input {
        border-radius: 6px;
        height: auto;
      }
    }
  }

  &__append {
    position: relative;
    flex: 1;
    margin-left: size(1x);
  }

  &--focus {
    box-shadow: 0 4px 8px 0 rgba($azure, 0.2);

    .control__prepend,
    .control__content {
      & > * {
        border-color: $blue-main;
      }
    }
  }

  &--readonly {
    box-shadow: none;

    .control__prepend,
    .control__content {
      & > * {
        background-color: $pale-grey;
      }
    }
  }

  &--disable {
    box-shadow: none;

    .control__prepend,
    .control__content {
      & > * {
        background-color: $pale-grey;
      }
    }
  }

  &.extra-control {
    @include maxW(768px) {
      width: calc(100% - #{size(7x)});
    }
  }

  &.wide-prepend {
    .control__prepend {
      flex: 0 0 66.666%;

      @include maxW(768px) {
        flex: 0 0 50%;
      }
    }

    .control__content {
      flex: 0 0 calc(33.333% + 1px);

      @include maxW(768px) {
        flex: 0 0 50%;
      }
    }
  }

  &.broad-prepend {
    .control__prepend {
      flex: 0 0 60%;
    }

    .control__content {
      flex: 0 0 calc(40% + 1px);
    }
  }

  &.content-prepend {
    .control__prepend {
      flex: 0 0 75%;
    }

    .control__content {
      flex: 0 0 25%;
    }
  }

  textarea {
    font-size: 12px;
    resize: vertical;
  }
}

.control-error-message {
  display: block;
  margin-top: size(1x);
  font-size: 12px;
  color: $vermillion;
}

@keyframes onautofillstart {
  from {}
}

@keyframes onautofillcancel {
  from {}
}

.alternative-label {
  .control {
    display: block;
  }

  .control__content {
    margin-left: 0;
    border-radius: 6px;
    background-color: #fff;

    & > * {
      border-radius: 6px;
    }
  }

  .control__prepend {
    max-width: 100%;
  }

  .control__input {
    border-radius: 6px !important;
  }

  .control__label {
    display: block;
    margin-bottom: 4px;
    padding: 2px 0;
    border: none;
    border-radius: 0;
    background-color: transparent;
  }

  .control__label-text {
    padding: 0;
    line-height: 20px;
    font-weight: 600;
    color: $slate;
  }

  .form-error__item {
    line-height: 18px;
  }

  .form-error__item:not([hidden]) {
    margin-top: 6px !important;
  }

  &.ng-dirty {
    &.ng-valid {
      .control--focus {
        .control__content {
          box-shadow: 0 4px 8px 0 rgba($azure, 0.2);
        }
      }
    }

    &.ng-invalid {
      .control--focus {
        .control__content {
          box-shadow: 0 4px 8px 0 rgba($vermillion, 0.2);
        }
      }
    }
  }

  .control--focus {
    box-shadow: none !important;

    .control__content {
      box-shadow: 0 4px 8px 0 rgba($azure, 0.2);
    }
  }

  &__no-label {
    .control__prepend {
      display: none;
    }
  }
}
