.calendly-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 16px;
  z-index: 99;
}

.calendly-close-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 30, 64, 0.8);
}

.calendly-popup {
  position: relative;
  max-width: 1032px;
  width: 100%;
  height: 100%;
  max-height: 840px;
  margin: auto;
  z-index: 1;
}

.calendly-popup-content {
  position: relative;
  height: 100%;

  .calendly-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    & > div {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $blue-main;
      animation: loading-dots-bounce-delay 1.4s infinite ease-in-out both;

      &:nth-child(1) {
        animation-delay: -0.32s;
      }

      &:nth-child(2) {
        animation-delay: -0.16s;
      }
    }
  }

  iframe {
    position: relative;
    z-index: 2;
  }
}

.calendly-popup-close {
  position: absolute;
  top: 12px;
  right: 12px;
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &::before,
  &::after {
    position: absolute;
    left: 11px;
    top: 3px;
    content: '';
    display: block;
    width: 2px;
    height: 18px;
    background: #fff;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

@keyframes loading-dots-bounce-delay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
