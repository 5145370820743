.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 0 15px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  background: transparent;
  border: none;

  &.link {
    height: auto;
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: $blue-main;
    text-decoration: none;
    font-size: inherit;

    &:hover {
      text-decoration: underline;
    }

    &-dark {
      color: #000;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
