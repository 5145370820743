// .form-control-radio
//   label.form-control-radio__label
//     input.form-control-radio__control(type="radio")
//     .form-control-radio__caption
//   label.form-control-radio__label
//     input.form-control-radio__control(type="radio")
//     .form-control-radio__caption

.form-control-radio {
  position: relative;

  &__label {
    margin-bottom: 0;
    border: 1px solid rgba($blue-main, 0.2);

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }

  &__control {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    visibility: hidden;

    &:checked ~ .form-control-radio__caption {
      color: $slate;
      background-color: rgba($blue-main, 0.2);
      cursor: default;
    }
  }

  &__caption {
    width: 32px;
    height: 32px;
    padding: size(1x) 0;
    font-size: 12px;
    font-weight: 600;
    color: $cloudy-blue-two;
    text-align: center;
    cursor: pointer;
  }
}
