@import "src/assets/scss/_variables";

// WITH CONTAINER
// .control-dropdown(dropdown="", container="body", placement="bottom/top/left/right end/start OR auto") <-- default "bottom start"
//   .control-btn(dropdownToggle="")
//   .control-dropdown__menu(*dropdownMenu="")
//     .control-dropdown__item
//       .control-dropdown__icon([inlineSVG]="'assets/components/component/icon.svg'")
//       span.control-dropdown__label Label

// WITHOUT CONTAINER
//  .control-dropdown(dropdown="")
//   .control-dropdown__toggle.dropdown-toggle(dropdownToggle="")
//   .control-dropdown__menu.dropdown-menu.dropdown-menu-right(*dropdownMenu="")
//     .control-dropdown__item
//       .control-dropdown__icon([inlineSVG]="'assets/components/component/icon.svg'")
//       span.control-dropdown__label Label


bs-dropdown-container {
  z-index: $zindex-modal + 1 !important;
}

.control-dropdown {
  position: relative;

  &__toggle {
    &:after {
      content: none;
    }
  }

  &__menu {
    min-width: 100px;
    margin: 0 5px;
    padding: 5px 0;
    background-color: #fff;
    border-radius: 5px;
    border: none;
    box-shadow: 0 5px 15px 0 rgba(2, 36, 71, 0.2), 0 3px 10px 0 rgba(2, 36, 71, 0.1);

    @include maxW(750px) {
      right: -26px;
      position: absolute;
    }

    &--profile-actions {
      margin-top: -10px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 24px 4px 16px;
    color: $grey-main;
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    transition: background-color 0.2s;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: $pale-grey;
    }

    &:disabled {
      color: $pale-grey;
      cursor: default;

      &:hover {
        background-color: #fff;
      }
    }

    &.active {
      background-color: #e4eaf2;
    }

    .inline-svg {
      margin-right: 4px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 12px;

    ::ng-deep {
      svg {
        vertical-align: unset;
      }
    }
  }

  &__label {
    flex: 1;
    text-decoration: none !important;
    color: $grey-main !important;
  }

  &__divider {
    height: 1px;
    border: none;
    background: $pale-grey;
  }

  &.show {
    .dropdown-toggle {
      background-color: transparent;
    }
  }
}
