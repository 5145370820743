$checked-default: '/assets/common/forms/form-controls/checkbox/checked_default.svg';
$checked-hover: '/assets/common/forms/form-controls/checkbox/checked_hover.svg';
$checked-disabled: '/assets/common/forms/form-controls/checkbox/checked_disabled.svg';
$indeterminate-default: '/assets/common/forms/form-controls/checkbox/indeterminate_default.svg';
$indeterminate-hover: '/assets/common/forms/form-controls/checkbox/indeterminate_hover.svg';
$indeterminate-disabled: '/assets/common/forms/form-controls/checkbox/indeterminate_disabled.svg';
$unchecked-default: '/assets/common/forms/form-controls/checkbox/unchecked_default.svg';
$unchecked-hover: '/assets/common/forms/form-controls/checkbox/unchecked_hover.svg';
$unchecked-disabled: '/assets/common/forms/form-controls/checkbox/unchecked_disabled.svg';

/*
  .checkbox
    input.checkbox__input(type="checkbox")
    label.checkbox__label Checkbox label
*/

// preload checkbox icons
body {
  content:
    url($checked-default)
    url($checked-hover)
    url($checked-disabled)
    url($indeterminate-default)
    url($indeterminate-hover)
    url($indeterminate-disabled)
    url($unchecked-default)
    url($unchecked-hover)
    url($unchecked-disabled);
}

.checkbox {
  &__input {
    position: absolute;
    display: none;
    z-index: -1;

    & + .checkbox__label {
      position: relative;
      margin: 0;
      padding: size(2x) 23px size(2x) 28px;
      font-size: 14px;
      font-weight: 500;
      color: $blue-grey;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 16px;
        height: 16px;
        margin: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url($unchecked-default);
      }

      &:hover {
        &:before {
          background-image: url($unchecked-hover);
        }
      }
    }

    &:disabled + .checkbox__label {
      cursor: not-allowed;

      &:before {
        background-image: url($unchecked-disabled);
      }
    }

    &:checked {
      & + .checkbox__label {
        &:before {
          background-image: url($checked-default);
        }

        &:hover {
          &:before {
            background-image: url($checked-hover);
          }
        }
      }

      &:disabled {
        & + .checkbox__label {
          &:before {
            background-image: url($checked-disabled);
          }
        }
      }
    }

    &:indeterminate {
      & + .checkbox__label {
        &:before {
          background-image: url($indeterminate-default);
        }

        &:hover {
          &:before {
            background-image: url($indeterminate-hover);
          }
        }
      }

      &:disabled {
        & + .checkbox__label {
          &:before {
            background-image: url($indeterminate-disabled);
          }
        }
      }
    }
  }
}
