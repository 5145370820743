
// label.form-switch
//   input.form-switch__control(type="checkbox")
//   .form-switch__slider
//   .form-switch__bg

.form-switch {
  position: relative;
  flex: 0 0 35px;
  width: 35px;
  height: 20px;
  margin-bottom: 0;
  border-radius: 15px;
  background-color: $pale-sky-blue;
  font-weight: 600;
  cursor: pointer;

  &__slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.2s linear;
    z-index: 2;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    border-radius: 15px;
    opacity: 1;
    background-color: transparent;
    transition: all 0.2s linear;
    z-index: 1;
  }

  &__control {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    visibility: hidden;

    &:checked ~ .form-switch__slider {
      left: 100%;
      margin-left: -18px;
    }

    &:checked ~ .form-switch__bg {
      background-color: $leafy-green;
    }

    &:disabled ~ .form-switch__slider {
      cursor: not-allowed;
    }

    &:disabled ~ .form-switch__bg {
      background: #fff;
      opacity: 0.4;
    }

    &:disabled:checked ~ .form-switch__bg {
      background-color: $leafy-green;
    }
  }

  &__group {
    position: relative;
    min-height: 40px;
    margin: 10px 0;
    padding: 6px 0 6px 45px;

    &--disabled {
      .form-switch {
        cursor: default;
      }

      .form-switch__group-title {
        color: rgba(83, 104, 128, 0.3);
      }
    }

    &-title {
      margin-bottom: 0;
      color: $blue-grey;
      line-height: 28px;
      font-size: 14px;
      font-weight: 600;
    }

    .form-switch {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }
}
