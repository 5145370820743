@import 'src/assets/scss/_variables';
@import 'src/assets/scss/mixins/all';

$sidebarWidth: 360px;

.intelligence-user-page {
  position: relative;
  display: flex;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;

  @include maxW(1200px) {
    flex-direction: column;
  }

  &__sidebar {
    width: $sidebarWidth;
    min-height: calc(100vh - var(--header-nav-height));
    padding: size(2x) size(3x);
    background-color: #fff;

    @include maxW(1200px) {
      width: auto;
      min-height: auto;
    }

    .user {
      display: flex;
      align-items: center;
      gap: 16px;
      margin: size(2x) auto size(3x) 0;
    }

    .user-avatar {
      position: relative;
    }

    .user-internal-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .user-info {
      width: calc(100% - 96px);

      &__name {
        position: relative;
        width: fit-content;
      }
    }

  }

  &__content {
    position: relative;
    flex-basis: calc(100% - $sidebarWidth);
    max-width: calc(100% - $sidebarWidth);
    padding: 0 size(4x);

    @include maxW(1200px) {
      flex-basis: 100%;
      max-width: 100%;
    }

    @include maxW(768px) {
      padding: 0 size(2x);
    }

    .tab-nav {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;

      &__link.active {
        .tab-nav__label {
          color: $grey-main;
        }
      }

      &__label {
        color: $steel;
      }
    }
  }
}

.intelligence-details-card {
  background-color: #fff;
  border-radius: 12px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: size(3x) size(4x);

    @include maxW(576px) {
      padding-left: size(2x);
      padding-right: size(2x);
    }
  }

  &__content {
    padding: 0 size(4x) size(4x);

    @include maxW(576px) {
      padding-left: size(2x);
      padding-right: size(2x);
    }
  }
}
