.viral-import,
.import-database,
.upload-database {
  &__header {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 24px;
    text-align: center;

    &--btn {
      position: absolute;
      top: 15px;
      left: 0;
    }
  }

  &__title {
    padding-top: 30px;
    font-size: 16px;
    line-height: 24px;
    color: $grey-main;
    font-weight: 700;
  }

  &__select-file-btn, .btn {
    font-weight: 500;
  }

  &__content {
    position: relative;
    max-width: 600px;
    margin: 24px auto;
    padding: 0 15px;

    &-icon {
      margin: 24px 0;
    }

    &-title {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
      line-height: 26px;
      color: $grey-main;
    }

    &-text {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 21px;
      color: $grey-main;
    }
  }

  .btn:not(.link) {
    display: block;
    min-width: 150px;
    margin-left: auto;
    margin-right: auto;

    &.import-csv__import-btn {
      font-size: 17px;
    }
  }

  hr.divider {
    margin: 15px 0;
    background-color: $pale-sky-blue;
  }

  &__loading {
    padding-top: 134px;

    .loading-dots {
      display: block;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 22px;
    color: $grey-main;
    font-weight: 500;

    a {
      color: $blue-main !important;
    }
  }

  &__select-file-btn {
    input {
      position: absolute;
      visibility: hidden;
      z-index: -1;
    }
  }

  &__uploaded-icon {
    width: 22px;
    height: 22px;
    margin-right: 9px;
    background-color: #fff;
    border-radius: 50%;
  }

  &__status {
    &-image {
      width: 120px;
    }

    &-icon {
      font-size: 64px;
      margin: 56px auto 0;
    }

    &-text {
      margin-bottom: 24px;
      font-size: 14px;
    }
  }

  &__select-another-file {
    margin: 25px 0 30px;
    padding: 0;
    font-weight: 400;
    color: $blue-main;
  }

  &__progress {
    position: relative;
    display: block;
    width: 346px;
    margin: 0 auto;
    padding: 5px;
    border-radius: 6px;
    background-color: #fff;

    &-bar {
      height: 46px;
      border-radius: 6px;
      background-color: $blue-main;
      transition: width 0.5s ease;
    }

    &-value {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 16px;
      line-height: 24px;
      transform: translate(-50%, -50%);
    }
  }

  &__leads {
    max-width: 500px;
    margin: 0 auto 10px;
    text-align: left;
  }
}

.import-database {
  &__header {
    border-bottom: 1px solid $pale-grey;
  }
}
